<div class="gd-cq-table-rc-dialog">
  <mat-action-list>
    <ng-container *ngFor="let option of filteredOptions">
      <ng-container *ngIf="getOptionUIData(option) as optionUIData">
        <div *ngIf="optionUIData.displayed"
             [matTooltip]="optionUIData.tooltip"
             [matTooltipDisabled]="!optionUIData.tooltip">
          <button mat-list-item
                  (click)="handleOptionClick(option)"
                  [disabled]="optionUIData.disabled">
            <mat-icon fontSet="fal"
                      [fontIcon]="option.icon"
                      matListItemIcon></mat-icon>
            <span>{{ option.label }}</span>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </mat-action-list>
</div>
