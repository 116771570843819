import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-reducer';
import { getReferencedContentItems } from '../../store/referenced-content-repository/referenced-content-repository.reducer';
import { debounceTime, filter, firstValueFrom, forkJoin, map, mergeMap, of, take, tap } from 'rxjs';
import { GetReferencedContentAction } from '../../store/referenced-content-repository/referenced-content-repository.actions';
import { DataSourceOptions } from '../../../gpp-shared/custom-form-builder/data-source-options.enum';
import {
  FieldType,
  ResourceType,
} from '../../store/referenced-content-repository/referenced-content-repository.model';
import { ImagesService } from '../images/images.service';
import { getTaxonomies, valueAsArray } from '../../store';
import * as moment from 'moment-timezone';
import { FilesService } from '../files/files.service';
import { processTaxonomies } from '../taxonomies/taxonomies.service';
import { get } from 'lodash-es';
import { DataSourceFactoryService, safeCompileHandlebarsTemplate } from '../../../gpp-shared/widget-configuration/field-builder/field-builder-data-factory.service';

export interface CFPreviewItem {
  thumbnail?: string;
  // needed for authors and galleries
  images?: string[];
  icon?: string;
  iconSvg?: string;
  fieldType: string;
  glideData?: boolean;
  notAllowed?: boolean;
  status?: {
    name: string;
    tooltip?: string;
    showScheduleIcon: boolean;
  };
  values: CFPreviewItemValue[];
  // primarily added for CFGs to override label from configuration with a label of preview field
  // also used to indicate if a field that is being previewed is a part of CFG
  fieldLabel?: string;
}

interface CFPreviewItemValue {
  label: string;
  alt?: string;
  icon?: string;
  link?: string;
  // default icon: "fa-exclamation-circle", default color: "#b00020"
  tooltip?: { icon?: string; message; color?: string };
  checked?: boolean;
}
type ContentIdentifier = string | number | string[] | number[];

/**
 * The responsibilities of this service:
 * - providing an easy way for fetching Glide resources (supports all resources added to the ResourceType enum)
 * - preparing custom field preview data for all custom field types
 */
@Injectable()
export class ReferencedContentRepositoryService {
  constructor(
    private store: Store<AppState>,
    private imagesService: ImagesService,
    private filesService: FilesService,
    private dataSourceFactoryService: DataSourceFactoryService
  ) {}

  typeConversionMap = {
    [FieldType.Article]: ResourceType.Article,
    [FieldType.Collection]: ResourceType.Collection,
    [FieldType.Gallery]: ResourceType.Gallery,
    [FieldType.LiveReport]: ResourceType.LiveReport,
    [FieldType.Image]: ResourceType.Image,
    [FieldType.ArticleType]: ResourceType.ArticleType,
    [FieldType.CollectionType]: ResourceType.CollectionType,
    [FieldType.ContentTag]: ResourceType.ContentTag,
    [FieldType.HTMLSnippet]: ResourceType.HTMLSnippet,
    [FieldType.ContentLocale]: ResourceType.ContentLocale,
    [FieldType.File]: ResourceType.File,
    [FieldType.Menu]: ResourceType.Menu,
    [FieldType.Taxonomy]: ResourceType.Taxonomy,
    [FieldType.Author]: ResourceType.Author,
    [FieldType.CFG]: ResourceType.CFG,
  };

  /**
   * Fetch Glide resource(s) from state if already exist there. If not, make a request to the appropriate API.
   * @param contentIds An id or array of ids
   * @param type ResourceType (see ResourceType enum)
   * @param includeSysMetaInResponse Boolean
   */
  getData(contentIds: ContentIdentifier, type, includeSysMetaInResponse = true) {
    const multiple = Array.isArray(contentIds);
    const ids = multiple ? contentIds : [contentIds];
    return this.store.select(getReferencedContentItems(ids, type)).pipe(
      debounceTime(50),
      tap((items) => {
        if (!items?.length || items.length !== ids.length) {
          this.store.dispatch(new GetReferencedContentAction({ ids, type }));
        }
      }),
      filter(
        (items) => items.length === ids.length && items.every((item) => item?.sysMeta?.loadedAt)
      ),
      take(1),
      map((items) => (includeSysMetaInResponse ? items : items.map(({ data }) => data))),
      map((items) => (multiple ? items : items[0]))
    );
  }

  getExternalData(fieldValue, configuration, settings) {
    if (!fieldValue) {
      return of(null);
    }
    if (!Array.isArray(fieldValue)) {
      fieldValue = [fieldValue];
    }

    // compile handlebars template
    const dataInitTemplateRaw = get(configuration, 'dataSource.value.dynamicSearch.dataInitializationUrl', null);
    const urlTemplateRaw = get(configuration, 'dataSource.value.value', '');
    const template = dataInitTemplateRaw || urlTemplateRaw;
    const searchTerms = { __id: [fieldValue], __ids: fieldValue };
    const compiledHandlebarsTemplate = safeCompileHandlebarsTemplate(template);
    const url = compiledHandlebarsTemplate({ ...settings, ...searchTerms });;

    // create request URL and send request
    const useComposerAsProxy = get(configuration, 'dataSource.value.builtInProxy.enabled', false);
    const httpHeaders = configuration.dataSource.value.httpHeaders || [];
    let serverData = useComposerAsProxy
      ? this.dataSourceFactoryService.createProxyRequest(url, httpHeaders)
      : this.dataSourceFactoryService.getDataFromServer(url, httpHeaders);

    // check if it exist and create transform function
    const functionData = get(configuration.dataSource.value, 'functionData', {});
    const doResponseDataTransform: boolean = functionData.slideToggle;
    const transformDataFn =
      doResponseDataTransform &&
      new Function('data', 'contextValues', functionData.transformFunction);

    // run transform data fn over response data
    if (transformDataFn) {
      serverData = serverData.pipe(
        map((extData) => {
          return transformDataFn(extData, settings);
        })
      );
    }
    return serverData.pipe(
      debounceTime(50),
      take(1),
      map((res) => {
        const items = this.dataSourceFactoryService.createObjectData(res);
        // case when dont have init url and we have to filter reponse by id (fieldValue) (RP chain request case)
        if (!dataInitTemplateRaw) {
          const data = (items || []).filter(obj => fieldValue.includes(obj.id));
          // if we can't find data return id
          if (!data) {
            return fieldValue;
          }
          return data;
        }
        return items;
      })
    );
  }

  /**
   * Prepare custom field preview data. Handle additional data fetching from API if needed.
   * @param fieldValue Field value depends on field type
   * @param configuration Custom field configuration
   * @param localeId Active locale id (optional)
   */
  getCustomFieldPreviewData(fieldValue, configuration, localeId = null, settings = {}) {
    const type = this.typeConversionMap[configuration?.dataSource?.value];
    const isExternalData = configuration.dataSource?.type === DataSourceOptions.ExternalData;
    const glideData = configuration.dataSource?.type === DataSourceOptions.CMSData;
    const isValidCMSData = glideData && !!valueAsArray(fieldValue).filter((v) => !!v).length;
    let observer = of(fieldValue);
    if (isValidCMSData) {
      // in case of Glide data custom fields fetch selected entities
      observer = this.getData(fieldValue, type);
    }
    if (isExternalData) {
      observer = this.getExternalData(fieldValue, configuration, settings)
    }
    return observer.pipe(
      mergeMap((response) => {
        // Glide data
        if (type === ResourceType.Image) {
          return this.prepareImageFieldPreviewData(response);
        }
        if (type === ResourceType.Gallery) {
          return this.prepareGalleryFieldPreviewData(response);
        }
        if (type === ResourceType.Article) {
          return this.prepareArticleFieldPreviewData(response);
        }
        if (type === ResourceType.Collection) {
          return this.prepareCollectionFieldPreviewData(response);
        }
        if (type === ResourceType.LiveReport) {
          return this.prepareLiveReportFieldPreviewData(response);
        }
        if (type === ResourceType.ArticleType) {
          return this.prepareArticleTypeFieldPreviewData(response);
        }
        if (type === ResourceType.CollectionType) {
          return this.prepareCollectionTypeFieldPreviewData(response);
        }
        if (type === ResourceType.ContentTag) {
          return this.prepareContentTagFieldPreviewData(response);
        }
        if (type === ResourceType.HTMLSnippet) {
          return this.prepareHTMLSnippetFieldPreviewData(response);
        }
        if (type === ResourceType.ContentLocale) {
          return this.prepareContentLocaleFieldPreviewData(response);
        }
        if (type === ResourceType.Menu) {
          return this.prepareMenuFieldPreviewData(response);
        }
        if (type === ResourceType.File) {
          return this.prepareFileFieldPreviewData(response);
        }
        if (type === ResourceType.Author) {
          return this.prepareAuthorFieldPreviewData(response);
        }
        if (type === ResourceType.CFG) {
          return this.prepareCFGFieldPreviewData(response);
        }
        if (type === ResourceType.Taxonomy) {
          return this.store.select(getTaxonomies).pipe(
            filter(
              (taxonomies) =>
                !valueAsArray(response).filter((v) => !!v).length ||
                Object.keys(taxonomies).length > 0
            ),
            take(1),
            mergeMap((taxonomies) =>
              this.prepareTaxonomyFieldPreviewData(response, taxonomies, localeId)
            )
          );
        }
        if (configuration.dataSource?.type === DataSourceOptions.Group) {
          return this.prepareFormFragmentPreviewData(response, configuration);
        }
        if (configuration.dataSource?.type === DataSourceOptions.ExternalData) {
          console.log('response', response);
          return of({
            iconSvg: 'glideCustomIcon_external-data',
            fieldType: FieldType.ExternalData,
            values: valueAsArray(response).map((v) => ({ label: v?.name || v?.label || v })),
          } as CFPreviewItem);
        }
        if (
          configuration.dataSource?.type === DataSourceOptions.CustomData &&
          configuration.fieldType === FieldType.CDSelect
        ) {
          const values = valueAsArray(fieldValue)
            .map((key) => configuration.dataSource.value.find(({ value }) => value === key))
            .filter((key) => !!key);
          return of({
            iconSvg: 'glideCustomIcon_select',
            fieldType: configuration.fieldType,
            values,
          } as CFPreviewItem);
        }
        if (
          configuration.dataSource?.type === DataSourceOptions.CustomData &&
          configuration.fieldType === FieldType.CDAutocomplete
        ) {
          const values = valueAsArray(fieldValue)
            .map((val) => (typeof val === 'object' ? val : { id: val, name: val }))
            .map(({ id, name }) => configuration.dataSource.value.find(({ value }) => value === id))
            .filter((id) => !!id);
          return of({
            iconSvg: 'glideCustomIcon_autocomplete',
            fieldType: configuration.fieldType,
            values,
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.CheckboxGroup) {
          return this.prepareCheckboxFieldPreviewData(response, configuration);
        }
        if (configuration.inputType === FieldType.RadioGroup) {
          return this.prepareRadioFieldPreviewData(response, configuration);
        }
        if (configuration.inputType === FieldType.Toggle) {
          return of({
            icon: 'fa-toggle-on',
            fieldType: FieldType.Toggle,
            values: [{ label: configuration.label, checked: !!response }],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Colour) {
          return of({
            iconSvg: 'glideCustomIcon_colour',
            fieldType: FieldType.Colour,
            values: response ? [{ label: response }] : [],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Date) {
          return of({
            iconSvg: 'glideCustomIcon_date',
            fieldType: FieldType.Date,
            values: response ? [{ label: moment(response).format('DD-MMM-YYYY') }] : [],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.DateTime) {
          return of({
            iconSvg: 'glideCustomIcon_datetime',
            fieldType: FieldType.DateTime,
            values: response ? [{ label: moment(response).format('DD-MMM-YYYY h:mm A') }] : [],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Email) {
          return of({
            iconSvg: 'glideCustomIcon_email',
            fieldType: FieldType.Email,
            values: [{ label: response }],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Number) {
          return of({
            iconSvg: 'glideCustomIcon_number',
            fieldType: FieldType.Number,
            values: [{ label: response }],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Password) {
          return of({
            iconSvg: 'glideCustomIcon_password',
            fieldType: FieldType.Password,
            values: response ? [{ label: '**************' }] : [],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Search) {
          return of({
            iconSvg: 'glideCustomIcon_search',
            fieldType: FieldType.Search,
            values: [{ label: response }],
          } as CFPreviewItem);
        }
        if (configuration.inputType === FieldType.Text) {
          return of({
            iconSvg: 'glideCustomIcon_text',
            fieldType: FieldType.Text,
            values: [{ label: response }],
          } as CFPreviewItem);
        }
        if (configuration.fieldType === FieldType.TextArea) {
          return of({
            icon: 'fa-text-width',
            fieldType: FieldType.TextArea,
            values: [{ label: response }],
          } as CFPreviewItem);
        }
        if (configuration.fieldType === FieldType.TextEditor) {
          return of({
            icon: 'fa-text-width',
            fieldType: FieldType.TextArea,
            values: [{ label: response }],
          } as CFPreviewItem);
        }
        if (configuration.fieldType === FieldType.ValueList) {
          return of({
            iconSvg: 'glideCustomIcon_value-list',
            fieldType: FieldType.ValueList,
            values: (response || []).map((label) => ({ label })),
          } as CFPreviewItem);
        }
        if (configuration.fieldType === FieldType.Snippet) {
          return of({
            iconSvg: 'glideCustomIcon_snippet',
            fieldType: FieldType.Snippet,
            values: response ? [{ label: response }] : [],
          } as CFPreviewItem);
        }
      }),
      map((data) => {
        valueAsArray(data).forEach((item) => (item.glideData = item.glideData || glideData));
        return data;
      })
    );
  }

  private async prepareImageFieldPreviewData(input) {
    const previewItem: CFPreviewItem = {
      icon: 'fa-image',
      fieldType: FieldType.Image,
      values: [],
    };
    if (!input) {
      return previewItem;
    }
    const { data, sysMeta } = input;
    if (!sysMeta.allowed) {
      return { ...previewItem, notAllowed: true };
    }
    const thumbnail = await this.getValidatedImageUrl(data.previewImage);
    const label = data.id ? data.imageMetaData?.caption : `[Image - ${sysMeta.id}]`;
    const link = data.id && `media/images/${data.id}`;
    const tooltip = !data.id && { message: $localize`Image deleted` };
    return { ...previewItem, thumbnail, values: [{ label, link, tooltip }] } as CFPreviewItem;
  }

  private async prepareGalleryFieldPreviewData(input) {
    const previewItem: CFPreviewItem = {
      iconSvg: 'glideCustomIcon_galleries',
      fieldType: FieldType.Gallery,
      values: [],
    };
    if (!input) {
      return previewItem;
    }
    const { data, sysMeta } = input;
    if (!sysMeta.allowed) {
      return { ...previewItem, notAllowed: true };
    }
    const revision = data.latest || {};
    const label = revision.title || `[Gallery - ${sysMeta.id}]`;
    const link = data.id && `media/galleries/${data.id}`;
    const contentLocaleId = data.contentLocaleId;
    let status;
    let tooltip;
    if (!data?.id) {
      tooltip = { message: $localize`Gallery deleted` };
    } else if (!data.published_revision_id) {
      status = { name: revision.status?.name, tooltip: $localize`No published revision` };
    } else {
      status = {
        name: 'Published',
        tooltip: $localize`Last Publish: ${formatDate(data.published_at)}`,
      };
    }

    const galleryImages = (revision?.images || [])
      .map((img) => img.formats.find(({ type }) => type === 'gm_preview'))
      .map(({ key }) => this.imagesService.generateImageUrl(key));
    const images = (
      await Promise.all(
        [revision.promoImage?.previewImage, ...galleryImages]
          .slice(0, 4)
          .map((url) => this.getValidatedImageUrl(url))
      )
    ).filter((url) => !!url);
    return {
      ...previewItem,
      contentLocaleId,
      images,
      status,
      values: [{ label, link, tooltip }],
    } as CFPreviewItem;
  }

  private async prepareArticleFieldPreviewData(input) {
    const multiple = Array.isArray(input);
    const res = await Promise.all(
      valueAsArray(input).map(async (val) => {
        const previewItem: CFPreviewItem = {
          icon: 'fa-quote-right',
          fieldType: FieldType.Article,
          values: [],
        };
        if (!val) {
          return previewItem;
        }
        const { data, sysMeta } = val;
        if (!sysMeta.allowed) {
          return { ...previewItem, notAllowed: true };
        }
        const thumbnail = await this.getValidatedImageUrl(data.promoImageURL);
        const label = data.headline || `[Article - ${sysMeta.id}]`;
        const link = data.id && `articles/${data.id}`;
        const contentLocaleId = data.contentLocaleId;
        let status: any = data?.id ? { name: data?.status?.name } : null;
        let tooltip;
        if (!data.id) {
          tooltip = { message: $localize`Article deleted` };
        } else if (!data.publishedRevisionId && !data.scheduledRevision) {
          status.tooltip = $localize`No published revision`;
        } else if (data.scheduledRevision) {
          const publishDate = data.scheduledRevision?.publishDateFrom;
          status = {
            tooltip: $localize`Publish scheduled: ${formatDate(publishDate)}`,
            showScheduleIcon: true,
          };
        } else if (data.publishedRevisionId && data.unpublishedOn) {
          status = {
            name: 'Unpublished',
            tooltip: $localize`Unpublish scheduled: ${formatDate(data.unpublishedOn)}`,
            showScheduleIcon: true,
          };
        } else {
          status = {
            name: 'Published',
            tooltip: $localize`Last Publish: ${formatDate(
              data.transmitUpdatedAt || data.publishedOn
            )}`,
          };
        }
        return {
          ...previewItem,
          thumbnail,
          contentLocaleId,
          status,
          values: [{ label, link, tooltip }],
        } as CFPreviewItem;
      })
    );
    return multiple ? res : res[0];
  }

  private async prepareCollectionFieldPreviewData(input) {
    const multiple = Array.isArray(input);
    const res = await Promise.all(
      valueAsArray(input).map(async (val) => {
        const previewItem: CFPreviewItem = {
          iconSvg: 'glideCustomIcon_collections',
          fieldType: FieldType.Collection,
          values: [],
        };
        if (!val) {
          return previewItem;
        }
        const { data, sysMeta } = val;
        if (!sysMeta.allowed) {
          return { ...previewItem, notAllowed: true };
        }
        const imgUrl = this.imagesService.generateImageUrl(get(data, 'image.path', ''));
        const thumbnail = await this.getValidatedImageUrl(imgUrl);
        const label = data.name || `[Collection - ${sysMeta.id}]`;
        const link = data.id && `collections/${data.id}`;
        let status: any = data?.id ? { name: data?.status?.name } : null;
        let tooltip;
        if (!data.id) {
          tooltip = { message: $localize`Collection deleted` };
        } else if (!data.publishedRevisionId && !data.scheduledRevision) {
          status.tooltip = $localize`No published revision`;
        } else if (data.scheduledRevision) {
          const publishDate = data.scheduledRevision?.publishDateFrom;
          status.tooltip = $localize`Publish scheduled: ${formatDate(publishDate)}`;
          status.showScheduleIcon = true;
        } else if (data.publishedRevisionId && data.unpublishedAt) {
          status.name = 'Unpublished';
          status.tooltip = $localize`Unpublish scheduled: ${formatDate(data.unpublishedAt)}`;
          status.showScheduleIcon = true;
        } else {
          status.name = 'Published';
          status.tooltip = $localize`Last Publish: ${formatDate(
            data.transmitUpdatedAt || data.publishedAt
          )}`;
        }
        return {
          ...previewItem,
          thumbnail,
          status,
          values: [{ label, link, tooltip }],
        } as CFPreviewItem;
      })
    );
    return multiple ? res : res[0];
  }

  private async prepareLiveReportFieldPreviewData(input) {
    const multiple = Array.isArray(input);
    const res = await Promise.all(
      valueAsArray(input).map(async (val) => {
        const previewItem: CFPreviewItem = {
          iconSvg: 'glideCustomIcon_liveReport',
          fieldType: FieldType.LiveReport,
          values: [],
        };
        if (!val) {
          return previewItem;
        }
        const { data, sysMeta } = val;
        if (!sysMeta.allowed) {
          return { ...previewItem, notAllowed: true };
        }
        const activeRevision = data.publishedRevision || data.workingRevision || {};
        const imgUrl = this.imagesService.generateImageUrl(
          get(activeRevision, 'promoDetails.image.path', '')
        );
        const thumbnail = await this.getValidatedImageUrl(imgUrl);
        const label = activeRevision.headline || `[Live Report - ${sysMeta.id}]`;
        const link = data.id && `live-reporting/${data.id}`;
        let status;
        let tooltip;
        if (!data.id) {
          tooltip = { message: $localize`Live Report deleted` };
        } else if (!data.publishedRevision) {
          status = { name: 'Draft', tooltip: $localize`No published revision` };
        } else if (!data.publishedRevision.publishFrom && !data.publishedRevision.publishTo) {
          status = {
            name: 'Published',
            tooltip: $localize`Last Publish: ${formatDate(data.lastPublishedAt)}`,
          };
        } else if (data.publishedRevision.publishFrom) {
          status = {
            name: 'Scheduled',
            tooltip: $localize`Publish scheduled: ${formatDate(
              data.publishedRevision.publishFrom
            )}`,
            showScheduleIcon: true,
          };
        } else {
          status = {
            name: 'Unpublished',
            tooltip: $localize`Unpublish scheduled: ${formatDate(
              data.publishedRevision.publishTo
            )}`,
            showScheduleIcon: true,
          };
        }
        return {
          ...previewItem,
          thumbnail,
          status,
          values: [{ label, link, tooltip }],
        } as CFPreviewItem;
      })
    );
    return multiple ? res : res[0];
  }

  private async prepareArticleTypeFieldPreviewData(input) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.name || `[Article Type - ${sysMeta.id}]`;
        const link = data.id && `site-builder/article-types/${data.id}`;
        const tooltip = !data.id && { message: $localize`Article type deleted` };
        return { label, link, tooltip };
      });
    return {
      iconSvg: 'glideCustomIcon_article-configuration',
      fieldType: FieldType.ArticleType,
      values: notAllowed ? [] : values,
      notAllowed
    } as CFPreviewItem;
  }

  private async prepareCollectionTypeFieldPreviewData(input) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.name || `[Collection Type - ${sysMeta.id}]`;
        const link = data.id && `site-builder/collection-types/${data.id}`;
        const tooltip = !data.id && { message: $localize`Collection type deleted` };
        return { label, link, tooltip };
      });
    return {
      iconSvg: 'glideCustomIcon_collection-configuration',
      fieldType: FieldType.CollectionType,
      values: notAllowed ? [] : values,
      notAllowed
    } as CFPreviewItem;
  }

  private async prepareContentTagFieldPreviewData(input) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.name || `[Content Tag - ${sysMeta.id}]`;
        const link = data.id && `site-builder/content-tags/${data.id}`;
        const tooltip = !data.id && { message: $localize`Content tag deleted` };
        return { label, link, tooltip };
      });
    return {
      iconSvg: 'glideCustomIcon_content-tags-confguration',
      fieldType: FieldType.ContentTag,
      values: notAllowed ? [] : values,
      notAllowed
    } as CFPreviewItem;
  }

  private async prepareHTMLSnippetFieldPreviewData(input) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.label || `[HTML Snippet - ${sysMeta.id}]`;
        const link = data.id && `widgets/html-snippets/${data.id}`;
        const tooltip = !data.id && { message: $localize`HTML Snippet deleted` };
        return { label, link, tooltip };
      });
    return {
      icon: 'fa-code',
      fieldType: FieldType.HTMLSnippet,
      values: notAllowed ? [] : values,
      notAllowed,
    } as CFPreviewItem;
  }

  private async prepareContentLocaleFieldPreviewData(input) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.label || `[Content Locale - ${sysMeta.id}]`;
        const link = data.id && `site-builder/content-locales/${data.id}`;
        let tooltip;
        if (!data.id) {
          tooltip = { message: $localize`Content locale deleted` };
        } else if (!data.active) {
          tooltip = { message: $localize`Content locale is not active`, color: '#e9ebf7' };
        }
        return { label, link, tooltip, icon: data.iconUrl };
      });
    return {
      iconSvg: 'glideCustomIcon_locale',
      fieldType: FieldType.ContentLocale,
      values: notAllowed ? [] : values,
      notAllowed,
    } as CFPreviewItem;
  }

  private async prepareAuthorFieldPreviewData(input) {
    let notAllowed = false;
    let images = [];
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.name || `[Author - ${sysMeta.id}]`;
        const link = data.id && `authors/${data.id}`;
        let tooltip = !data.id && { message: $localize`Author deleted` };
        images.push(data?.image?.url);
        return { label, link, tooltip, icon: data.iconUrl };
      });
    images = await Promise.all(images.map((url) => this.getValidatedImageUrl(url)));
    return {
      iconSvg: 'glideCustomIcon_authors',
      fieldType: FieldType.Author,
      values: notAllowed ? [] : values.map((val, i) => ({
        ...val,
        icon: images[i] || 'assets/img/avatars/noavatar.png',
      })),
      notAllowed
    } as CFPreviewItem;
  }

  private async prepareMenuFieldPreviewData(input) {
    const multiple = Array.isArray(input);
    const res = await Promise.all(
      valueAsArray(input).map(async (val) => {
        const previewItem: CFPreviewItem = {
          icon: 'fa-bars',
          fieldType: FieldType.Menu,
          values: [],
        };
        if (!val) {
          return previewItem;
        }
        const { data, sysMeta } = val;
        if (!sysMeta.allowed) {
          return { ...previewItem, notAllowed: true };
        }
        const thumbnail = await this.getValidatedImageUrl(data?.icon?.url);
        const label = data.name || `[Menu - ${sysMeta.id}]`;
        const link = data.id && `widgets/menus/${data.id}`;
        const tooltip = !data.id && { message: $localize`Menu deleted` };

        return { ...previewItem, thumbnail, values: [{ label, tooltip, link }] } as CFPreviewItem;
      })
    );
    return multiple ? res : res[0];
  }

  private async prepareFileFieldPreviewData(input) {
    const multiple = Array.isArray(input);
    const res = await Promise.all(
      valueAsArray(input).map(async (val) => {
        const previewItem: CFPreviewItem = {
          icon: 'fa-file',
          fieldType: FieldType.File,
          values: [],
        };
        if (!val) {
          return previewItem;
        }
        const { data, sysMeta } = val;
        if (!sysMeta.allowed) {
          return { ...previewItem, notAllowed: true };
        }
        const fileType = data.filename && data.filename.match(/[^.]+$/g)[0];
        const fileIcon = this.filesService.resolveFileThumbnail(fileType);
        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'ico', 'webp'].includes(fileType);
        let thumbnail;
        if (isImage) {
          const imgUrl = this.imagesService.generateImageUrl(data.key);
          thumbnail = await this.getValidatedImageUrl(imgUrl);
        }
        const label = data?.meta_data?.name || data?.filename || `[File - ${sysMeta.id}]`;
        const link = data.id && `media/files/edit/${data.id}`;
        const tooltip = !data.id && { message: $localize`File deleted` };
        return {
          ...previewItem,
          thumbnail,
          // icon: fileIcon,
          values: [{ label, link, tooltip }],
        } as CFPreviewItem;
      })
    );
    return multiple ? res : res[0];
  }

  private async prepareCFGFieldPreviewData(input) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        const label = data.name || `[Custom Field Group - ${sysMeta.id}]`;
        const link = data.id && `site-builder/custom-field-groups/${data.id}`;
        const tooltip = !data.id && { message: $localize`Custom field group deleted` };
        return { label, link, tooltip };
      });
    return {
      iconSvg: 'glideCustomIcon_cfg',
      fieldType: FieldType.CFG,
      values: notAllowed ? [] : values,
      notAllowed
    } as CFPreviewItem;
  }

  private async prepareTaxonomyFieldPreviewData(input, allTaxonomies, localeId = null) {
    let notAllowed = false;
    const values = valueAsArray(input)
      .filter((v) => !!v)
      .map(({ data, sysMeta }) => {
        notAllowed = notAllowed || !sysMeta.allowed;
        let label = `[Taxonomy - ${sysMeta.id}]`;
        const link = data.id && `taxonomies/${data.id}`;
        let tooltip: any = !data.id && { message: $localize`Taxonomy deleted` };
        let alt;

        if (data.id) {
          const taxonomy = processTaxonomies([data], allTaxonomies, true, null, false, localeId)[0];
          label = taxonomy.taxonomyDisplayData
            .map((part) => (part === 'NODE_PLACEHOLDER' ? '...' : part))
            .join(' > ');
          alt = taxonomy.fullPath;
          tooltip = localeId &&
            !taxonomy.localizedDisplayData[localeId] && {
              message: $localize`Taxonomy is missing corresponding localisation`,
              color: 'unset',
            };
        }

        return { label, link, tooltip, alt };
      });
    return {
      iconSvg: 'glideCustomIcon_taxonomies',
      fieldType: FieldType.Taxonomy,
      values: notAllowed ? [] : values,
      notAllowed
    } as CFPreviewItem;
  }

  private async prepareCheckboxFieldPreviewData(selection, configuration) {
    const values = (configuration.dataSource.value || []).map(({ label, value }) => {
      return { label, checked: (selection || []).includes(value) } as CFPreviewItemValue;
    });
    return {
      iconSvg: 'glideCustomIcon_checkbox',
      fieldType: FieldType.CheckboxGroup,
      values,
    } as CFPreviewItem;
  }

  private async prepareRadioFieldPreviewData(selectedVal, configuration) {
    const values = (configuration.dataSource.value || []).map(({ label, value }) => {
      return { label, checked: value === selectedVal } as CFPreviewItemValue;
    });
    return {
      iconSvg: 'glideCustomIcon_radio',
      fieldType: FieldType.RadioGroup,
      values,
    } as CFPreviewItem;
  }

  private async prepareFormFragmentPreviewData(input, configuration) {
    const previewItem: CFPreviewItem = {
      icon: 'fa-list-alt',
      fieldType: FieldType.FormFragment,
      values: [],
    };
    const cfgId = get(configuration, 'dataSource.value', null);
    if (!cfgId) {
      return previewItem;
    }
    const cfg = await firstValueFrom(this.getData(cfgId, ResourceType.CFG));
    if (!cfg?.data?.id) {
      const tooltip = { message: $localize`Custom field group deleted` };
      return { ...previewItem, values: [{ label: `[Custom Field Group - ${cfgId}]`, tooltip }] };
    }
    const cfgFields = cfg.data.fieldDefinitions.reduce((acc, step) => {
      return [...acc, ...(step.fields || [])];
    }, []);
    const previewField = cfgFields.find((field) => field.key === cfg.data.previewField);
    const validatedInput = valueAsArray(input).filter((item) => typeof item === 'object');
    if (!previewField || !validatedInput.length) {
      previewItem.fieldLabel = previewField?.label || '';
      return previewItem;
    }
    const previewItems = await firstValueFrom(
      forkJoin(
        validatedInput.map((item) =>
          this.getCustomFieldPreviewData(item[previewField.key], previewField, null, item)
        )
      )
    ).then((items: any) => {
      return items
        .reduce((acc, item) => [...acc, ...valueAsArray(item)], [])
        .map((item) => ({ ...item, fieldLabel: previewField.label }));
    });
    return previewItems.length > 1 ? previewItems : previewItems[0];
  }

  private async getValidatedImageUrl(url) {
    if (!url) {
      return null;
    }
    const { isValid }: any = await this.imagesService.validateImageUrl(url);
    return isValid ? url : null;
  }
}

function formatDate(date) {
  return moment(date).format('DD-MMM-YYYY h:mm A');
}
