import { PreviewV1Service } from './preview-v1.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lowerCase } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { PreviewProviderVersions } from './preview-provider-versions.enum';

interface ConfigurationCheckStatus {
  validProviderForVersion: PreviewProviderVersions;
  message?: string;
  errorStatus?: any;
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PreviewProviderIntegrationService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private previewV1Service: PreviewV1Service
  ) { }

  testConfiguration(config = { apiUrl: '', httpHeaders: [], version: 'NO' }): Observable<ConfigurationCheckStatus> {

    if (!config || config.version === PreviewProviderVersions.NoProvider) {
      return of({ validProviderForVersion: PreviewProviderVersions.NoProvider });
    }

    const requestOptions = this.resolveRequestOptions({
      httpHeaders: config.httpHeaders,
      version: config.version
    });

    if (config.version === PreviewProviderVersions.V1) {
      // use the url construction logic from preview v1 service to get exact url which is used
      const urlToTest = this.previewV1Service.getApiUrl();
      return this.http.get(urlToTest + '/status', requestOptions)
        .pipe(
          map((response: any) => {
            // validate that the response is valid Preview V1 API
            const statusSuccess = response.status === 'SUCCESS';
            const responseData = response.data || {};
            const hasPreviewServiceStatus = responseData.hasOwnProperty('previewServiceStatus');
            const apiReady = hasPreviewServiceStatus && responseData.previewServiceStatus === 'READY';
            const v1ValidApi = statusSuccess || apiReady;
            return v1ValidApi
              ? {
                validProviderForVersion: PreviewProviderVersions.V1,
                message: 'Status check successful, Preview V1 provider ready for requests.'
              }
              : {
                validProviderForVersion: null,
                errorMessage: 'This endpoint provided does not point to a valid V1 preview service API'
              };
          }),
          catchError(err => {
            return of({
              validProviderForVersion: null,
              errorStatus: err.status,
              errorMessage: err.message
            });
          })
        );
    }

    if (config.version === PreviewProviderVersions.V2) {
      return this.http.get(config.apiUrl, requestOptions)
        .pipe(
          map((response: any) => {
            // preview API v2 requires only 200 response on the endpoint as minimum
            const v2ValidApi = true;
            // validate that the response is valid Preview V1 API
            let message = $localize`This endpoint points to a potentially valid Preview V2 provider service.`;

            if (response && response.contentTypes && response.contentTypes.length > 0) {
              message = $localize`The API URL points to a valid Preview v2 provider service, supporting preview for content types: `
                + response.contentTypes.map(ct => ct[0].toUpperCase() + ct.slice(1).toLowerCase()).join(', ')
                + '.';
            }

            if (response && response.additionalFeatures && response.additionalFeatures.length > 0) {
              message += $localize`\nAdditional supported features: `
                + response.additionalFeatures.map(af => lowerCase(af)).join(', ')
                + '.';
            }

            return {
              validProviderForVersion: PreviewProviderVersions.V2,
              message: message,
            };

          }),
          catchError(err => {
            return of({
              validProviderForVersion: null,
              errorStatus: err.status,
              errorMessage: err.message
            });
          })
        );

    }

    return of({ validProviderForVersion: null });
  }

  public resolveRequestOptions({ httpHeaders, version }: { httpHeaders: any[]; version }) {
    const requestOptions: any = { withCredentials: false };
    if (httpHeaders && httpHeaders.length > 0) {
      const headersRaw = httpHeaders.reduce((acc, { label, value }) => {
        acc[label] = value;
        return acc;
      }, {});

      // application/json is always the default implicit content type
      headersRaw['content-type'] = `application/json`;

      // preview V1 requires auth token and application/json content type headers
      if (version === PreviewProviderVersions.V1) {
        const authToken = this.authService.authToken;
        headersRaw.authorization = `Bearer ${authToken}`;
      }

      requestOptions.headers = new HttpHeaders(headersRaw);
    }
    return requestOptions;
  }
}

