import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
  GET_AUTHORS, GET_AUTHORS_SUCCESS, CREATE_AUTHOR, UPDATE_AUTHOR, DELETE_AUTHOR,
  CREATE_AUTHOR_SUCCESS, UPDATE_AUTHOR_SUCCESS, DELETE_AUTHOR_SUCCESS, SET_AUTHOR_LOADING_FLAG, SET_AUTHORS_PAGE_VIEW_OPTIONS,
  GET_LOGGED_IN_AUTHOR,
  GET_LOGGED_IN_AUTHOR_SUCCESS
} from './authors.actions';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';

export interface Author {
  id: number;
  name: string;
  about: string;
  image: any;
  user: any;
}

export interface AuthorsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  authors: Author[];
  authorsView?: AuthorsPageView;
  loggedAuthorState?: { data: Author, loaded: boolean };
}

export interface AuthorsPageView {
  currentPage: number;
  total: number;
  pageSize: number;
  pageSizeOptions: any[];
}

const initialState: AuthorsState = {
  loaded: false,
  loading: false,
  error: null,
  authors: [],
  authorsView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  loggedAuthorState: { data: null, loaded: false }
};

export function authorsReducer(state: AuthorsState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_AUTHORS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_AUTHORS_SUCCESS: {
      const authors = action.payload.data;
      const meta = action.payload.meta;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        authors,
        authorsView: {
          ...state.authorsView,
          total: meta.page.total
        }
      };
    }

    case CREATE_AUTHOR:
    case UPDATE_AUTHOR:
    case DELETE_AUTHOR: {
      return { ...state, loading: true };
    }

    case CREATE_AUTHOR_SUCCESS: {
     return { ...state, loading: false };
    }
    case UPDATE_AUTHOR_SUCCESS: {
      const updatedAuthor = action.payload;
      const updatedIndex = state.authors.findIndex(el => el.id === updatedAuthor.id);
      const newAuthors = [
        ...state.authors.slice(0, updatedIndex),
        updatedAuthor,
        ...state.authors.slice(updatedIndex + 1)
      ];
      return {
        ...state,
        loading: false,
        authors: newAuthors
        };
    }

    case DELETE_AUTHOR_SUCCESS: {
      const deletedId = action.payload;
      const authors = state.authors.filter(author => author.id !== deletedId);
      return {
        ...state,
        loading: false,
        error: null,
        authors
      };
    }

    case SET_AUTHORS_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        authorsView: {
          ...state.authorsView,
          currentPage: pageIndex,
          pageSize: pageSize || state.authorsView.pageSize
        }
      };
    }

    case SET_AUTHOR_LOADING_FLAG: {
      return { ...state, loading: action.payload };
    }

    case GET_LOGGED_IN_AUTHOR: {
      return { ...state, loggedAuthorState: { data: null, loaded: false } };
    }

    case GET_LOGGED_IN_AUTHOR_SUCCESS: {
      return { ...state, loggedAuthorState: { data: action.payload, loaded: true } };
    }

    default: {
      return state;
    }
  }

}

export const getAuthorsState = (state) => state.authors;

export const getAuthorsPageView = createSelector(getAuthorsState, (state):AuthorsPageView => state.authorsView);

export const getAuthorsList = createSelector(getAuthorsState, (state):Author[] => state.authors);

export const getAuthor = id => createSelector(getAuthorsState, state => state.authors.find(author => author.id === id));

export const getAuthorLoadingFlag = (state) => state.authors.loading;

export const getLoggedInAuthorState = (state) => state.authors.loggedAuthorState;
