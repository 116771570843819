<div class="gd-taxonomies-list-chip-wrapper" [style.maxWidth]="width">
  <mat-chip-list class="gd-taxonomies-list">
    <mat-chip class="gd-taxonomies-list-chip"
              *ngFor="let taxonomy of taxonomyDisplayData && taxonomyDisplayData.visibleTaxonomies"
              [class.--primary]="taxonomy.isPrimaryTaxonomy"
              [class.--secondary]="!taxonomy.isPrimaryTaxonomy"
              [matTooltip]="taxonomy.fullPath">
      <div *ngIf="!showTaxonomyPath"
           class="chip-name">{{taxonomy.name}}</div>
      <div *ngIf="showTaxonomyPath"
           class="chip-name">
        <span *ngFor="let name of taxonomy.taxonomyDisplayData; let last = last; let first = first;" [dir]="dir$ | async">
          <mat-icon class="arrow-taxonomy-icon"
                    *ngIf='!first'
                    fontSet="fal"
                    [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"> </mat-icon>
          <mat-icon *ngIf="name === 'NODE_PLACEHOLDER'"
                    class="arrow-taxonomy-icon"
                    fontSet="fal"
                    fontIcon="fa-ellipsis-h-alt">
          </mat-icon>
          <span *ngIf="name !== 'NODE_PLACEHOLDER'">{{ name }}</span>
        </span>
      </div>
    </mat-chip>
    <mat-chip class="gd-taxonomies-list-chip --unShownTaxos"
              *ngIf="!!taxonomyDisplayData.infoChip"
              [gdCheckMoreTooltip]="{type: 'taxonomies', data: taxonomyDisplayData && taxonomyDisplayData.hiddenTaxonomies}">
      {{ taxonomyDisplayData.infoChip.name }}
    </mat-chip>
  </mat-chip-list>
</div>
