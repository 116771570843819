import { UnsafeAction } from '../unsafe-action.interface';

export const GET_CONTENT_QUEUES = '[Content Queues] Get List';
export class GetContentQueuesAction implements UnsafeAction {
  readonly type = GET_CONTENT_QUEUES;
  constructor(public payload: any = {}) {}
}

export const GET_CONTENT_QUEUES_SUCCESS = '[Content Queues] Get List Success';
export class GetContentQueuesSuccessAction implements UnsafeAction {
  readonly type = GET_CONTENT_QUEUES_SUCCESS;
  constructor(public payload: any) {}
}

export const GET_ACTIVE_CONTENT_QUEUE = '[Content Queues] Get Active Content Queue';
export class GetActiveContentQueueAction implements UnsafeAction {
  readonly type = GET_ACTIVE_CONTENT_QUEUE;
  constructor(public payload: number) {}
}

export const CREATE_CONTENT_QUEUE = '[Content Queues] Create Content Queue';
export class CreateContentQueueAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_QUEUE;
  constructor(public payload: any) {}
}

export const UPDATE_CONTENT_QUEUE = '[Content Queues] Update Content Queue';
export class UpdateContentQueueAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_QUEUE;
  constructor(public payload: any) {}
}

export const SET_ACTIVE_CONTENT_QUEUE = '[Content Queues] Set Active Content Queue';
export class SetActiveContentQueueAction implements UnsafeAction {
  readonly type = SET_ACTIVE_CONTENT_QUEUE;
  constructor(public payload: any) {}
}

export const SET_CONTENT_QUEUES_LOADING_FLAG = '[Content Queues] Set Content Queues Loading Flag';
export class SetContentQueuesLoadingFlagAction implements UnsafeAction {
  readonly type = SET_CONTENT_QUEUES_LOADING_FLAG;
  constructor(public payload: any) {}
}

export const CONTENT_QUEUES_ACTION_FAILED = '[Content Queues] Generic failure action';
export class ContentQueuesFailedAction implements UnsafeAction {
  readonly type = CONTENT_QUEUES_ACTION_FAILED;
  constructor(public payload: any) {}
}


export const SET_CONTENT_QUEUES_PAGE_VIEW_OPTIONS = '[Content Queues] Set page view options';
export class SetContentQueuesPageViewOptions implements UnsafeAction {
  readonly type = SET_CONTENT_QUEUES_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}
