export interface AdvancedFiltersState {
  loaded: boolean;
  loading: boolean;
  filters: ContentFilter[];
  error: any;
  queries: { [key: string]: ContentFilterQuery };
  activeFilterQuerry?: Filter;
}

export interface ContentFilter {
  id: string;
  query: any;
  domain: string;
  name: string;
  slug: string;
  visibility: string;
  createdBy: string;
  defaultFilter: boolean; // calculated property
}

export interface ContentFilterQuery {
  id: string;
  query: any;
}

export interface Filter {
  include?: {
    sysUpdatedAt?: { gte?: number; lte?: number; eq?: number; relative?: number; };
    updatesAvailable?: boolean;
    publicationState?: PublicationState;
    headlineOrCatchline?: { like?: string; eq?: string; };
    ids?: number[];
    statusIds?: number[];
    typeIds?: number[];
    updatedBy?: number[];
    taxonomyIds?: number[];
    contentLocaleIds?: number[];
    customFieldTaxonomyIds?: number[];
    collectionIds?: number[];
    firstPublishedAt?: { gte?: number; lte?: number; eq?: number; relative?: number; };
    lastPublishedAt?: { gte?: number; lte?: number; eq?: number; relative?: number; };
  };
  includeSome?: {
    taxonomyIds?: number[];
    authorIds?: number[];
    customFieldTaxonomyIds?: number[];
    statusIds?: number[];
  };
  exclude?: {
    headlineOrCatchline?: { like?: string; eq?: string; };
    ids?: number[];
    statusIds?: number[];
    typeIds?: number[];
    updatedBy?: number[];
    taxonomyIds?: number[];
  };
  excludeSome?: {
    taxonomyIds?: number[];
    authorIds?: number[];
    statusIds?: number[];
  };
}

export enum PublicationState {
  Active = 'Active',
  Scheduled = 'Scheduled',
  Expired = 'Expired',
  ScheduledOrPublished = 'ScheduledOrPublished'
}

export function getEmptyContentFilter() {
  return {
    include: {},
    includeSome: {},
    exclude: {},
    excludeSome: {}
  };
}
