import { mergeMap, catchError, map, withLatestFrom, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';
import {
  GET_AUTHORS,
  AuthorFailedAction,
  GetAuthorsSuccessAction,
  CREATE_AUTHOR,
  CreateAuthorSuccessAction,
  DELETE_AUTHOR,
  DeleteAuthorSuccessAction,
  UPDATE_AUTHOR,
  UpdateAuthorSuccessAction,
  AUTHOR_ACTION_FAILED,
  SetAuthorLoadingFlagAction,
  GET_LOGGED_IN_AUTHOR,
  GetLoggedInAuthorSuccessAction,
} from './authors.actions';
import { AuthorsService } from '../../api/authors/authors.service';
import { AuthService } from '../../api';

@Injectable()
export class AuthorsEffects {

  loadAuthors$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_AUTHORS),
    withLatestFrom(this.store.select((state) => state.authors.authorsView)),
    mergeMap(([action, pageView]: [UnsafeAction, any]) => {
      return this.authorsService.getAuthors({ ...pageView, ...action.payload }).pipe(
        map((response) => new GetAuthorsSuccessAction(response)),
        catchError((e) => of(new AuthorFailedAction(e)))
      );
    })
  ));


  createAuthor$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_AUTHOR),
    mergeMap((action: UnsafeAction) => {
      const author = action.payload;
      delete author.id;
      return this.authorsService.createAuthor(author).pipe(
        map((data) => new CreateAuthorSuccessAction(data)),
        tap(() => {
          this.snackBar.open($localize`Author created successfully.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/authors']);
        }),
        catchError((e) => of(new AuthorFailedAction(e)))
      );
    })
  ));


  deleteAuthor$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_AUTHOR),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.authorsService.deleteAuthor(id).pipe(
        map(() => new DeleteAuthorSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Author deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Cannot delete an author which is assigned to an article.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new SetAuthorLoadingFlagAction(false));
        })
      );
    })
  ));


  updateAuthor$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_AUTHOR),
    mergeMap((action: UnsafeAction) => {
      return this.authorsService.updateAuthor(action.payload).pipe(
        map((data) => new UpdateAuthorSuccessAction(data)),
        tap(() => {
          this.router.navigate(['/authors']);
          this.snackBar.open($localize`Author successfully updated.`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update author!`, $localize`Close`);
          return of(new AuthorFailedAction(e));
        })
      );
    })
  ));

  getLoggedInAuthor$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_LOGGED_IN_AUTHOR),
    mergeMap((action: UnsafeAction) => {
      return this.authorsService.getAuthorByUserId(this.authService.getUserId()).pipe(
        map((data) => new GetLoggedInAuthorSuccessAction(data)),
        catchError((e) => {
          // this.snackBar.open($localize`Failed to fetch the author!`, $localize`Close`);
          return of(new AuthorFailedAction(e));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AUTHOR_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private authorsService: AuthorsService,
    private authService: AuthService
  ) {}
}
