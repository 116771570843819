import { of, throwError } from 'rxjs';
import { AccountSettingsService } from './../account-settings/accounts-settings.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PreviewProviderIntegrationService } from './preview-provider-integration.service';
import { PreviewProviderVersions } from './preview-provider-versions.enum';
import { catchError, debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import * as shortid from 'shortid';
import { ArticlesService } from '../articles/articles.service';
import { CollectionsService } from '../collections/collections.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions } from '@ngrx/effects';
import { UPDATE_ACCOUNT_SETTINGS_SUCCESS } from '../../store/account-settings/account-settings.actions';

@Injectable({
  providedIn: 'root',
})
export class PreviewV2Service {
  // added to avoid sending a lot of requests for checking the status
  // will be updated automatically on the account settings update action
  cachedStatusInfo: {
    enabled: boolean;
    configured: boolean;
    hasError?: boolean;
    allowedTypes: string[];
  } = null;

  constructor(
    private http: HttpClient,
    private settingsService: AccountSettingsService,
    private previewIntegrationService: PreviewProviderIntegrationService,
    private articlesService: ArticlesService,
    private collectionsService: CollectionsService,
    private snackBar: MatSnackBar,
    private actions$: Actions
  ) {
    this.actions$
      .pipe(filter((action) => action.type === UPDATE_ACCOUNT_SETTINGS_SUCCESS), debounceTime(200))
      .subscribe(() => this.getPreviewStatusInfo());
  }

  checkIfServiceAllowsForPreviewOfType(contentType) {
    const previewSettings = this.settingsService.getPreviewServiceSettings();
    if (!previewSettings.version || !previewSettings.apiUrl) {
      return throwError(new Error($localize`Invalid preview V2 settings!`));
    }

    const httpHeaders = previewSettings.httpHeaders || [];
    const requestOptions = this.previewIntegrationService.resolveRequestOptions({
      httpHeaders: httpHeaders,
      version: PreviewProviderVersions.V2
    });

    return this.http.get(previewSettings.apiUrl, requestOptions)
      .pipe(map((response: any) => {
        // if the response does not advertise which items can be previewed, assume all can be
        // and delegate to preview service provider to handle invalid requests
        if (!response.contentTypes) {
          return true;
        }

        // otherwise check if the specific content type is in the array of those
        // preview service provider claims it can handle
        return response.contentTypes.includes(contentType);
      }));
  }

  getPreview(config) {
    const previewSettings = this.settingsService.getPreviewServiceSettings();
    if (!previewSettings.version || previewSettings.apiUrl) {
      throwError(new Error($localize`Invalid preview V2 settings!`));
    }

    if (!config || config.type || !(config.contentId || config.url)) {
      throwError(new Error($localize`Invalid preview request configuration!`));
    }

    const previewId = this.generatePreviewId(config);
    const { stagedRevisionId, contentType } = config;

    const httpHeaders = previewSettings.httpHeaders || [];
    const requestOptions = this.previewIntegrationService.resolveRequestOptions({
      httpHeaders: httpHeaders,
      version: PreviewProviderVersions.V2
    });

    return this.getContentUrl(contentType, stagedRevisionId).pipe(
      mergeMap(contentUrl => this.http.post(previewSettings.apiUrl, { ...config, previewId, contentUrl }, requestOptions)),
      catchError(({ error, message }) => {
        const errorMsg = typeof error === 'string' ? error : (message || $localize`Failed to render the preview page!`);
        this.snackBar.open(errorMsg, $localize`Close`, { duration: 10000 });
        return of(null);
      })
    );
  }

  generatePreviewId({ contentId, contentGlideId, contentType }) {
    if (contentGlideId) {
      return 'ps' + contentGlideId;
    }

    if (contentId && contentType) {
      return `ps-${contentType}-contentId`;
    }

    return `ps-` + shortid.generate();
  }

  getContentUrl(contentType, revisionId) {
    switch(contentType) {
      case 'ARTICLE':
        return this.articlesService.getArticleRevisionUrlPreview(revisionId);
      case 'COLLECTION':
        return this.collectionsService.getCollectionRevisionUrlPreview(revisionId);
      default:
        return of('');
    }
  }

  getPreviewStatusInfo() {
    this.cachedStatusInfo = null;
    const enabled = this.settingsService.getPreviewServiceVersion() === 'V2';
    const previewSettings = this.settingsService.getPreviewServiceSettings();
    if (!previewSettings.version || !previewSettings.apiUrl) {
      this.cachedStatusInfo = { enabled, configured: false, allowedTypes: [] };
      return of(this.cachedStatusInfo);
    }
    const httpHeaders = previewSettings.httpHeaders || [];
    const requestOptions = this.previewIntegrationService.resolveRequestOptions({
      httpHeaders: httpHeaders,
      version: PreviewProviderVersions.V2,
    });

    return this.http.get(previewSettings.apiUrl, requestOptions).pipe(
      map((response: any) => {
        this.cachedStatusInfo = {
          enabled,
          configured: true,
          allowedTypes: response.contentTypes || [],
        };
        return this.cachedStatusInfo;
      }),
      catchError((err) => {
        this.cachedStatusInfo = { enabled, configured: true, hasError: true, allowedTypes: [] };
        return of(this.cachedStatusInfo);
      })
    );
  }

  getCachedPreviewStatusInfo() {
    return this.cachedStatusInfo;
  }
}
