import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { get } from 'lodash';
import {
  CONTENT_QUEUES_ACTION_FAILED,
  ContentQueuesFailedAction,
  CREATE_CONTENT_QUEUE,
  GET_ACTIVE_CONTENT_QUEUE,
  GET_CONTENT_QUEUES,
  GetContentQueuesSuccessAction,
  SetActiveContentQueueAction,
  UPDATE_CONTENT_QUEUE,
} from './content-queues.actions';
import { ContentQueuesService } from '../../api/content-queues/content-queues.service';
import { Router } from '@angular/router';

@Injectable()
export class ContentQueuesEffects {
  getContentQueues$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_CONTENT_QUEUES),
      mergeMap((action: UnsafeAction) => {
        return this.contentQueuesService.getContentQueues(action.payload).pipe(
          map((response) => new GetContentQueuesSuccessAction(response)),
          catchError((error) => of(new ContentQueuesFailedAction({ error, action })))
        );
      })
    )
  );

  getActiveContentQueue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_ACTIVE_CONTENT_QUEUE),
      mergeMap((action: UnsafeAction) => {
        return this.contentQueuesService.getContentQueue(action.payload).pipe(
          map((response) => new SetActiveContentQueueAction(response)),
          catchError((error) => {
            this.router.navigate(['dashboard/content-queues']);
            if (error?.status === 404) {
              this.snackBar.open($localize`Invalid Content Queue Id`, $localize`Close`, {
                duration: 3000,
              });
              return of(new SetActiveContentQueueAction(null));
            }
            return of(new ContentQueuesFailedAction({ error, action }));
          })
        );
      })
    )
  );

  createContentQueue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_CONTENT_QUEUE),
      mergeMap((action: UnsafeAction) => {
        return this.contentQueuesService.createContentQueue(action.payload).pipe(
          tap((response) => {
            this.router.navigate(['dashboard/content-queues', response?.id]);
            this.snackBar.open($localize`Content queue created successfully.`, $localize`Close`, {
              duration: 3000,
            });
          }),
          map((response) => new SetActiveContentQueueAction(response)),
          catchError((error) => of(new ContentQueuesFailedAction({ error, action })))
        );
      })
    )
  );

  updateContentQueue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_CONTENT_QUEUE),
      mergeMap((action: UnsafeAction) => {
        return this.contentQueuesService.updateContentQueue(action.payload).pipe(
          map((response) => new SetActiveContentQueueAction(response)),
          tap(() =>
            this.snackBar.open($localize`Content queue updated successfully.`, $localize`Close`, {
              duration: 3000,
            })
          ),
          catchError((error) => of(new ContentQueuesFailedAction({ error, action })))
        );
      })
    )
  );

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CONTENT_QUEUES_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private contentQueuesService: ContentQueuesService,
    private router: Router
  ) {}
}
