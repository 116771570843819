import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { AccountSettingsService } from '../../../core/api/account-settings/accounts-settings.service';
import { ChipService } from '../../../core/api/chip/chip.service';
import { setTaxonomiesOrder } from '../../../core/api/taxonomies/taxonomies.service';
import { BidiService } from '../../../core/i18n/bidi.service';
import { getTaxonomies } from '../../../core/store';
import { AppState } from '../../../core/store/app-reducer';


@Component({
  selector: 'gd-taxonomies-list',
  templateUrl: './taxonomies-list.component.html',
  styleUrls: ['./taxonomies-list.component.scss'],
})
export class TaxonomiesListComponent implements OnInit, OnDestroy {
  @Input() taxonomies;
  @Input() primaryTaxonomyId;
  @Input() width: string = '390px';

  showTaxonomyPath: boolean = this.accountSettingsService.getShowTaxonomyPathFlag();
  taxonomyDisplayData;
  componentSub: Subscription = new Subscription();
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private chipService: ChipService,
    private store: Store<AppState>,
    private accountSettingsService: AccountSettingsService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.componentSub.add(combineLatest([this.store.select(getTaxonomies)]).subscribe(([taxonomyMap]) => {
      const taxonomies = setTaxonomiesOrder(
        this.taxonomies,
        taxonomyMap,
        this.primaryTaxonomyId,
        this.showTaxonomyPath
      );

      this.taxonomyDisplayData = this.chipService.getTaxonomyDisplayData(taxonomies);
    }));
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }
}
