import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, isAnyStateSliceLoading } from '../../core/store/app-reducer';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BidiService } from '../../core/i18n/bidi.service';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { Permissions } from '../../core/store/auth/permissions';

@Component({
  selector: 'gd-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements AfterViewInit, OnDestroy {
  @Input() sidenav: any;
  isFullscreen = false;

  @Input() sectionHasFloatingTabHeader = false;

  hasReadCQPermission = this.permissionService.hasPermission(Permissions.GC_CONTENT_QUEUE_READ);

  showBreadcrumbs = true;
  showFavorites = true;
  showContentQueues = false;

  loadingBarActive = true;
  activeSection = '';
  componentSub: Subscription = new Subscription();
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private bidiService: BidiService,
    private permissionService: PermissionService,
  ) {
    const routerSub = this.router.events
      .pipe(filter(navigationEnd => navigationEnd instanceof NavigationEnd))
      .subscribe((navigationEnd: NavigationEnd) => {
        this.activeSection = navigationEnd.url.startsWith('/media/files') ? 'files' : '';
        this.showContentQueues =
        (navigationEnd.url.startsWith('/dashboard') || navigationEnd.url === '/') &&
        this.hasReadCQPermission
          ? true
          : false;
      });
    this.componentSub.add(routerSub);
  }

  ngAfterViewInit() {
    this.store.select(isAnyStateSliceLoading).subscribe(anyStateLoading => {
      setTimeout(() => {
        this.loadingBarActive = anyStateLoading;
      }, 200);
    });
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }

}
