export interface ReferencedContentRepository {
  items: { [key: string]: any };
}

export enum ResourceType {
  Article = 'article',
  Image = 'image',
  ArticleType = 'article-type',
  CollectionType = 'collection-type',
  ContentTag = 'content-tag',
  HTMLSnippet = 'html-snippet',
  ContentLocale = 'content-locale',
  File = 'file',
  Menu = 'menu',
  Taxonomy = 'taxonomy',
  Collection = 'collection',
  LiveReport = 'live-report',
  Author = 'author',
  Gallery = 'gallery',
  CFG = 'custom-field-group',
  Widget = 'widget',
  ContentQueue = 'content-queue'
}

export enum FieldType {
  Article = 'articles',
  Collection = 'collections',
  Gallery = 'galleries',
  LiveReport = 'liveReports',
  Image = 'images',
  ArticleType = 'articleTypes',
  CollectionType = 'collectionTypes',
  ContentTag = 'contentTags',
  HTMLSnippet = 'htmlSnippets',
  ContentLocale = 'contentLocales',
  Author = 'authors',
  File = 'files',
  Menu = 'menus',
  Taxonomy = 'taxonomies',
  CFG = 'customFieldGroups',
  CheckboxGroup = 'checkbox',
  Toggle = 'toggle',
  RadioGroup = 'radio-button',
  Colour = 'colour-picker',
  Date = 'date',
  DateTime = 'datetime-local',
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Text = 'text',
  TextArea = 'textarea',
  ValueList = 'valuelist',
  Snippet = 'snippet',
  Search = 'search',
  CDAutocomplete = 'autocomplete',
  CDSelect = 'select',
  FormFragment = 'group',
  ExternalData = 'external-data',
  TextEditor = 'texteditor',
}

