import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { ContentQueuesState } from './content-queues.model';
import {
  CONTENT_QUEUES_ACTION_FAILED,
  CREATE_CONTENT_QUEUE,
  GET_ACTIVE_CONTENT_QUEUE,
  GET_CONTENT_QUEUES,
  GET_CONTENT_QUEUES_SUCCESS,
  SET_ACTIVE_CONTENT_QUEUE,
  SET_CONTENT_QUEUES_LOADING_FLAG,
  SET_CONTENT_QUEUES_PAGE_VIEW_OPTIONS,
  UPDATE_CONTENT_QUEUE,
} from './content-queues.actions';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';

const initialState: ContentQueuesState = {
  loaded: false,
  loading: false,
  error: null,
  items: [],
  activeContentQueue: null,
  pageView: {
    page: 0,
    size: defaultPageSize,
    sizeOptions: defaultPageSizeOptions,
  },
};

export function contentQueuesReducer(
  state: ContentQueuesState = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case GET_CONTENT_QUEUES: {
      return {
        ...state,
        items: [],
        loading: true,
        loaded: false,
      };
    }

    case GET_ACTIVE_CONTENT_QUEUE: {
      return {
        ...state,
        loading: true,
        activeContentQueue: null,
      };
    }
    case CREATE_CONTENT_QUEUE:
    case UPDATE_CONTENT_QUEUE: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_ACTIVE_CONTENT_QUEUE: {
      return {
        ...state,
        loading: false,
        activeContentQueue: action.payload,
      };
    }

    case GET_CONTENT_QUEUES_SUCCESS: {
      const items = action.payload.data;
      const pageView = action.payload.meta.page;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        items,
        pageView: {
          ...state.pageView,
          page: pageView.number,
          size: pageView.size,
          total: pageView.total,
        },
      };
    }

    case SET_CONTENT_QUEUES_LOADING_FLAG: {
      return { ...state, loading: action.payload };
    }

    case CONTENT_QUEUES_ACTION_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    case SET_CONTENT_QUEUES_PAGE_VIEW_OPTIONS: {
      const pW = action.payload;
      const page = pW.hasOwnProperty('page') ? pW.page : state.pageView.page;
      const size = pW.hasOwnProperty('size') ? pW.size : state.pageView.size;
      return {
        ...state,
        pageView: {  ...state.pageView,  page, size }
      };
    }

    default: {
      return state;
    }
  }
}

export const getContentQueuesState = (state) => state.contentQueues;
export const getContentQueues = createSelector(getContentQueuesState, (state) => state.items);
export const getContentQueue = (id: string) =>
  createSelector(getContentQueuesState, (state) =>
    state.items.find((contentQueue) => contentQueue.id == id)
  );
export const getActiveContentQueue = createSelector(
  getContentQueuesState,
  (state) => state.activeContentQueue
);
export const getContentQueuesPageView = createSelector(
  getContentQueuesState,
  (state) => state.pageView
);
