import { UntypedFormControl, UntypedFormArray, AbstractControl, Validators } from '@angular/forms';
import { Component, Input, OnChanges, ViewEncapsulation, SimpleChanges, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { WidgetTypeFieldConfiguration } from '../../../core/store/widget-types/widget-types.model';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment-timezone';
import { AccountSettingsService } from '../../../core/api/account-settings/accounts-settings.service';
import { ENTER } from '@angular/cdk/keycodes';
import { hasRequiredValidation } from './has-required-validation/has-required-validation';
import { typecastAbstractToFormControl, typecastAbstractToFormGroup } from '../../../shared/shared-functions';
import { debounceTime, distinctUntilChanged, startWith, filter, Subscription } from 'rxjs';
import { isEqual, isNumber } from 'lodash';
import { AutocompleteFieldConfigurationComponent } from './autocomplete-field-configuration/autocomplete-field-configuration.component';
import { SelectFieldConfigurationComponent } from './select-field-configuration/select-field-configuration.component';
import { BidiService } from '../../../core/i18n/bidi.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { generateHelpTooltip } from './info-tooltip.text';

const CUSTOM_FORMAT = {
  parse: { dateInput: 'DD-MMM-YYYY' },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

/**
 * Field builder component is used to construct custom form fields based on the
 * GPP field configurations. Plain inputs of various types, CMS data selection fields,
 * as well as fields that can be configured to fetch external API data can all be built
 * by this component.
 *
 * This component does *NOT* build configurations, it is a consumer of GPP custom field
 * configurations, and it uses them to instantiate concrete form fields.
 */
@Component({
  selector: 'gd-field-builder',
  templateUrl: './field-builder.component.html',
  styleUrls: ['./field-builder.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMAT }],
  encapsulation: ViewEncapsulation.None,
})
export class FieldBuilderComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * This is the GPP field configuration object defining what the field is
   */
  @Input() fieldConfig: WidgetTypeFieldConfiguration;
  /**
   * This is the Angular form model control to which the value will be bound
   */
  @Input() fieldControl: AbstractControl;
  @Input() width = 70;
  @Input() hasActionPermission = true;
  @Input() isEditingMode = false;
  @Input() isContentPublished = true;
  @Input() customDataLabel = '';
  @Input() setFocus = false;
  @Input() isAllowedTaxonomiesField = false;
  @Input() usage = null;
  @Input() activeLocale = null;
  @Input() readOnlyMode = false;

  // these references are used to loadt up the selection data
  @ViewChild(AutocompleteFieldConfigurationComponent) autocompleteFieldInstance: AutocompleteFieldConfigurationComponent;
  @ViewChild(SelectFieldConfigurationComponent) selectFieldInstance: SelectFieldConfigurationComponent;

  /**
   * This form control is used internally for filtering in e.g. autocomplete custom fields.
   * The autocomplete input value is not the same as the actual value for that field
   * which will be saved for the form - the actual value is bound to `fieldControl`
   */
  filterField: UntypedFormControl = new UntypedFormControl();
  numericInputControl: UntypedFormControl = new UntypedFormControl();
  isFormControlRequired;

  /**
   * `sourceVal` is used to store info on which data source, if any, will be used for
   * populating the field. Not relevant for `No Data` fields. Example:
   * ```
   * {
   *   "type": "CMS Data",
   *   "value": "articles",
   *   "allowedTypes": [10]
   * }
   * ```
   */
  sourceVal = null;
  /**
   * This is the same as `sourceVal.value`, check comment on `sourceVal`.
   */
  cmsDataSourceType;
  /**
   * Image and galleries count as media widgets - fields configured for selecting
   * those two are handled by a separate component.
   */
  isMediaWidget;
  validators: any = {};

  ready = true;
  valueNullifySubscription = new Subscription();
  numericInputSubscription = new Subscription();
  dateControl: UntypedFormControl = new UntypedFormControl();
  timeControl: UntypedFormControl = new UntypedFormControl();
  dateFormat = 'DD-MMM-YYYY';
  moment = moment;
  timezone = this.accountSettingsService.getTimezone();
  separatorKeysCodes = [ ENTER ];

  infoTooltip = '';

  // buttons for text editor custom field
  textEditorButtons = [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'fontSize',
    'subscript',
    'superscript',
    'formatUL',
    'formatOL',
    'color',
    '|',
    'paragraphFormat',
    '|',
    'insertLink',
    'specialCharacters',
    'insertHR',
    '|',
    'clearFormatting',
    'clearTextEditor',
    '|',
    'undo',
    'redo',
    '|',
  ];

  typecastAbstractToFormGroup = typecastAbstractToFormGroup;
  typecastAbstractToFormControl = typecastAbstractToFormControl;

  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  readOnlyTooltip = $localize`Read-only`;

  constructor(
    private accountSettingsService: AccountSettingsService,
    private bidiService: BidiService,
    ) { }


  ngOnInit() {
    const isToggleInput = this.fieldConfig.inputType === 'toggle';

    if (this.fieldControl && isToggleInput) {
      if (this.fieldControl.value === null) {
        this.fieldControl.setValue(false);
        this.fieldControl.removeValidators(Validators.required);
        this.fieldControl.setErrors(null);
        this.fieldControl.updateValueAndValidity();
      }
    }
  }
  // if the input configuration changes, do reinitialize field to ensure it works as expected
  // also, we're using this instead of ngOnIt, as ngOnChanges is called on init

  ngOnChanges(changes: SimpleChanges) {


    // don't reinitialize the field if only the label changes
    if(changes.customDataLabel && Object.keys(changes).length === 1) {
      return;
    }

    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);

    this.ready = false;
    this.initializeField();
    // TODO: investigate and remove the timeout here
    setTimeout(() => {
      this.ready = true;
    }, 0);
  }

  ngOnDestroy(): void {
    this.valueNullifySubscription.unsubscribe();
    this.numericInputSubscription.unsubscribe();
  }

  initializeField() {
    nullifyEmptyValues(this.fieldControl, this.fieldConfig);

    const isNumericInput = this.fieldConfig.inputType === 'number';

    if (this.fieldControl && isNumericInput) {
      this.numericInputControl.setValue(this.fieldControl.value);
      this.valueNullifySubscription.unsubscribe();
      this.numericInputSubscription = this.numericInputControl.valueChanges
        .pipe(
          startWith(this.numericInputControl.value),
          debounceTime(200),
          distinctUntilChanged((oldValue, newValue) => isEqual(oldValue, newValue))
        )
        .subscribe((val) => {
          const value = isNumber(parseInt(val, 10)) && val !== '' ? val : null;
          this.fieldControl.setValue(value);
          if (!this.numericInputControl?.pristine) {
            this.fieldControl.markAsDirty();
          }
        });
    }

    // handle date-time input type
    const isDateTimeInputField = this.fieldConfig.fieldType === 'input' && this.fieldConfig.inputType === 'datetime-local';
    if (isDateTimeInputField) {
      this.setEnvForDateTimeInputField();
      return;
    }

    // handle date input type, handled slightly different than above
    const isDateInputField = this.fieldConfig.fieldType === 'input' && this.fieldConfig.inputType === 'date';
    if(isDateInputField) {
      this.setEnvForDateInputField();
      return;
    }

    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);

    // set validators object needed for error messages
    this.fieldConfig.validators.forEach(({ validator, parameter }) => this.validators[validator] = parameter);

    // resolve properties related to field data source configuration
    this.sourceVal = this.fieldConfig && this.fieldConfig['dataSource'] || null;
    this.cmsDataSourceType = this.sourceVal && this.sourceVal.value;
    // TODO change this to fieldType check (should be 'mediaSelect')
    this.isMediaWidget = ['images', 'galleries', 'files'].includes(this.cmsDataSourceType);

    // disable control if the user only has view permissions
    if (this.hasActionPermission) {
      this.filterField.enable();
      this.fieldControl.enable();
    } else {
      this.filterField.disable();
      this.fieldControl.disable();
    }

    // ensure we always have the empty strings and arrays as proper null values
    this.valueNullifySubscription.unsubscribe();
    this.valueNullifySubscription = this.fieldControl.valueChanges
      .pipe(
        startWith(this.fieldControl.value),
        debounceTime(200),
        distinctUntilChanged((oldValue, newValue) => isEqual(oldValue, newValue))
      )
      .subscribe(() => nullifyEmptyValues(this.fieldControl, this.fieldConfig));
  }
  getFormControl() {
    if (this.fieldConfig?.inputType === 'number') {
      return typecastAbstractToFormControl(this.numericInputControl);
    }
    return typecastAbstractToFormControl(this.fieldControl);
  }
  getFormGroupData(formGroup) {
    // used for text editor
    this.fieldControl.setValue(formGroup);
    this.fieldControl.markAsDirty();
  }

  setEnvForDateInputField() {
    const initialDate = this.fieldControl.value;
    if(!initialDate) {
      return;
    }

    let [date, time] = initialDate.split('T');
    const hours = +time.slice(0, 2);

    // if hours is larger than 12, round the date up to account for previous values
    if(hours >= 12) {
      // the logic here is that for time zones with positive offset (like GTM+2) the
      // date would be reduced by one day when going to Greenwich time. E.g. when it is
      // midnight in GMT+2, it is only 22:00h at Greenwich, and the date hasn't moved
      // forward yet. Since we recorded date as midnight in the users time zone, we correct
      // all the dates recorded with positive GMT by discarding hours and moving forward one day.
      // West of Greenwich we need no correction since the date would already have moved
      // to the correct day.
      const dateCorrected = moment(date).add(1, 'days');
      date = dateCorrected.format('YYYY-MM-DD');
      console.log(
        `Correcting saved custom field date input to round value.\n`
        + `| Field key: [${this.fieldConfig.key}].\n`
        + `| Old value: [${this.fieldControl.value}]\n`
        + `| New value: [${date}T00:00:00.000Z].`
      );
    }

    this.dateControl.patchValue(date);
  }

  updateDateInputField() {
    const isDateValid = !!this.dateControl.value;
    this.fieldControl.markAsDirty();
    this.dateControl.markAsDirty();

    if (!isDateValid) {
      this.dateControl.updateValueAndValidity();
      return this.fieldControl.setValue(null);
    }

    // moment object or string?
    const date = typeof (this.dateControl.value) === 'object'
      ? this.dateControl.value.format('YYYY-MM-DD')
      : this.dateControl.value;
    this.fieldControl.setValue(date + 'T00:00:00.000Z');
  }

  setEnvForDateTimeInputField() {
    if (!this.fieldControl.value) {
      return this.timeControl.disable();
    }
    const data = this.fieldControl.value.split('T');
    this.dateControl.patchValue(data[0]);
    this.timeControl.patchValue(data[1]);
  }


  updateDatetimeInputField() {
    const isDateValid = !!this.dateControl.value;
    this.fieldControl.markAsDirty();
    this.timeControl.markAsDirty();
    this.dateControl.markAsDirty();

    if (!isDateValid) {
      this.timeControl.setValue('');
      this.timeControl.disable();
      this.dateControl.updateValueAndValidity();
      return this.fieldControl.setValue(null);
    }

    const date = typeof (this.dateControl.value) === 'object' ? this.dateControl.value.format('YYYY-MM-DD') : this.dateControl.value;
    const time = this.timeControl.value || moment().tz(this.timezone).format('HH:mm');
    this.timeControl.setValue(time);
    this.timeControl.enable();
    this.fieldControl.setValue(date + 'T' + time);
  }

  /**
   * This method allows the parent component of field builder to fetch
   * selection data, to allow for improved UI features. It should not be used
   * to affect actual saved data for the form
   *
   * @returns single or array of values selected for this custom field
   */
  public getSelectedValue() {
    if(this.fieldConfig.fieldType === 'autocomplete') {
      if(this.fieldConfig.inputType === 'single') {
        return this.autocompleteFieldInstance?.selectedItem;
      }
      if(this.fieldConfig.inputType === 'multiple') {
        return this.autocompleteFieldInstance?.multiSelectFieldChips || [];
      }
    }
    if(this.fieldConfig.fieldType === 'select') {
      if(this.fieldConfig.inputType === 'single') {
        return this.selectFieldInstance?.selectedItem;
      }
      if(this.fieldConfig.inputType === 'multiple') {
        // TODO implement seelcted value retieval for multiselect
      }
    }
    return this.fieldControl.value;
  }

}

/**
 * this bit of code ensures that proper nulls are sent instead of
 * wrong type empty/falsy values for empty fields (e.g. "" is not a valid empty value for an array type)
 * how it works: on field value change for empty/falsy values set the value to null
 */
export function nullifyEmptyValues(fieldControl: AbstractControl, fieldConfig: WidgetTypeFieldConfiguration) {
  if (!fieldControl || fieldConfig.fieldType === 'texteditor') {
    return;
  }

  const isEmptyObject = checkIfValueIsEmptyObject(fieldControl.value);
  const isEmptyString = fieldControl.value === '';
  if (isEmptyObject || isEmptyString) {
    // in the case of the form array we can't just set the value to null (it would error out),
    // but we can reset the array. note that we're not clearing the array, as it is already empty
    // and in order to not affect parent form, we're limiting reset to this control only.
    if (fieldControl instanceof UntypedFormArray) {
      (fieldControl as UntypedFormArray).reset([], { onlySelf: true });
      return;
    }

    // TODO re-evaluate this, provide an option to send proper empty values
    fieldControl.setValue(null);
  }
}

function checkIfValueIsEmptyObject(value) {
  return typeof (value) === 'object' && value && value.length === 0;
}
