import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
  GET_WORKFLOWS_SUCCESS, GET_ACTIVE_WORKFLOW, GET_ACTIVE_WORKFLOWS_SUCCES, GET_WORKFLOWS,
  SET_ACTIVE_WORKFLOW, GET_ACTIVE_WORKFLOWS_ERROR, SET_ACTIVE_WORKFLOWS_ERROR,
  GET_WORKFLOWS_ERROR,
  SET_ACTIVE_WORKFLOWS_SUCCES
} from './workflow.actions';
import { AppState } from '../app-reducer';
import { Workflow, RawWorkflow, WorkflowStatus } from './workflow.model';

export interface StatusWorkflowsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  workflows: { [key: string]: RawWorkflow };
  activeWorkflowId: number;
}

const initialState: StatusWorkflowsState = {
  loaded: false,
  loading: false,
  error: null,
  workflows: {},
  activeWorkflowId: null
};

export function workflowsReducer(state: StatusWorkflowsState = initialState, action: UnsafeAction) {

  switch (action.type) {


    case GET_WORKFLOWS:
    case SET_ACTIVE_WORKFLOW:
    case GET_ACTIVE_WORKFLOW: {
      return { ...state, loading: true };
    }

    case GET_ACTIVE_WORKFLOWS_SUCCES: {
      const activeWorkflow = action.payload;
      const newWorkflowsMap = {
        ...state.workflows,
        [activeWorkflow.id]: activeWorkflow
      };
      return {
        loaded: true,
        loading: false,
        error: null,
        workflows: newWorkflowsMap,
        activeWorkflowId: activeWorkflow.id
      };
    }

    case SET_ACTIVE_WORKFLOWS_SUCCES: {
      const activeWorkflowId = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        activeWorkflowId: activeWorkflowId
      };
    }

    case GET_WORKFLOWS_SUCCESS: {
      const workflows: any[] = action.payload;
      const workflowsMap = workflows.reduce((acc, w) => {
        acc[w.id] = w;
        return acc;
      }, {});

      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        workflows: workflowsMap
      };
    }


    case SET_ACTIVE_WORKFLOWS_ERROR:
    case GET_WORKFLOWS_ERROR:
    case GET_ACTIVE_WORKFLOWS_ERROR: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }

}

export const getWorkflowsState = (state: AppState): StatusWorkflowsState => state.workflows;
export const getActiveWorkflowId = (state: AppState): number => state.workflows.activeWorkflowId;
export const getWorkflowsLoaded = (state) => state.workflows.loaded;
export const getWorkflowsLoading = (state) => state.workflows.loading;

export const getActiveWorkflowPublishableStatusId = createSelector(
  getWorkflowsState,
  (state: StatusWorkflowsState) => {
    if (!state.activeWorkflowId) {
      return null;
    }
    const activeWorkFlowId = state.activeWorkflowId;
    const workflow = state.workflows[activeWorkFlowId];
    const activeStateGraphArray = Object.values(workflow.statesGraph);
    const statusPublishable = activeStateGraphArray.find(stateElement => stateElement.publishable);
    return statusPublishable.id;
  }
);

export const getWorkflowsMap = createSelector(
  getWorkflowsState,
  (state: StatusWorkflowsState) => state.workflows || {}
);

export const getWorkflowsArray = createSelector(
  getWorkflowsMap,
  (workflows: any) => Object.values(workflows)
);


export const getWorkflowsListData = createSelector(
  getWorkflowsArray,
  getActiveWorkflowId,
  (workflows: Workflow[], activeWorkflowId: number) => {
    return workflows.map(w => ({
      id: w.id,
      accountId: w.accountId,
      name: w.name,
      isActive: activeWorkflowId === w.id
    }));
  }
);

export const getActiveWorkflow = createSelector(
  getWorkflowsMap,
  getActiveWorkflowId,
  (workflows, activeWorkflowId) => {
    if (!workflows || !activeWorkflowId) {
      return null;
    }
    return workflows[activeWorkflowId];
  }
);

export const getActiveWorkflowForArticles = createSelector(
  getActiveWorkflow,
  (activeWorkflow: RawWorkflow): Workflow => {
    if (!activeWorkflow) {
      return null;
    }
    const startingState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.initial);
    const publishedState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.published);
    const publishableState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.publishable);
    const scheduledState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.scheduled);
    const unpublishedState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.unpublished);

    return {
      ...activeWorkflow,
      startingState,
      publishedState,
      publishableState,
      unpublishedState,
      scheduledState
    };

  }
);

export const getActiveWorkflowForCollections = createSelector(
  getActiveWorkflow,
  (activeWorkflow: RawWorkflow): Workflow => {
    if (!activeWorkflow) {
      return null;
    }
    const startingState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.initial);
    const publishedState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.published);
    const publishableState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.publishable);
    const scheduledState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.scheduled);
    const unpublishedState = Object.values(activeWorkflow.statesGraph).find((s: WorkflowStatus) => s.unpublished);

    return {
      ...activeWorkflow,
      startingState,
      publishedState,
      publishableState,
      unpublishedState,
      scheduledState
    };
  }
);

export const getActiveWorkflowGraph = createSelector(
  getWorkflowsMap,
  getActiveWorkflowId,
  (workflows, activeWorkflowId) => {
    if (!workflows || !activeWorkflowId) {
      return null;
    }
    const statuses = workflows[activeWorkflowId].statesGraph;
    const statusFormat = Object.keys(statuses).map(key => statuses[key]);
    return statusFormat;
  }
);
