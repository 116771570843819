import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from './permissions.service';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(private permissionService: PermissionService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    const dashboardUrl = '/dashboard';
    // dashboard can always be navigated to as it doesn't require any permissions if not on content queues subsection
    if (url.startsWith(dashboardUrl) && !url.includes('/content-queues')) {
      return true;
    }

    // determine if the user has relevant permissions for the section
    const sectionPathFragment = this.permissionService.resolveSectionPathFragment(url);
    const section = this.permissionService.resolveSectionFromPathFragment(sectionPathFragment);
    if (this.permissionService.hasSectionPermission(section)) {
      return true;
    }

    // if user doesn't have required section permission, redirect to dashboard
    this.router.navigate([dashboardUrl]);
    return false;
  }
}
