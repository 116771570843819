import { snakeCase } from 'lodash-es';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-reducer';
import { Observable } from '../../../../../node_modules/rxjs';
import {
  selectUserFieldPermissions,
  selectUserSectionPermission,
} from '../../store/auth/auth.reducer';

@Injectable()
export class PermissionService {
  permissionsMap: any;
  permissions$: Observable<any>;
  permissionSection;
  sectionName: any = [
    '3RD_PARTY_WIDGET',
    'ACCOUNT',
    'ARTICLE',
    'ARTICLE_TYPE',
    'AUTHOR',
    'COLLECTION',
    'COLLECTION_TYPE',
    'GM_CONFIGURE',
    'CONTENT_PANEL',
    'CONTENT_TAG',
    'FIELD_GROUP',
    'FILE',
    'GALLERY',
    'HTML_SNIPPET',
    'IMAGE',
    'MENU',
    'PAGE',
    'REDIRECT',
    'ROLE',
    'ROUTE',
    'SSO',
    'SYSTEM_WIDGET',
    'TAXONOMY',
    'TAXONOMY_CONFIGURATION',
    'TEMPLATE',
    'USER',
    'WIDGETTYPE',
    'ACCESS_BUNDLE',
    'GV_CONFIGURE',
    'LIVE_REPORT',
     'SUBSCRIBER',
    'CONTENT_LOCALE',
    'SYSTEM_NOTIFICATION',
    'LIVE_REPORT_TYPE',
    'CONTENT_QUEUE'
  ];

  fieldPermissions$: Observable<any>;
  fieldPermissionsMap: any;

  constructor(private store: Store<AppState>) {
    this.permissions$ = this.store.select(selectUserSectionPermission);
    this.permissions$.subscribe((permissionsValue) => {
      this.permissionsMap = permissionsValue;
      this.permissionSection = {};
      this.makePermissionSection();
    });

    this.fieldPermissions$ = this.store.select(selectUserFieldPermissions);
    this.fieldPermissions$.subscribe(
      (fieldPermissions) => (this.fieldPermissionsMap = fieldPermissions)
    );
  }

  makePermissionSection() {
    const arrayPermission = Object.values(this.permissionsMap);
    this.sectionName.forEach((sectionName: string) => {
      const sectionPermissionFlag = arrayPermission.some((permValue) => {
        return this.compareSection(permValue, sectionName);
      });
      if (sectionPermissionFlag) {
        this.permissionSection[sectionName] = sectionName;
      }
    });
  }

  compareSection(permission, sectionName: string) {
    return permission ? permission.includes(sectionName) : false;
  }

  getUserPermision() {
    return this.permissions$;
  }

  hasAnySiteBuilderPermissions() {
    const hasAnySiteBuilderPermissions =
      this.permissionsMap['GC_3RD_PARTY_WIDGET_READ'] ||
      this.permissionsMap['GC_ARTICLE_TYPE_READ'] ||
      this.permissionsMap['GC_COLLECTION_TYPE_READ'] ||
      this.permissionsMap['GC_CONTENT_PANEL_READ'] ||
      this.permissionsMap['GC_CONTENT_TAG_READ'] ||
      this.permissionsMap['GC_FIELD_GROUP_READ'] ||
      this.permissionsMap['GC_PAGE_READ'] ||
      this.permissionsMap['GC_REDIRECT_READ'] ||
      this.permissionsMap['GC_ROUTE_READ'] ||
      this.permissionsMap['GC_SYSTEM_WIDGET_READ'] ||
      this.permissionsMap['GC_TAXONOMY_CONFIGURATION_READ'] ||
      this.permissionsMap['GC_TEMPLATE_READ'] ||
      this.permissionsMap['GC_CONTENT_LOCALE_READ'] ||
      this.permissionsMap['GC_LIVE_REPORT_TYPE_READ'];
    return hasAnySiteBuilderPermissions;
  }

  hasAnyMediaPermissions() {
    const hasAnyMediaPermissions =
      this.permissionsMap['GM_CONFIGURE_READ'] ||
      this.permissionsMap['GM_FILE_READ'] ||
      this.permissionsMap['GM_GALLERY_READ'] ||
      this.permissionsMap['GM_IMAGE_READ'] ||
      this.permissionsMap['GM_TAG_READ'];
    return hasAnyMediaPermissions;
  }

  hasAnyVerifyPermissions() {
    const hasAnyVerifyPermissions = this.permissionsMap['GV_ACCESS_BUNDLE_READ'] || this.permissionsMap['GV_CONFIGURE_READ'];
    return hasAnyVerifyPermissions;
  }

  hasAnyUserPermissions() {
    const hasAnyUserPermissions =
      this.permissionsMap['GU_ACCOUNT_READ'] ||
      this.permissionsMap['GC_AUTHOR_READ'] ||
      this.permissionsMap['GU_ROLE_READ'] ||
      this.permissionsMap['GU_SSO_READ'] ||
      this.permissionsMap['GU_USER_READ']
    return hasAnyUserPermissions;
  }

  hasAnyWidgetTypePermissions() {
    const hasAnyWidgetTypePermissions =
      this.permissionsMap['GC_HTML_SNIPPET_READ'] || this.permissionsMap['GC_MENU_READ'];
    return hasAnyWidgetTypePermissions;
  }


  hasAnyTransmitPermissions() {
    const hasAnyTransmitPermissions = this.permissionsMap['GT_SUBSCRIBER_READ'];
    return hasAnyTransmitPermissions;
  }

   //SN killSwitch hasAnyNotificationPermissions() {
   //SN killSwitch   const hasAnyNotificationPermissions = this.permissionsMap['GC_SYSTEM_NOTIFICATION_CREATE'];
   //SN killSwitch   return hasAnyNotificationPermissions;
   //SN killSwitch }

  hasPermission(permissionValue: string) {
    const permission = this.permissionsMap[permissionValue];
    if (permission === undefined) {
      return false;
    }
    return true;
  }

  hasSectionPermission(permissionValue: string) {
    return this.permissionSection[permissionValue];
  }

  hasAnyRoutingPermissions() {
    const hasAnyRoutingPermissions =
      this.permissionsMap['GC_ROUTE_READ'] || this.permissionsMap['GC_REDIRECT_READ'];
    return hasAnyRoutingPermissions;
  }

  getUserFieldPermission() {
    return this.fieldPermissions$;
  }

  hasFieldPermission(fieldPermissionValue: string) {
    const permission = this.fieldPermissionsMap[fieldPermissionValue];
    if (permission === undefined) {
      return false;
    }
    return true;
  }

  /**
   * This function maps the most relevant section path fragment to section slug
   *
   * IMPORTANT: new sections will need to be added to this map IF:
   * 1. The plural form is not singular form + 's', e.g. taxonomy -> taxonomies
   * 2. The section slug is not a singular of the section path fragment,
   *    e.g. settings -> config
   * 3. Section path fragment and section slug are the same, but they end with letter `s`
   *
   * In these cases DO NOT ADD the entry to the map:
   * 1. Section path fragment and section slug are the same and they don't end with `s`,
   *    e.g sso -> sso
   * 2. Section path is a simple plural of section slug, e.g files -> file,
   */
  resolveSectionFromPathFragment(sectionPathFragment = '') {
    const pathFragmentMapped2SectionSlug = {
      taxonomies: 'TAXONOMY',
      'page-routes': 'ROUTE',
      galleries: 'GALLERY',
      'media-config': 'GM_CONFIGURE',
      'verify-config': 'GV_CONFIGURE',
      'third-party-widgets': '3RD_PARTY_WIDGET',
      'live-reporting': 'LIVE_REPORT',
      'custom-field-groups': 'FIELD_GROUP',
    }[sectionPathFragment];

    // special mapping applied as per above
    if (pathFragmentMapped2SectionSlug) {
      return pathFragmentMapped2SectionSlug;
    }

    // convert from simple plural into simple singular
    const isSimplePluralForm = !!sectionPathFragment.match(/s$/);
    if (isSimplePluralForm) {
      const singularForm = sectionPathFragment.slice(0, sectionPathFragment.length - 1);
      return snakeCase(singularForm).toUpperCase();
    }

    // as the last option, convert sectionPathFragment directly
    return snakeCase(sectionPathFragment).toUpperCase();
  }

  /**
   * This function extracts the most relevant path fragment for determining
   * which section the user is trying to access via routing
   *
   * IMPORTANT: if a new root level navigable section is added which has
   * items navigable by id, e.g. `/new-section` and `/new-section/151`, then
   * `new-section` needs to be added to the `rootLevelSectionsWithItemPages`
   *
   * @param url - path for which permissions are being resolved
   */
  resolveSectionPathFragment(url) {
    const urlArray = url.match(/[^?]*/)[0].split('/');
    const [parentSectionUrlFragment, childSectionPathFragment] = [urlArray[1], urlArray[2]];

    let sectionPathFragment = childSectionPathFragment || parentSectionUrlFragment;

    // do a correction for following sections, as `childSectionPathFragment` is potentially an item id
    // i.e. in `/articles/157` - childSectionPathFragment would be 157 which isn't a section path fragment
    const rootLevelSectionsWithItemPages = ['articles', 'taxonomies', 'authors', 'collections', 'live-reporting'];
    if (rootLevelSectionsWithItemPages.includes(parentSectionUrlFragment)) {
      sectionPathFragment = parentSectionUrlFragment;
    }

    return sectionPathFragment;
  }
}
