import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { inRange } from 'lodash';

@Component({
  selector: 'gd-right-click-dialog-wrapper',
  templateUrl: './right-click-dialog-wrapper.component.html',
  styleUrls: ['./right-click-dialog-wrapper.component.scss'],
})
export class RightClickDialogWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('document:click', ['$event']) onclick(event) {
    if (this.listenersEnabled) {
      this.handleDialogClose(event);
    }
  }
  @HostListener('document:contextmenu', ['$event']) onrightclick(event) {
    if (this.listenersEnabled) {
      this.handleDialogClose(event);
    }
  }

  listenersEnabled = false;
  dialogPosition;
  contentSize: { width: number; height: number };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    public dialogRef: MatDialogRef<RightClickDialogWrapperComponent>
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.listenersEnabled = true), 200);
  }

  ngAfterViewInit() {
    const contentEl = document.getElementById('rc-dialog-content');
    this.contentSize = { width: contentEl.offsetWidth, height: contentEl.offsetHeight };
    this.resolveDialogPosition();
    window.addEventListener('scroll', this.handleDialogClose, true);
  }

  handleDialogClose = (event) => {
    const shouldClose =
      !inRange(
        event.clientX,
        this.dialogPosition.left,
        this.dialogPosition.left + this.contentSize.width
      ) ||
      !inRange(
        event.clientY,
        this.dialogPosition.top,
        this.dialogPosition.top + this.contentSize.height
      );
    if (shouldClose) {
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

  resolveDialogPosition() {
    let top = +this.dialogInputData.position.top.replace('px', '');
    let left = +this.dialogInputData.position.left.replace('px', '');

    if (top + this.contentSize.height > window.innerHeight) {
      top = top - this.contentSize.height;
    }

    if (left + this.contentSize.width > window.innerWidth) {
      left = left - this.contentSize.width;
    }
    this.dialogPosition = { left, top };
    this.dialogRef.updatePosition({ top: `${top}px`, left: `${left}px` });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.handleDialogClose, true);
  }
}
