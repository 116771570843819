<div class="gd-quick-view"
     *ngIf="quickViewArticle$ && quickViewArticle$.revision && quickViewArticle$.body"
     [dir]="dir$ | async">

  <div class="gd-quick-view-sticky">
    <h2 mat-dialog-title
        class="gd-quick-view__headline">{{quickViewArticle$.revision.headline}}</h2>
    <h5 class="gd-quick-view__catchline"
        *ngIf="quickViewArticle$.revision.catchline">{{quickViewArticle$.revision.catchline}}</h5>
  </div>

  <div class="gd-quick-view-additional-details"
       fxLayout="row"
       fxLayoutAlign="space-between start">

    <div>
      <div class="gd-quick-view-additional-details-item"
           fxLayout="row"
           fxLayoutAlign="start start"
           *ngIf="quickViewArticle$.revision.authors as authors">
        <div class="gd-quick-view-additional-details-label"><ng-container i18n>Author</ng-container>:</div>

        <div class="gd-quick-view-additional-details-authors">
          <span *ngFor="let author of authors let i=index">
            {{author.name}}
            <span *ngIf="(authors.length - 1) !== i">,</span>
          </span>
        </div>

      </div>


      <div class="gd-quick-view-additional-details-item"
           fxLayout="row"
           fxLayoutAlign="start center"
           *ngIf="quickViewArticle$.revision.status as status">
        <div class="gd-quick-view-additional-details-label"><ng-container i18n>Status</ng-container>:</div>

        <div class="status-label-wrapper">
          <div i18n="@@statusList">{status?.name, select, Published {Published} Ready {Ready} Review {Review} Scheduled {Scheduled} Unpublished {Unpublished} Draft {Draft} other { {{status?.name}} } }</div>
          <div class="status-color --select-value"
               [style.backgroundColor]="status.backgroundColor">
            {{status.name.charAt(0)}}
          </div>
        </div>


      </div>

      <div class="gd-quick-view-additional-details-item"
           fxLayout="row"
           fxLayoutAlign="start center"
           *ngIf="quickViewArticle$.revision.status as status">
        <div class="gd-quick-view-additional-details-label">
          <span *ngIf="status.published" i18n>Published time: {{quickViewArticle$.revision.date}}</span>
          <span *ngIf="!status.published" i18n>Edit time: {{quickViewArticle$.revision.date}}</span>
        </div>

      </div>

      <div class="gd-quick-view-additional-details-item"
           fxLayout="row"
           fxLayoutAlign="start start"
           *ngIf="quickViewArticle$.revision.taxonomies || quickViewArticle$.revision.primaryTaxonomyId">
        <div class="gd-quick-view-additional-details-label"><ng-container i18n>Taxonomies</ng-container>:</div>
        <gd-taxonomies-list [taxonomies]="quickViewArticle$.revision.taxonomies"
                            [primaryTaxonomyId]="quickViewArticle$.revision.primaryTaxonomyId"
                            [width]="'500px'"></gd-taxonomies-list>
      </div>
    </div>

    <div class="gd-quick-view-additional-details-item"
         fxLayout="row"
         fxLayoutAlign="start start">
      <div class="gd-promo-image__image-wrapper"
           *ngIf="quickViewArticle$.revision.promoImage as promoImage">
        <img class="promo-image --card"
             [src]="promoImage">
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="gd-quick-view-content fr-wrapper">
    <div class="fr-box fr-basic fr-top">
      <div class="fr-element fr-view"
           [innerHtml]="quickViewArticle$.body">
      </div>
    </div>
  </div>

  <button *ngIf="(loading$ | async) !== true"
          mat-raised-button
          class="gd-quick-view-modal__cancel-btn --margin"
          i18n
          mat-dialog-close>Close</button>
</div>
