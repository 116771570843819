import { Injectable } from '@angular/core';
import { ManageEmbeddedImageVisibilityAction, UpdateReferencedContentAction } from '../../store/article-quick-view/article-quick-view.actions';
import { AbstractReferencedContentService } from './abstract-referenced-content.service';


@Injectable({
  providedIn: 'root',
})
export class ArticleQuickViewContentService extends AbstractReferencedContentService {
  protected EmbeddedImageVisibilityAction = ManageEmbeddedImageVisibilityAction;
  protected UpdateReferencedContentAction = UpdateReferencedContentAction;
}
