import { AdvancedFiltersState, getEmptyContentFilter } from './advanced-filtering-option.model';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  UPDATE_TAXONOMIES_FILTER, UPDATE_BYLINE_FILTER,
  UPDATE_HEADLINE_FILTER, UPDATE_LAST_MODIFIED_BY_FILTER, UPDATE_TYPE_FILTER,
  UPDATE_UPDATED_AT_FILTER, UPDATE_STATUS_FILTER, RESET_ADVANCED_FILTER, UPDATE_HEADLINE_FILTER_ARTICLE_LIST,
  GET_CONTENT_FILTERS, GET_CONTENT_FILTERS_SUCCESS, CONTENT_FILTER_FAILED_ACTION, CREATE_CONTENT_FILTER,
  UPDATE_CONTENT_FILTER, DELETE_CONTENT_FILTER, CREATE_CONTENT_FILTER_SUCCESS, UPDATE_CONTENT_FILTER_SUCCESS,
  DELETE_CONTENT_FILTER_SUCCESS, SET_ACTIVE_CONTENT_FILTER, SET_DEFAULT_CONTENT_FILTER,
  SET_DEFAULT_CONTENT_FILTER_SUCCESS,
  UPDATE_TAXONOMY_INCLUDE_TYPE,
  UPDATE_TAXONOMY_EXCLUDE_TYPE,
  ARTICLE_LIST_RESET_ADVANCED_FILTER,
  CLEAR_DEFAULT_CONTENT_FILTER_SUCCESS,
  UPDATE_HEADLINE_EXCLUDE_FILTER,
  SET_ADVANCED_FILTERING_QUERY,
  CLEAR_LOADED_FILTERS,
  UPDATE_CONTENT_LOCALE_ID_FILTER,
  UPDATE_CONTENT_LOCALE_FILTER,
  UPDATE_COLLECTION_FILTER,
} from './advanced-filtering-option.actions';
import { AppState } from '../app-reducer';
import { createSelector } from '@ngrx/store';

const initialState: AdvancedFiltersState = {
  loaded: false,
  loading: false,
  filters: [],
  error: null,
  queries: {},
  activeFilterQuerry: getEmptyContentFilter()
};

export function advancedFilteringReducer(state: AdvancedFiltersState = initialState, action: UnsafeAction) {

  switch (action.type) {
    case CREATE_CONTENT_FILTER:
    case UPDATE_CONTENT_FILTER:
    case DELETE_CONTENT_FILTER:
    case SET_DEFAULT_CONTENT_FILTER: {
      return { ...state, loading: true };
    }

    case GET_CONTENT_FILTERS: {
      return { ...state, loading: true, loaded: false };
    }

    case GET_CONTENT_FILTERS_SUCCESS: {
      return { ...state, loaded: true, loading: false, filters: action.payload };
    }

    case CREATE_CONTENT_FILTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        filters: [...state.filters, action.payload]
      };
    }

    case UPDATE_CONTENT_FILTER_SUCCESS: {
      const updatedFilter = action.payload;
      const filters = state.filters.filter(cf => cf.id !== updatedFilter.id);
      return {
        ...state,
        loading: false,
        filters: [...filters, updatedFilter]
      };
    }

    case DELETE_CONTENT_FILTER_SUCCESS: {
      const deletedFilterId = action.payload;
      const filters = state.filters.filter(cf => cf.id !== deletedFilterId);
      return {
        ...state,
        loading: false,
        filters
      };
    }

    case SET_ACTIVE_CONTENT_FILTER: {
      return { ...state, activeFilterQuerry: action.payload.activeFilterQuerry };
    }

    case SET_DEFAULT_CONTENT_FILTER_SUCCESS: {
      const defaultFilterId = action.payload;
      const filters = [...state.filters].map(filter => {
        const defaultFilter = filter.id === defaultFilterId;
        return { ...filter, defaultFilter };
      });

      return { ...state, loading: false, filters };
    }

    case CLEAR_DEFAULT_CONTENT_FILTER_SUCCESS: {
      const filters = [...state.filters].map(filter => {
        return { ...filter, defaultFilter: false };
      });
      return { ...state, loading: false, filters };
    }

    case CONTENT_FILTER_FAILED_ACTION: {
      return {
        ...state,
        loading: false,
        filters: [],
        error: action.payload
      };
    }

    case UPDATE_TAXONOMIES_FILTER: {
      const resource: 'taxonomyIds' | 'customFieldTaxonomyIds' = action.payload?.resource || 'taxonomyIds';
      const taxonomyIds = updateResourceFilter(state.activeFilterQuerry, action.payload, resource);

      const queryType: 'include' | 'includeSome' | 'exclude' | 'excludeSome' = action.payload.type;
      // depending on if the AND or OR criteria is active, we reset the other one
      const queryTypeToRest = {
        includeSome: 'include',
        include: 'includeSome',
        exclude: 'excludeSome',
        excludeSome: 'exclude'
      }[queryType];

      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          // for the chosen query type add the new filter values for the resource
          [queryType]: {
            ...state.activeFilterQuerry[queryType],
            [resource]: taxonomyIds
          },
          [queryTypeToRest]: {
            ...state.activeFilterQuerry[queryTypeToRest],
            [resource]: []
          }
        }
      }
    }

    case UPDATE_BYLINE_FILTER: {
      const authorIds = updateResourceFilter(state.activeFilterQuerry, action.payload, 'authorIds');
      if (action.payload.type === 'includeSome') {
        return {
          ...state,
          activeFilterQuerry: {
            ...state.activeFilterQuerry,
            includeSome: {
              ...state.activeFilterQuerry.includeSome,
              authorIds
            }
          }
        };
      }
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          excludeSome: {
            ...state.activeFilterQuerry.excludeSome,
            authorIds
          }
        }
      };
    }

    case SET_ADVANCED_FILTERING_QUERY: {
      const { include = {}, includeSome = {}, exclude = {}, excludeSome = {} } = action.payload;

      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          include: {
            ...state.activeFilterQuerry.include,
            ...include,
          },
          includeSome: {
            ...state.activeFilterQuerry.includeSome,
            ...includeSome,
          },
          exclude: {
            ...state.activeFilterQuerry.exclude,
            ...exclude,
          },
          excludeSome: {
            ...state.activeFilterQuerry.excludeSome,
            ...excludeSome,
          }
        }
      }
    }

    case UPDATE_HEADLINE_FILTER:
    case UPDATE_HEADLINE_FILTER_ARTICLE_LIST: {
      const includeHeadline = !!action.payload ? { like: action.payload } : {};
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          include: {
            ...state.activeFilterQuerry.include,
            headlineOrCatchline: includeHeadline,
          }
        }
      };
    }

    case UPDATE_CONTENT_LOCALE_FILTER:
    case UPDATE_CONTENT_LOCALE_ID_FILTER: {
      const includeContentLocaleId = action.payload;
      // reset locale filter if we get null
      if(!includeContentLocaleId) {
        const includeCopy = {...state.activeFilterQuerry.include};
        delete includeCopy.contentLocaleIds;
        return {
          ...state,
          activeFilterQuerry: {
            ...state.activeFilterQuerry,
            include: includeCopy
          }
        };
      }

      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          include: {
            ...state.activeFilterQuerry.include,
            contentLocaleIds: [includeContentLocaleId],
          }
        }
      };
    }

    case UPDATE_HEADLINE_EXCLUDE_FILTER: {
      const excludeHeadline = !!action.payload ? { like: action.payload } : {};
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          exclude: {
            ...state.activeFilterQuerry.exclude,
            headlineOrCatchline: excludeHeadline,
          }
        }
      };
    }

    case UPDATE_LAST_MODIFIED_BY_FILTER: {
      const updatedBy = updateResourceFilter(state.activeFilterQuerry, action.payload, 'updatedBy');
      if (action.payload.type === 'include') {
        return {
          ...state,
          activeFilterQuerry: {
            ...state.activeFilterQuerry,
            include: {
              ...state.activeFilterQuerry.include,
              updatedBy
            }
          }
        };
      }
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          exclude: {
            ...state.activeFilterQuerry.exclude,
            updatedBy
          }
        }
      };
    }

    case UPDATE_TYPE_FILTER: {
      const typeIds = updateResourceFilter(state.activeFilterQuerry, action.payload, 'typeIds');
      if (action.payload.type === 'include') {
        return {
          ...state,
          activeFilterQuerry: {
            ...state.activeFilterQuerry,
            include: {
              ...state.activeFilterQuerry.include,
              typeIds
            }
          }
        };
      }
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          exclude: {
            ...state.activeFilterQuerry.exclude,
            typeIds
          }
        }
      };
    }

    case UPDATE_UPDATED_AT_FILTER: {
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          include: {
            ...(state.activeFilterQuerry?.include || {}),
            updatesAvailable: action.payload.updatesAvailable || null,
            sysUpdatedAt: action.payload.sysUpdatedAt
          }
        }
      };
    }

    case UPDATE_COLLECTION_FILTER: {
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          include: {
            ...(state.activeFilterQuerry?.include || {}),
            collectionIds: action.payload || [],
          }
        }
      };
    }

    case UPDATE_STATUS_FILTER: {
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          include: {
            ...(state.activeFilterQuerry?.include || {}),
            statusIds: action.payload.includeStatus,
            publicationState: action.payload.publicationState
          },
          exclude: {
            ...state.activeFilterQuerry.exclude,
            statusIds: action.payload.excludeStatus
          }
        }
      };
    }

    case RESET_ADVANCED_FILTER: {
      return {
        ...state,
        activeFilterQuerry: {
          include: {},
          includeSome: {},
          exclude: {},
          excludeSome: {}
        }
      };
    }

    case ARTICLE_LIST_RESET_ADVANCED_FILTER: {
      return {
        ...state,
        activeFilterQuerry: {
          include: {},
          includeSome: {},
          exclude: {},
          excludeSome: {}
        }
      };
    }

    case UPDATE_TAXONOMY_INCLUDE_TYPE: {
      const resource: 'taxonomyIds' | 'customFieldTaxonomyIds' = action.payload.resource;
      const queryType: 'include' | 'includeSome' = action.payload.type;
      // depending on if the AND or OR criteria is active, we reset the other one
      const queryTypeToRest = {
        includeSome: 'include',
        include: 'includeSome'
      }[queryType];

      const existingFilterValues = state.activeFilterQuerry[queryTypeToRest][resource] || [];
      return {
        ...state,
        activeFilterQuerry: {
          ...state.activeFilterQuerry,
          [queryTypeToRest]: {
            ...state.activeFilterQuerry[queryTypeToRest],
            [resource]: []
          },
          [queryType]: {
            ...state.activeFilterQuerry[queryType],
            [resource]: existingFilterValues
          }
        }
      };
    }

    // NOTE this can be refactored the same as above and combined similar to UPDATE_TAXONOMIES_FILTER
    case UPDATE_TAXONOMY_EXCLUDE_TYPE: {
      const type = action.payload;
      if (type === 'exclude') {
        const excludeSomeTaxonomies = state.activeFilterQuerry.excludeSome.taxonomyIds || [];
        return {
         ...state,
         activeFilterQuerry: {
           ...state.activeFilterQuerry,
           excludeSome: {
             ...state.activeFilterQuerry.excludeSome,
             taxonomyIds: []
           },
           exclude: {
             ...state.activeFilterQuerry.exclude,
             taxonomyIds: excludeSomeTaxonomies
           }
         }
       };
      }

      const excludeTaxonomies = state.activeFilterQuerry.exclude.taxonomyIds || [];
      return {
       ...state,
       activeFilterQuerry: {
         ...state.activeFilterQuerry,
         excludeSome: {
           ...state.activeFilterQuerry.excludeSome,
           taxonomyIds: excludeTaxonomies
         },
         exclude: {
           ...state.activeFilterQuerry.exclude,
           taxonomyIds: []
         }
       }
     };
    }

    case CLEAR_LOADED_FILTERS: {
      return {
        ...state,
        loaded: false,
        filters: []
      }
    }

    default: {
      return state;
    }
  }
}

export const getAdvancedFiltersState = (state: AppState) => state.advancedFilters;
export const getAdvancedFiltersLoading = createSelector(getAdvancedFiltersState, state => state.loading);
export const getAdvancedFiltersLoaded = createSelector(getAdvancedFiltersState, state => state.loaded);
export const getContentFilters = createSelector(getAdvancedFiltersState, state => state.filters);
export const getActiveAdvancedFilter = createSelector(getAdvancedFiltersState, state => state.activeFilterQuerry);
export const getActiveAdvancedFilterJson =
  createSelector(getAdvancedFiltersState, state => getStringifyAdvancedFilter({ ...state.activeFilterQuerry }));

export const getActiveHeadlineFilter = createSelector(getAdvancedFiltersState, state => state.activeFilterQuerry
    && state.activeFilterQuerry.include && state.activeFilterQuerry.include.headlineOrCatchline
    && state.activeFilterQuerry.include.headlineOrCatchline.like || '');

function updateResourceFilter(activeFilterQuerry, payload, resource) {
  const data = activeFilterQuerry[payload.type][resource] || [];
  if (payload.actionType === 'add') {
    return [...data, payload.data.id];
  }
  return data.filter(id => id !== payload.data.id);
}

export function getStringifyAdvancedFilter(advancedFilter) {
  const payload = removeEmpty(advancedFilter);
  Object.keys(payload).forEach(key => {
    if (Object.keys(payload[key]).length < 1) {
      return delete payload[key];
    }
  });
  return payload;
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach(key => {
    const isObjectEmpty = obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key]) && Object.keys(obj[key]).length < 1;
    if (isObjectEmpty) {
      return delete obj[key];
    }

    const hasNestedObjects = obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key]);
    if (hasNestedObjects) {
      removeEmpty(obj[key]);
      if (Object.keys(obj[key]).length < 1) {
        delete obj[key];
      }
      return;
    }

    const emptyArray = obj[key] && Array.isArray(obj[key]) && obj[key].length < 1;
    if (emptyArray) {
      return delete obj[key];
    }

    const noValue = obj[key] === '' || obj[key] === null;
    if (noValue) {
      return delete obj[key];
    }
  });
  return { ...obj };
}
