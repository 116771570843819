import { AbstractControl, UntypedFormArray, ValidationErrors } from '@angular/forms';
import { get } from 'lodash-es';
import { Observable, timer, switchMap, of } from 'rxjs';

/**
 * TODO update the comment, we no longer set errors on other fields (but we still remove them)
 * Used to ensure there are no duplicate keys in a given form configuration.
 * Duplicate keys would result in data being overwritten, as custom field definition
 * array is used to create a FormGroup, and the final output is a map of field keys
 * into chosen field values.
 *
 * The input parameter is the form array containing list of form groups which have the
 * key control, i.e. form groups represent the custom field configuration
 *
 * Note 1: this validator actually MUST also handle all other sibling field config
 * key fields, as we need to potentially remove the errors on other duplicate key field
 * when the current key value changes, which makes the code below a lot more complicated
 *
 * Note 2: since we are updating other fields, we need to trigger update value and validity
 * on those fields, and since other fields have this same validator, there needs to be a
 * small delay (100ms) to avoid circle triggering in an endless loop
 *
 * @param parentFormArray FormArray onto which the validator will be attached
 * @returns async angular form validator
 */
export function getCustomFieldKeyUniquenessValidator(parentFormArray: UntypedFormArray) {
  return (currentKeyControl: AbstractControl): { [key: string]: any | null } => {
    // skip the field key uniqueness validation as long as there are no changes to the array
    // this includes skipping the initial check for all fields
    if(currentKeyControl.parent.pristine) {
      return Promise.resolve(null);
    }

    // that the 100ms delay here means that these don't get chain triggered
    return new Promise((resolve) => {
      const keyControls = getSiblingKeyControls(parentFormArray);
      // we compile a map of keys to let us know which are unique and which are not
      const keyUniquenessMap: any = keyControls
        .map((control) => control.value)
        .filter((key) => !!key)
        .reduce((acc, key) => {
          // if not set, i.e. undefined, this key is unique - set to true
          // if the value has been set, we've seen this key and it isn't unique
          acc[key] = acc[key] === undefined ? true : false;
          return acc;
        }, {});

      // we need to remove the `notUnique` error from other key fields where needed
      keyControls
        // we are only interested in unique controls that have the `notUnique` error
        .filter((control) => control.hasError('notUnique') && keyUniquenessMap[control.value])
        // if the control had `notUnique` but the key is now unique, remove that error
        .forEach((control) => {
          delete control.errors['notUnique'];
          control.updateValueAndValidity();
        });

      // note this validator no longer sets the `notUnique` error on other fields
      // as that led to edge cases degrading performance or functionality

      const thisKeyIsUnique = keyUniquenessMap[currentKeyControl?.value];
      resolve(thisKeyIsUnique ? null : { notUnique: true });
    });
  };
}

// TODO write up a description and update the comment of `getCustomFieldKeyUniquenessValidator`
export const customFieldListKeyDuplicationValidator = (
  formArray: UntypedFormArray
): Observable<ValidationErrors | null> => {
  return timer(20).pipe(
    switchMap(() => {
      if (formArray.pristine) {
        return of(null);
      }

      const keyAppearanceCount = formArray.value
        .map((fieldDefinition) => fieldDefinition.key)
        .reduce((acc, key) => {
          acc[key] = acc[key] ? acc[key] + 1 : 1;
          return acc;
        }, {});
      const hasDuplicateKeys = Object.values(keyAppearanceCount).some(
        (appearanceCount: number) => appearanceCount > 1
      );

      return hasDuplicateKeys ? of({ duplicateKeysDefined: true }) : of(null);
    })
  );
};


function getSiblingKeyControls(parentFormArray) {
  const isCfgControl = checkIfParentArrayIsInCFG(parentFormArray);

  // if we are not in a CFG context, just return other sibling controls from Form array
  if(!isCfgControl) {
    return parentFormArray.controls.map((field) => field.get('key'));
  }

  // inside CFG context, we must take into account fields from other sub groups (steps)
  const cfgParentControls = parentFormArray.parent.parent.controls;
  const allCfgKeyFields = cfgParentControls
    .map(cfg => cfg.get('fields').controls)
    .map(cfgFields => {
      // for each sub-group of field definitions, get their key fields
      return cfgFields.map((field) => field.get('key'))
    })
    // concat all key controls into a single array
    .reduce((acc, fields) => acc.concat(fields));

    return allCfgKeyFields;
}

// lists of field configurations are grouped into steps within CFG configuration
// each step must be named, so this is what we're checking using a lodash helper `get`
function checkIfParentArrayIsInCFG(parentFormArray) {
  // first parent is the CFG step (FormGroup) in which the parentFormArray is housed
  // second parent is the list of steps (FromArray|fieldDefinitions)
  // we go up and down to make sure we are in CFG context, else we get null which we should never get
  const firstGroupLabel = get(parentFormArray, 'parent.parent.controls.0.value.label', null);
  return firstGroupLabel !== null;
}
