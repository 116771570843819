import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { Dashboard, systemDashboardPanels } from '../../store/dashboards/dashboards.model';
import shortid from 'shortid';
import { get } from 'lodash-es';

@Injectable()
export class DashboardsService {
  constructor(private rest: RestService) {}

  getDashboards() {
    return this.rest.get('dashboards').pipe(
      map((response) => {
        const dashboard = get(response, 'data[0]', this.getDefaultDashboard());
        dashboard.panels.forEach((panel) => (panel.id = panel.id || shortid.generate(10)));
        return [dashboard];
      })
    );
  }

  createDashboard(payload: Dashboard) {
    return this.rest.post('dashboards', payload).pipe(map((response) => [response.data]));
  }

  updateDashboard(payload: Dashboard) {
    return this.rest
      .put('dashboards/' + payload.id, payload)
      .pipe(map((response) => [response.data]));
  }

  private getDefaultDashboard() {
    return {
      name: 'Default Dashboard',
      panels: systemDashboardPanels.map((panel, position) => ({
        ...panel,
        position,
        id: shortid.generate(10),
      })),
    } as Dashboard;
  }
}
