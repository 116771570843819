const permissionIdToLabel = {

  1: 'GU_ACCOUNT_CREATE',
  2: 'GU_ACCOUNT_READ',
  3: 'GU_ACCOUNT_UPDATE',
  4: 'GU_ACCOUNT_DELETE',

  5: 'GU_ROLE_CREATE',
  6: 'GU_ROLE_READ',
  7: 'GU_ROLE_UPDATE',
  8: 'GU_ROLE_DELETE',

  9: 'GU_USER_CREATE',
  10: 'GU_USER_READ',
  11: 'GU_USER_UPDATE',
  12: 'GU_USER_DELETE',

  13: 'GU_PERMISSION_READ',

  14: 'GM_CONFIGURE_READ',
  15: 'GM_CONFIGURE_WRITE',
  16: 'GM_CONFIGURE_UPDATE',
  17: 'GM_CONFIGURE_DELETE',

  18: 'GM_IMAGE_READ',
  19: 'GM_IMAGE_WRITE',
  20: 'GM_IMAGE_UPDATE',
  21: 'GM_IMAGE_DELETE',

  22: 'GM_TAG_READ',
  23: 'GM_TAG_WRITE',
  24: 'GM_TAG_UPDATE',
  25: 'GM_TAG_DELETE',

  26: 'GM_GALLERY_READ',
  27: 'GM_GALLERY_WRITE',
  28: 'GM_GALLERY_UPDATE',
  29: 'GM_GALLERY_DELETE',

  30: 'GC_ARTICLE_READ',
  31: 'GC_ARTICLE_WRITE',
  32: 'GC_ARTICLE_UPDATE',
  33: 'GC_ARTICLE_DELETE',
  72: 'GC_ARTICLE_REVOKELOCK',
  81: 'GC_ARTICLE_PUBLISH',

  34: 'GC_TAXONOMY_READ',
  35: 'GC_TAXONOMY_WRITE',
  36: 'GC_TAXONOMY_UPDATE',
  37: 'GC_TAXONOMY_DELETE',

  38: 'GC_TEMPLATE_READ',
  39: 'GC_TEMPLATE_WRITE',
  40: 'GC_TEMPLATE_UPDATE',
  41: 'GC_TEMPLATE_DELETE',
  95: 'GC_TEMPLATE_PUBLISH',

  42: 'GC_PAGE_READ',
  43: 'GC_PAGE_WRITE',
  44: 'GC_PAGE_UPDATE',
  45: 'GC_PAGE_DELETE',
  94: 'GC_PAGE_PUBLISH',

  46: 'GC_ROUTE_READ',
  47: 'GC_ROUTE_WRITE',
  48: 'GC_ROUTE_UPDATE',
  49: 'GC_ROUTE_DELETE',

  55: 'GU_ACCOUNT_SETTINGS_UPDATE',

  56: 'GU_PASSWORD_CHANGE',

  57: 'GU_USER_ACTIVATE',
  58: 'GU_USER_DEACTIVATE',
  59: 'GU_USER_GET_DEACTIVATED',

  60: 'GC_3RD_PARTY_WIDGET_READ',
  61: 'GC_3RD_PARTY_WIDGET_WRITE',
  62: 'GC_3RD_PARTY_WIDGET_UPDATE',
  63: 'GC_3RD_PARTY_WIDGET_DELETE',

  64: 'GC_SYSTEM_WIDGET_READ',
  65: 'GC_SYSTEM_WIDGET_WRITE',
  66: 'GC_SYSTEM_WIDGET_UPDATE',
  67: 'GC_SYSTEM_WIDGET_DELETE',

  68: 'GC_AUTHOR_READ',
  69: 'GC_AUTHOR_WRITE',
  70: 'GC_AUTHOR_UPDATE',
  71: 'GC_AUTHOR_DELETE',

  73: 'GC_ARTICLE_TYPE_READ',
  74: 'GC_ARTICLE_TYPE_WRITE',
  75: 'GC_ARTICLE_TYPE_UPDATE',
  76: 'GC_ARTICLE_TYPE_DELETE',

  77: 'GC_REDIRECT_READ',
  78: 'GC_REDIRECT_WRITE',
  79: 'GC_REDIRECT_UPDATE',
  80: 'GC_REDIRECT_DELETE',

  82: 'GC_HTML_SNIPPET_READ',
  83: 'GC_HTML_SNIPPET_WRITE',
  84: 'GC_HTML_SNIPPET_UPDATE',
  85: 'GC_HTML_SNIPPET_DELETE',

  // 86: 'GC_ITEM_LIST_READ',
  // 87: 'GC_ITEM_LIST_WRITE',
  // 88: 'GC_ITEM_LIST_UPDATE',
  // 89: 'GC_ITEM_LIST_DELETE',

  90: 'GC_MENU_READ',
  91: 'GC_MENU_WRITE',
  92: 'GC_MENU_UPDATE',
  93: 'GC_MENU_DELETE',

  101: 'GC_CONTENT_PANEL_READ',
  102: 'GC_CONTENT_PANEL_WRITE',
  103: 'GC_CONTENT_PANEL_UPDATE',
  104: 'GC_CONTENT_PANEL_DELETE',

  96: 'GC_COLLECTION_TYPE_READ',
  97: 'GC_COLLECTION_TYPE_WRITE',
  98: 'GC_COLLECTION_TYPE_UPDATE',
  99: 'GC_COLLECTION_TYPE_DELETE',

  105: 'GC_COLLECTION_READ',
  106: 'GC_COLLECTION_WRITE',
  107: 'GC_COLLECTION_UPDATE',
  108: 'GC_COLLECTION_DELETE',
  109: 'GC_COLLECTION_PUBLISH',
  151: 'GC_COLLECTION_REVOKELOCK',

  110: 'GC_TAXONOMY_CONFIGURATION_READ',
  112: 'GC_TAXONOMY_CONFIGURATION_UPDATE',

  115: 'GM_FILE_READ',
  116: 'GM_FILE_WRITE',
  117: 'GM_FILE_UPDATE',
  118: 'GM_FILE_DELETE',

  122: 'GC_CONTENT_TAG_READ',
  123: 'GC_CONTENT_TAG_WRITE',
  124: 'GC_CONTENT_TAG_UPDATE',
  125: 'GC_CONTENT_TAG_DELETE',

  119: 'GU_SSO_SIGN_IN',
  120: 'GU_SSO_READ',
  121: 'GU_SSO_UPDATE',

  147: 'GC_FIELD_GROUP_READ',
  148: 'GC_FIELD_GROUP_WRITE',
  149: 'GC_FIELD_GROUP_UPDATE',
  150: 'GC_FIELD_GROUP_DELETE',

  134: 'GC_CONTENT_FILTER_READ',
  135: 'GC_CONTENT_FILTER_WRITE',
  136: 'GC_CONTENT_FILTER_UPDATE',
  137: 'GC_CONTENT_FILTER_DELETE',
  138: 'GC_CONTENT_FILTER_CHANGE_VISIBILITY',

  139: 'GV_ACCESS_BUNDLE_READ',
  140: 'GV_ACCESS_BUNDLE_WRITE',
  141: 'GV_ACCESS_BUNDLE_UPDATE',
  142: 'GV_ACCESS_BUNDLE_DELETE',
  143: 'GV_ACCESS_BUNDLE_PUBLISH',

  144: 'GV_CONFIGURE_READ',
  145: 'GV_CONFIGURE_UPDATE',

  152: 'GC_LIVE_REPORT_READ',
  153: 'GC_LIVE_REPORT_WRITE',
  154: 'GC_LIVE_REPORT_UPDATE',
  155: 'GC_LIVE_REPORT_DELETE',
  156: 'GC_LIVE_REPORT_PUBLISH',

  157: 'GU_USER_ACCOUNT_READ',
  158: 'GU_USER_ACCOUNT_UPDATE',

  160: 'GT_SUBSCRIBER_READ',
  161: 'GT_SUBSCRIBER_WRITE',
  162: 'GT_SUBSCRIBER_UPDATE',
  163: 'GT_SUBSCRIBER_DELETE',

  164: 'GC_CONTENT_LOCALE_READ',
  165: 'GC_CONTENT_LOCALE_WRITE',
  166: 'GC_CONTENT_LOCALE_UPDATE',
  167: 'GC_CONTENT_LOCALE_DELETE',

  //SN killSwitch  168: 'GC_SYSTEM_NOTIFICATION_CREATE',
  //SN killSwitch  169: 'GC_SYSTEM_NOTIFICATION_UPDATE',
  //SN killSwitch  170: 'GC_SYSTEM_NOTIFICATION_DELETE',

  171: 'GC_GAIA_SUMMARIZE',
  173: 'GC_GAIA_GENERATE',
  174: 'GC_GAIA_TRANSLATE',

  175: 'GC_LIVE_REPORT_TYPE_WRITE',
  176: 'GC_LIVE_REPORT_TYPE_READ',
  177: 'GC_LIVE_REPORT_TYPE_UPDATE',
  178: 'GC_LIVE_REPORT_TYPE_DELETE',

  182: 'GC_CONTENT_QUEUE_READ',
  183: 'GC_CONTENT_QUEUE_WRITE',
  184: 'GC_CONTENT_QUEUE_UPDATE',
  185: 'GC_CONTENT_QUEUE_DELETE',
};

const permissionLabelToId = Object.entries(permissionIdToLabel).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export function mapPermissionIdToLabel(permissionId: number) {
  return permissionIdToLabel[permissionId];
}

export function mapPermissionLabelToId(permissionId: number) {
  return permissionLabelToId[permissionId];
}

const fieldPermissionsLabelToId = {
  'ARTICLE_CUSTOM_FIELDS_READ': 1,
  'ARTICLE_CUSTOM_FIELDS_UPDATE': 2,
  'COLLECTION_CUSTOM_FIELDS_READ': 3,
  'COLLECTION_CUSTOM_FIELDS_UPDATE': 4,
  'TAXONOMY_CUSTOM_FIELDS_READ': 5,
  'TAXONOMY_CUSTOM_FIELDS_UPDATE': 6,
  'ARTICLE_GLIDE_VERIFY_READ': 7,
  'ARTICLE_GLIDE_VERIFY_UPDATE': 8,
  'LIVE_REPORT_GLIDE_VERIFY_READ': 9,
  'LIVE_REPORT_GLIDE_VERIFY_UPDATE': 10,
  'ARTICLE_MULTI_CHANNEL_PUBLISHING_READ': 11,
  'ARTICLE_MULTI_CHANNEL_PUBLISHING_UPDATE': 12,
  'COLLECTION_MULTI_CHANNEL_PUBLISHING_READ': 13,
  'COLLECTION_MULTI_CHANNEL_PUBLISHING_UPDATE': 14,
  'CONTENT_QUEUE_GLOBAL_UPDATE': 15,
}

const fieldPermissionsIdToLabel = {
  1: 'ARTICLE_CUSTOM_FIELDS_READ',
  2: 'ARTICLE_CUSTOM_FIELDS_UPDATE',
  3: 'COLLECTION_CUSTOM_FIELDS_READ',
  4: 'COLLECTION_CUSTOM_FIELDS_UPDATE',
  5: 'TAXONOMY_CUSTOM_FIELDS_READ',
  6: 'TAXONOMY_CUSTOM_FIELDS_UPDATE',
  7: 'ARTICLE_GLIDE_VERIFY_READ',
  8: 'ARTICLE_GLIDE_VERIFY_UPDATE',
  9: 'LIVE_REPORT_GLIDE_VERIFY_READ',
  10: 'LIVE_REPORT_GLIDE_VERIFY_UPDATE',
  11: 'ARTICLE_MULTI_CHANNEL_PUBLISHING_READ',
  12: 'ARTICLE_MULTI_CHANNEL_PUBLISHING_UPDATE',
  13: 'COLLECTION_MULTI_CHANNEL_PUBLISHING_READ',
  14: 'COLLECTION_MULTI_CHANNEL_PUBLISHING_UPDATE',
  15: 'CONTENT_QUEUE_GLOBAL_UPDATE'
}

export function mapFieldPermissionIdToLabel(fieldPermissionId: number) {
  return fieldPermissionsIdToLabel[fieldPermissionId];
}

export function mapFieldPermissionLabelToId(fieldPermissionLabel) {
  return fieldPermissionsLabelToId[fieldPermissionLabel];
}
