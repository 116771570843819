import {
  tap,
  catchError,
  map,
  take,
  filter,
  mergeMap,
  withLatestFrom,
  debounceTime,
} from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ArticlesService } from '../../api';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  GET_ARTICLES,
  GetArticlesSuccessAction,
  DELETE_ARTICLE,
  DeleteArticleSuccessAction,
  ARTICLE_LIST_ACTION_FAILED,
  ArticleListFailedAction,
  COPY_ARTICLE_URL,
  CopyArticleUrlSuccessAction,
  CopyArticleUrlFailedAction,
  GET_ARTICLES_SUCCESS,
  GET_LOCALIZED_VERSIONS_ARTICLES,
  GetLocalizedVersions4Articles,
  GetLocalizedVersions4ArticlesSuccess,
} from './article-list.actions';
import { AppState } from '../app-reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { getActiveAdvancedFilterJson } from '../advanced-filtering-option/advanced-filtering-option.reducer';
import { multipleLocalesExist } from '../content-locales/content-locales.reducer';
import { ArticleListColumnManager } from '../../miscellaneous/article-list-column-manager.service';

@Injectable()
export class ArticleListEffects {
  loadList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ARTICLES),
    withLatestFrom(this.store.select(getActiveAdvancedFilterJson)),
    mergeMap(([action, advancedFilters]: [UnsafeAction, any]) => {
      const { payload } = action;
      return this.articlesService.getArticlesAdvancedFiltering({ ...payload, filter: advancedFilters }).pipe(
        map((articlesListData) => new GetArticlesSuccessAction(articlesListData)),
        catchError((e) => of(new ArticleListFailedAction({ error: e, action })))
      )
    })
  ));

  loadLocalizedVersionsData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ARTICLES_SUCCESS),
    withLatestFrom(this.articleListColumnManager.getActiveColumns(), this.store.select(multipleLocalesExist)),
    filter(([, activeColumns, multipleLocalesExist]) => !!multipleLocalesExist),
    map(([action, ,]: [GetArticlesSuccessAction, boolean, boolean]) => {
      const articleIds = action.payload.articles.map(article => article.id);
      return new GetLocalizedVersions4Articles(articleIds);
    })
  ))

  loadLocalizedVersionsData2$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_LOCALIZED_VERSIONS_ARTICLES),
    mergeMap((action: GetLocalizedVersions4Articles) => {
      if(action.articleIds.length === 0) {
        return of(new GetLocalizedVersions4ArticlesSuccess([]));
      }
      return this.articlesService
        .getLocalizedVersionsForArticles(action.articleIds)
        .pipe(
          map(localizedVersions => (new GetLocalizedVersions4ArticlesSuccess(localizedVersions))),
          catchError((e) => of(new ArticleListFailedAction({ error: e, action })))
        )
    })
  ))

  deleteArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_ARTICLE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.articlesService.deleteArticle(action.payload).pipe(
        map((response) => new DeleteArticleSuccessAction(response)),
        catchError((e) => of(new ArticleListFailedAction({ error: e, action })))
      )
    )
  ));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(ARTICLE_LIST_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  copyArticleUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(COPY_ARTICLE_URL), mergeMap((action: UnsafeAction) => {
    return this.articlesService.getArticleRevisionUrlPreview(action.payload).pipe(
         map((articleUrl) => {
           if(!articleUrl) {
              this.snackBar.open($localize`This link is unavailable. Please contact the account administrator to help.`, $localize`Close`, { duration: 4000 });
           } else {
              navigator.clipboard.writeText(articleUrl);
              this.snackBar.open($localize`Copied to clipboard`, $localize`Close`, { duration: 4000 });
           }
           return new CopyArticleUrlSuccessAction()
         }),
         catchError((e) => {
          this.snackBar.open($localize`Action Failed: Article path cannot be generated for this article.`, $localize`Close`, { duration: 4000 });
          return of(new CopyArticleUrlFailedAction())
         })
     );
  })))

  constructor(
    private actions$: Actions,
    private articlesService: ArticlesService,
    public snackBar: MatSnackBar,
    private store: Store<AppState>,
    public router: Router,
    public articleListColumnManager: ArticleListColumnManager,
  ) {}
}
