import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { PreviewV2Service } from '../../../../core/api/preview/preview-v2.service';
import { PermissionService } from '../../../../core/api/auth/permissions.service';
import { Permissions } from '../../../../core/store/auth/permissions';
import { AccountSettingsService } from '../../../../core/api/account-settings/accounts-settings.service';
import { ContentQueueSource } from '../../../../core/api/content-queues/content-queues.service';
import { AppState } from '../../../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { CopyArticleByIdAction } from '../../../../core/store/article/article.actions';
import { Router } from '@angular/router';
import { CopyArticleUrlAction } from '../../../../core/store/articles-list/article-list.actions';
import { ModalsService } from '../../modals.service';
import { ArticleQuickViewComponent } from '../../../article-quick-view/article-quick-view.component';
import { addUILocale } from '../../../../gpp-shared/i18n-utilities/i18n-utilities';

export enum ContentQueueRCDialogOption {
  Preview = 'preview',
  Copy = 'copy',
  CopyLink = 'copyLink',
  QuickView = 'quickView',
}

@Component({
  selector: 'gd-cq-table-rc-dialog',
  templateUrl: './cq-table-rc-dialog.component.html',
  styleUrls: ['./cq-table-rc-dialog.component.scss'],
})
export class CQTableRCDialogComponent implements OnInit {
  @Input() contentType: 'ARTICLE' | 'COLLECTION' | any;
  @Input() item;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  allOptions = [];

  get filteredOptions() {
    return this.allOptions.filter((option) =>
      option.allowedTypes.includes(this.contentType as any)
    );
  }

  get hasCustomDataPermission() {
    switch (this.contentType) {
      case ContentQueueSource.Article:
        return this.permissionService.hasFieldPermission(Permissions.ARTICLE_CUSTOM_FIELDS_UPDATE);
      case ContentQueueSource.Collection:
        return this.permissionService.hasFieldPermission(
          Permissions.COLLECTION_CUSTOM_FIELDS_UPDATE
        );
      default:
        return false;
    }
  }

  get localizedTypeLabel() {
    switch (this.contentType) {
      case ContentQueueSource.Article:
        return $localize`Article`;
      case ContentQueueSource.Collection:
        return $localize`Collection`;
      default:
        return '';
    }
  }

  constructor(
    private previewV2Service: PreviewV2Service,
    private permissionService: PermissionService,
    private settingsService: AccountSettingsService,
    private store: Store<AppState>,
    private router: Router,
    private modalService: ModalsService
  ) {}

  ngOnInit(): void {
    this.allOptions = this.getAllOptions();
  }

  getAllOptions() {
    return [
      {
        key: ContentQueueRCDialogOption.Preview,
        label: $localize`Preview ` + this.localizedTypeLabel,
        icon: 'fa-eye',
        allowedTypes: [ContentQueueSource.Article],
      },
      {
        key: ContentQueueRCDialogOption.Copy,
        label: $localize`Copy ` + this.localizedTypeLabel,
        icon: 'fa-copy',
        allowedTypes: [ContentQueueSource.Article],
      },
      {
        key: ContentQueueRCDialogOption.CopyLink,
        label: $localize`Copy Link`,
        icon: 'fa-link',
        allowedTypes: [ContentQueueSource.Article],
      },
      {
        key: ContentQueueRCDialogOption.QuickView,
        label: $localize`Quick View`,
        icon: 'fa-scroll',
        allowedTypes: [ContentQueueSource.Article],
      },
    ];
  }

  getOptionUIData(option) {
    switch (option.key) {
      case ContentQueueRCDialogOption.Preview:
        return this.getPreviewOptionUIData() as any;
      case ContentQueueRCDialogOption.Copy:
        return {
          displayed: true,
          tooltip: !this.hasCustomDataPermission && this.getCopyOptionTooltip(),
        } as any;
      case ContentQueueRCDialogOption.CopyLink:
        return { displayed: !!this.settingsService.getBaseUrl() } as any;
      case ContentQueueRCDialogOption.QuickView:
        return { displayed: this.contentType === ContentQueueSource.Article } as any;
      default: {
        return { displayed: true } as any;
      }
    }
  }

  getPreviewOptionUIData() {
    if (![ContentQueueSource.Article, ContentQueueSource.Collection].includes(this.contentType)) {
      return { displayed: false };
    }
    const previewStatusInfo: any = this.previewV2Service.getCachedPreviewStatusInfo() || {};
    let tooltip = '';
    if (!previewStatusInfo.configured) {
      tooltip = $localize`The Preview service has not been configured properly.`;
    } else if (previewStatusInfo.hasError) {
      tooltip = this.getUnsupportedPreviewError();
    } else if (!this.item.stagedRevisionId) {
      tooltip =
        this.contentType === ContentQueueSource.Article
          ? $localize`The article is not yet staged for preview.`
          : $localize`The collection is not yet staged for preview.`;
    }
    return { displayed: previewStatusInfo.enabled, tooltip, disabled: !!tooltip };
  }

  getUnsupportedPreviewError() {
    switch (this.contentType) {
      case ContentQueueSource.Article:
        return $localize`The Preview service provider does not support Articles.`;
      case ContentQueueSource.Collection:
        return $localize`The Preview service provider does not support Collections.`;
      default:
        return '';
    }
  }

  getCopyOptionTooltip() {
    switch (this.contentType) {
      case ContentQueueSource.Article:
        return $localize`You do not have the necessary permissions to copy article custom data. Custom data will be removed from the copied article, or revert to default values if configured. Permission to copy custom data is set in Users & Roles settings, and may require Admin access.`;
      case ContentQueueSource.Collection:
        return $localize`You do not have the necessary permissions to copy collection custom data. Custom data will be removed from the copied collection, or revert to default values if configured. Permission to copy custom data is set in Users & Roles settings, and may require Admin access.`;
      default:
        return '';
    }
  }

  handleOptionClick(option) {
    switch (option.key) {
      case ContentQueueRCDialogOption.Preview:
        this.preview();
        break;
      case ContentQueueRCDialogOption.Copy:
        this.copy();
        break;
      case ContentQueueRCDialogOption.CopyLink:
        this.copyLink();
        break;
      case ContentQueueRCDialogOption.QuickView:
        this.quickView();
        break;
    }
    this.closeDialog.emit();
  }

  preview() {
    const config = {
      contentId: this.item.id,
      contentGlideId: this.item.glideId,
      contentType: this.contentType,
      stagedRevisionId: this.item.stagedRevisionId,
    };
    this.previewV2Service
      .getPreview(config)
      .subscribe((response: any) => response && window.open(response.previewUrl));
  }

  copy() {
    if (this.contentType === ContentQueueSource.Article) {
      const url = `/articles/create?copy=true&copySourceId=${this.item.id}`;
      window.open(addUILocale(url), '_blank');
      return;
    }
  }

  copyLink() {
    if (this.contentType === ContentQueueSource.Article) {
      this.store.dispatch(
        new CopyArticleUrlAction(this.item.publishedRevisionId || this.item.latestRevisionId)
      );
      return;
    }
  }

  quickView() {
    if (this.contentType === ContentQueueSource.Article) {
      this.modalService
        .custom('article-quick-view', ArticleQuickViewComponent, {
          width: '100%',
          height: '100%',
          maxWidth: '62vw',
          maxHeight: '70vh',
          panelClass: 'gd-quick-view-modal',
          data: { id: this.item.id },
        })
        .subscribe();
      return;
    }
  }
}
