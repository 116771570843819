import { map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { firstValueFrom, of } from 'rxjs';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { ArticlesService } from '../articles/articles.service';
import { AppState } from '../../store/app-reducer';
import { Store } from '@ngrx/store';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';
import { getUniqueArray } from '../../store';
import { GetUsersAction, getUsersMap } from '../../store/users';

export enum ContentQueueSource {
  Article = 'ARTICLE',
  Collection = 'COLLECTION',
}

@Injectable()
export class ContentQueuesService {
  data = Array(53)
    .fill(1)
    .map((e, i) => {
      return {
        id: i + 1,
        name: `CQ ${i + 1}`,
        source: ['articles', 'collections', 'live-reports'][Math.floor(Math.random() * 3)],
        type: ['Global', 'Personal'][Math.floor(Math.random() * 2)],
        description: 'Descriptionnn',
        query: {
          filter: {
            include: {
              headline: {
                like: ['test', 'GAIA', 'headline'][Math.floor(Math.random() * 3)],
              },
            },
          },
          limit: [23, 35, 27][Math.floor(Math.random() * 3)],
          page: 0,
        },
      };
    });

  showTaxonomyPath = this.accountSettingsService.getShowTaxonomyPathFlag();

  constructor(
    private rest: RestService,
    private store: Store<AppState>,
    private articleService: ArticlesService,
    private accountSettingsService: AccountSettingsService
  ) {}

  getContentQueues({ name, source, type, page, size }) {
    /* let res = this.data;
    if (name) {
      res = res.filter((el) => el.name.toLowerCase().includes(name.toLowerCase()));
    }
    if (source) {
      res = res.filter((el) => el.source === source);
    }
    page = page || 0;
    size = size || defaultPageSize;
    const total = res.length;
    res = res.slice(page * size, page * size + size);

    return of({ data: res, meta: { page: { number: page, size, total } } }); */
    let requestPath = `content-queues?page=${page || 0}&size=${size || defaultPageSize}`;
    requestPath += name ? `&name=${encodeURIComponent(name)}` : '';
    requestPath += source ? `&source=${source}` : '';
    requestPath += type ? `&type=${type}` : '';
    return this.rest.get(requestPath);
  }

  getContentQueue(id) {
    return this.rest.get('content-queues/' + id).pipe(map((response) => response.data));
  }

  getContentQueuesByIds(contentQueuesIds) {
    if (contentQueuesIds.length < 1) {
      return of([]);
    }

    const size = contentQueuesIds.length <= 200 ? contentQueuesIds.length : 200;
    return this.rest
      .get(`content-queues?ids=${contentQueuesIds.join(',')}&size=${size}`)
      .pipe(map((response) => response.data));
  }

  createContentQueue(payload) {
    return this.rest.post('content-queues', payload).pipe(map((response) => response.data));
  }

  updateContentQueue(cq) {
    return this.rest.put('content-queues/' + cq.id, cq).pipe(map((response) => response.data));
  }

  deleteContentQueue(id) {
    return this.rest.delete(`content-queues/${id}`);
  }

  getContentQueueResources(query, source) {
    switch (source) {
      case 'ARTICLE': {
        return this.getContentQueueArticles(query);
      }
      case 'COLLECTION': {
        return of([])
      }
      case 'LIVE-REPORT': {
        return of([])
      }
    }
  }

  private getContentQueueArticles(query) {
    return this.articleService.getArticlesAdvancedFiltering(query, { noPagination: true, usage: 'CONTENT-QUEUES' }).pipe(
      map(({ articles }) => articles),
      tap((articles) => {
        const updatedByIds = articles.map((item) => item.updatedBy);
        const userIds = getUniqueArray([...updatedByIds]) as number[];
        this.store.select(getUsersMap).pipe(take(1)).subscribe(usersMap => {
          const usersNotLoaded = userIds.some(id => !usersMap[id]);
          if (usersNotLoaded) {
            this.store.dispatch(new GetUsersAction({ ids: userIds }));
          }
        })
      })
    );
  }
}
