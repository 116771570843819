import { mergeMap, catchError, map, tap, take, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, firstValueFrom, forkJoin, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  GET_REFERENCED_CONTENT,
  GetReferencedContentSuccessAction,
  REFERENCED_CONTENT_ACTION_FAILED,
  ReferencedContentFailedAction,
} from './referenced-content-repository.actions';
import { ArticlesService, ImagesService } from '../../api';
import { ResourceType } from './referenced-content-repository.model';
import { ArticleTypesService } from '../../api/article-types/article-types.service';
import { CollectionTypeService } from '../../api/collection-types/collection-types.service';
import { ContentTagsService } from '../../api/content-tags/content-tags.service';
import { HtmlSnippetsService } from '../../api/html-snippets/html-snippets.service';
import { ContentLocalesService } from '../../api/content-locales/content-locales.service';
import { FilesService } from '../../api/files/files.service';
import { MenusService } from '../../api/menus/menus.service';
import { TaxonomiesService } from '../../api/taxonomies/taxonomies.service';
import { CollectionsService } from '../../api/collections/collections.service';
import { LiveReportsService } from '../../api/live-reports/live-reports.service';
import { GalleriesService } from '../../api/galleries/galleries.service';
import { AuthorsService } from '../../api/authors/authors.service';
import { FieldGroupsService } from '../../api/field-groups/field-groups.service';
import { WidgetTypesService } from '../../api/widgets/widgets.service';
import { AppState } from '../app-reducer';
import { getReferencedContentRepository } from './referenced-content-repository.reducer';
import { ContentQueuesService } from '../../api/content-queues/content-queues.service';

@Injectable()
export class ReferencedContentRepositoryEffects {
  getReferencedContent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_REFERENCED_CONTENT),
      tap((action: UnsafeAction) => ({})),
      mergeMap((action: UnsafeAction) => {
        const { ids, type } = action.payload;
        return this.getData(ids, type).pipe(
          map((data) => ({ data })),
          catchError((error) => of({ error })),
          map((response) => new GetReferencedContentSuccessAction({ ids, type, response })),
          catchError((e) => of(new ReferencedContentFailedAction(e)))
        );
      })
    )
  );

  actionFailed$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(REFERENCED_CONTENT_ACTION_FAILED),
        tap((err: any) => {
          const actionType =
            (err && err.payload && err.payload.action && err.payload.action.type) ||
            $localize`Unknown`;
          this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, {
            duration: 4000,
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private imagesService: ImagesService,
    private articlesService: ArticlesService,
    private articleTypesService: ArticleTypesService,
    private collectionTypesService: CollectionTypeService,
    private contentTagsService: ContentTagsService,
    private htmlSnippetsService: HtmlSnippetsService,
    private contentLocalesService: ContentLocalesService,
    private filesService: FilesService,
    private menusService: MenusService,
    private taxonomiesService: TaxonomiesService,
    private collectionsService: CollectionsService,
    private liveReportsService: LiveReportsService,
    private galleriesService: GalleriesService,
    private authorsService: AuthorsService,
    private fieldGroupsService: FieldGroupsService,
    private widgetTypesService: WidgetTypesService,
    private contentQueueService: ContentQueuesService
  ) {}

  getData(ids, type) {
    switch (type) {
      case ResourceType.Article:
        return this.articlesService.getArticlesByIds(ids);
      case ResourceType.Collection:
        return this.collectionsService.getCollectionsByIds(ids);
      case ResourceType.Gallery:
        return forkJoin(
          ids.map((id) =>
            firstValueFrom(this.galleriesService.getGalleryDetails(id)).catch((error) => {
              if (error?.status !== 403) {
                return {};
              }
              throw error;
            })
          )
        );
      case ResourceType.LiveReport:
        return forkJoin(
          ids.map((id) =>
            firstValueFrom(this.liveReportsService.getLiveReport(id)).catch((error) => {
              if (error?.status !== 403) {
                return {};
              }
              throw error;
            })
          )
        );
      case ResourceType.ArticleType:
        return this.articleTypesService.getArticleTypesByIds(ids);
      case ResourceType.CollectionType:
        return this.collectionTypesService.getCollectionTypesByIds(ids);
      case ResourceType.ContentTag:
        return this.contentTagsService.getContentTagsById(ids);
      case ResourceType.HTMLSnippet:
        return this.htmlSnippetsService.getHtmlSnippetByIds(ids);
      case ResourceType.Menu:
        return this.menusService.getMenusByIds(ids);
      case ResourceType.Author:
        return this.authorsService.getAuthorsByIds(ids);
      case ResourceType.Image:
        return forkJoin(
          ids.map((id) =>
            this.imagesService
              .getImage(id)
              .then((img) => this.imagesService.processImage(img))
              .catch((error) => {
                if (error?.status !== 403) {
                  return {};
                }
                throw error;
              })
          )
        );
      case ResourceType.File:
        return forkJoin(
          ids.map((id) =>
            this.filesService.getFileById(id).catch((error) => {
              if (error?.status !== 403) {
                return {};
              }
              throw error;
            })
          )
        );
      case ResourceType.ContentLocale:
        return this.contentLocalesService.getAllContentLocales();
      case ResourceType.CFG:
        return this.fieldGroupsService.getReferencedCustomFieldGroupsByIds(ids);
      case ResourceType.Widget:
        return this.store.select(getReferencedContentRepository).pipe(
          take(1),
          filter((items) => {
            return !Object.keys(items).filter((key) => {
              return (
                key.startsWith(ResourceType.Widget) &&
                !ids.map((id) => `${ResourceType.Widget}:${id}`).includes(key)
              );
            }).length;
          }),
          mergeMap(() => this.widgetTypesService.getAllWidgets())
        );
      case ResourceType.ContentQueue:
        return this.contentQueueService.getContentQueuesByIds(ids);
      case ResourceType.Taxonomy:
        return this.taxonomiesService
          .loadAllTaxonomies()
          .pipe(map((taxonomies) => Object.values(taxonomies)));
      default:
        return of(ids);
    }
  }
}
