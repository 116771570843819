import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { DashboardsState } from './dashboards.model';
import {
  CREATE_DASHBOARD,
  CREATE_DASHBOARD_SUCCESS,
  GET_DASHBOARDS,
  GET_DASHBOARDS_SUCCESS,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SUCCESS,
} from './dashboards.actions';

const initialState: DashboardsState = {
  loaded: false,
  loading: false,
  error: null,
  items: [],
};

export function dashboardsReducer(state: DashboardsState = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_DASHBOARDS: {
      return {
        ...state,
        items: [],
        loading: true,
        loaded: false,
      };
    }
    case CREATE_DASHBOARD:
    case UPDATE_DASHBOARD: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_DASHBOARDS_SUCCESS:
    case CREATE_DASHBOARD_SUCCESS:
    case UPDATE_DASHBOARD_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        items: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getDashboardsState = (state) => state.dashboards;
export const getDashboards = createSelector(getDashboardsState, (state) => state.items);
export const getDashboardLoadingFlag = createSelector(getDashboardsState, (state) => state.loading);
