import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ModalsService } from '../../../shared/modals/modals.service';
import { AppState } from '../../store/app-reducer';
import { AccountChangeAction, BEFORE_ACCOUNT_CHANGED } from '../../store/auth/auth.actions';
import { AuthService } from '../auth/auth.service';
import { Actions, ofType } from '@ngrx/effects';
import { UnsafeAction } from '../../store/unsafe-action.interface';
import { NavigationEnd, Router } from '@angular/router';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';

@Injectable({ providedIn: 'root' })
export class AccountSwitchingService {
  // a reference of the component that is associated with the activated route
  activatedRouteComponentRef;
  // a flag that will be used in UnsavedChanges guard to prevent default behavior
  forceAccountChange = false;

  redirectMap = {
    // ending slash is important, quick way for detecting single resource url, eg. '/articles/123456'
    '/articles/': '/articles',
  };

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private modalService: ModalsService,
    private actions$: Actions,
    private router: Router,
    private accountSettingsService: AccountSettingsService
  ) {
    this.setAccountChangeListener();
  }

  private setAccountChangeListener() {
    this.actions$.pipe(ofType(BEFORE_ACCOUNT_CHANGED)).subscribe((action: UnsafeAction) => {
      const { accountId } = action.payload;
      const hasUnsavedChanges =
        typeof this.activatedRouteComponentRef?.hasUnsavedChanges === 'function' &&
        this.activatedRouteComponentRef.hasUnsavedChanges();

      if (!hasUnsavedChanges) {
        this.switchAccount(accountId);
        return;
      }
      this.showConfirmationDialog()
        .pipe(filter((confirmed) => !!confirmed))
        .subscribe(() => {
          this.setForceAccountChangeFlag(true);
          this.switchAccount(accountId);
        });
    });
  }

  private switchAccount(accountId) {
    this.accountSettingsService.reset();
    this.authService.refreshAuthToken(accountId).subscribe(() => {
      // a quick way for detecting if a user is already on the dashboard page
      const dashboardActive = this.router.url.includes('dashboard');
      if (dashboardActive) {
        this.router.navigate(['/dashboard']);
        this.store.dispatch(new AccountChangeAction({ accountId }));
        return;
      }
      // prepare navigation events listener
      // once the redirection is done dispatch account change action that will result in clearing the state
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd), take(1))
        .subscribe(() => this.store.dispatch(new AccountChangeAction({ accountId })));

      // trigger redirection
      this.router.navigate([this.getRedirectLocation()]);
    });
  }

  private showConfirmationDialog() {
    const title = $localize`There are unsaved changes`;
    const message = $localize`Are you sure you want to change account?`;
    return this.modalService.confirm(title, message);
  }

  private getRedirectLocation() {
    const locationKey = Object.keys(this.redirectMap).find((key) =>
      this.router.url.startsWith(key)
    );
    return this.redirectMap[locationKey] || '/dashboard';
  }

  setActivatedRouteComponentRef(ref) {
    this.activatedRouteComponentRef = ref;
  }

  setForceAccountChangeFlag(val) {
    this.forceAccountChange = val;
  }
}
