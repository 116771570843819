import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ImagesService } from '../../core/api';
import { AccountSettingsService } from '../../core/api/account-settings/accounts-settings.service';
import { ArticleQuickViewContentService } from '../../core/body-content/referenced-content/article-quick-view-referenced-content.service';
import { DateFormatService } from '../../core/miscellaneous/date-format.service';
import { AppState } from '../../core/store/app-reducer';
import {
  ClearArticleQuickViewAction,
  GetArticleQuickViewAction,
} from '../../core/store/article-quick-view/article-quick-view.actions';
import {
  getActiveArticle,
  getArticleQuickViewLoading,
  getReferencedContent,
} from '../../core/store/article-quick-view/article-quick-view.reducers';
import { transformModelIntoDOM } from '../editors/froala/froala-content-transform';
import { BidiService } from '../../core/i18n/bidi.service';
@Component({
  selector: 'gd-article-quick-view',
  templateUrl: './article-quick-view.component.html',
  styleUrls: [
    './article-quick-view.component.scss',
    '../editors/froala/froala.component.scss',
    '../../gpp-shared/styles/froala/froala_style.min.css',
    '../../gpp-shared/styles/content-tag/content-tag.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleQuickViewComponent implements OnInit, OnDestroy {
  componentSub: Subscription = new Subscription();
  quickViewArticle$;
  loading$ = this.store.select(getArticleQuickViewLoading);
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private articleQuickViewContentService: ArticleQuickViewContentService,
    private store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private imageService: ImagesService,
    private dateService: DateFormatService,
    private accountSettingsService: AccountSettingsService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    const id = this.dialogData.id;
    this.store.dispatch(new GetArticleQuickViewAction(id));

    this.componentSub.add(
      combineLatest([
        this.store.select(getActiveArticle).pipe(filter(({ id }) => !!id)),
        this.store.select(getReferencedContent),
      ]).pipe(debounceTime(200)).subscribe(([article, referencedContent]) => {
        const revision = article.publishedRevision || article.latestRevision;

        // prepare article body for rendering in DOM
        const body =
          typeof revision['body'] === 'string'
            ? JSON.parse(revision['body'])
            : revision['body'] || { contentStructure: [], contentNodes: {} };

        this.articleQuickViewContentService.loadReferencedContentForContentModel(
          body,
          referencedContent
        );
        const targetRegex = /target="framename"|target="_parent"|target="_self"|target="_top"/g;
        const bodyHtml = transformModelIntoDOM(body.contentStructure, body, referencedContent, 'quickView').replace(targetRegex, "target='_blank'");

        // fetch promo image
        const { promoImageId } = revision as any;

        if (promoImageId) {
          this.imageService
            .getImage(promoImageId)
            .then((img) => {
              const processedImage = this.imageService.processImage(img);
              revision['promoImage'] = processedImage.thumbnail;
            })
            .catch((e) => {
              revision['promoImage'] = '';
            });
        }

        // generate revision time
        const timezone = this.accountSettingsService.getTimezone();
        const date = this.dateService.getFormatedDate(
          revision['createdAt'],
          'DD-MMM-YYYY h:mm A',
          timezone
        );
        revision['date'] = date;

        this.quickViewArticle$ = {
          revision: revision,
          body: this.sanitizer.bypassSecurityTrustHtml(bodyHtml),
        };
      })
    );
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
    this.store.dispatch(new ClearArticleQuickViewAction());
  }
}
