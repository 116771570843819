import { mergeMap, withLatestFrom, map, filter, tap, take } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, of, from, combineLatest, firstValueFrom, forkJoin } from 'rxjs';
import { Subject } from '../../../../../node_modules/rxjs';

import { ImagesUploadService, getUserFriendlyRatio } from '../../api';
import { UnsafeAction } from '../unsafe-action.interface';
import { AppState } from '../app-reducer';
import {
  IMAGE_UPLOADED,
  UpdateUploadedImageAction,
  UPLOAD_STARTED,
  UpdateQueuedImagesAction,
  ImageUploadedAction,
  ADD_IMAGES_TO_QUEUE,
  AddImagesToQueueDoneAction,
  AllImagesUploadedAction,
} from './images-upload.actions';
import { getQueuedImages, getQueuedImagesArray } from './images-upload.reducer';
import { getImageSizesArray } from '../images-configuration';
import { generateShortId } from '../../../shared/shared-functions';

@Injectable()
export class ImagesUploadEffects {
  stopPolling = new Subject();
  uploadingMessageRef = null;


  $imagesAddedToQueue: Observable<Action> = createEffect(() => this.actions$.pipe(
    filter((action: UnsafeAction) => action.type === ADD_IMAGES_TO_QUEUE),
    map((action: UnsafeAction) => {
      // File validation for queued images
      const imagesToEnqueue = action.payload.map(image => {
        let fileHasAllowedType = this.imagesUploadService.isAllowedType(image.fileData.type);
        const fileSizeExceedsLimit = this.imagesUploadService.fileSizeLimitExceeds(
          image.fileData.size
        );
        // check image format in case of image/jpeg MIME type
        // prevent upload of jfif extension - not supported on sharp
        if (image.fileData.type === 'image/jpeg') {
          const regex = /[^.]+$/g;
          const extension = image.fileData.name.toLowerCase().match(regex)[0];
          fileHasAllowedType = extension !== 'jfif';
        }
        // Add allowedType to img object and set to true
        image = { ...image, allowedType: true };
        // Check if file size doesn't exceed limit
        if (!fileHasAllowedType) {
          image = { ...image, allowedType: false };
          this.snackBar.open($localize`Unsupported file type: ${image.fileData.type}`, null, {
            duration: 3000,
          });
        }

        // Check if file exceeds limit
        if (fileSizeExceedsLimit) {
          image['exceedsLimit'] = true;
          this.snackBar.open($localize`Image size limit exceeded`, null, { duration: 3000 });
        }

        image.invalid = !fileHasAllowedType || fileSizeExceedsLimit;

        return { ...image };
      });

      return imagesToEnqueue;
    }),
    withLatestFrom(this.store.select(getImageSizesArray)),
    mergeMap(async ([queuedImages, crops]: [any, any]) => {
      let selectedImages = [...queuedImages];
      // Add needed properties on enqueued image
      selectedImages = await Promise.all(selectedImages.map(async image => {
        image.cropped = false;
        image.crops = crops.map(crop => {
          // const usePredefined = crop.resize_method === 'crop_from_position';
          const usePredefined = false;
          return {
            ...crop,
            cropID: crop.cropID || generateShortId(),
            usePredefined,
            cropMethod: usePredefined ? 'preDefined' : 'custom',
            orientation: crop.width > crop.height ? 'landscape' : 'portrait',
            modified: false,
            group: `${getUserFriendlyRatio(crop.width, crop.height)}-${crop.resize_method}`.replace('*', '')
          };
        });
        image.iptc = await this.imagesUploadService.extractImageMetadata(image);
        if (image.iptc && !image.meta) {
          image.meta = [this.mapImageMetaToDetails(image.iptc)];
        }
        return image;
      }));
      return new AddImagesToQueueDoneAction(selectedImages);
    })));


  uploadInvoked$: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType(UPLOAD_STARTED),
      tap(() => this.uploadingMessageRef = this.snackBar.open($localize`Uploading. Please wait...`, $localize`Close`)),
      withLatestFrom(
        combineLatest([this.store.select(getQueuedImagesArray), this.store.select(getImageSizesArray)])
      ),
      mergeMap(([action, [queuedImages, imageSizes]]: [UnsafeAction, any]) => {
        const validImages = queuedImages.filter(image => !image.uploadUrl && !image.invalid) // nonUploadedImages
          .map(image => {
            const premiumTag = image.tags.find(tag => tag.premium);
            const tags = image.tags.map(tag => tag.name);
            const systemTags = (image.systemTags || []).map(tag => tag.name);
            return { ...image, tags, premiumTag: premiumTag?.name || null, systemTags };
          });
        // all initial requests
        let requests: any = [];
        // images from local machine follow standard procedure: get presigned urls in batch and then start uploading one by one
        const localImages = validImages.filter(img => !img.provider);
        if (localImages.length) {
          requests = [...requests, this.imagesUploadService.createImageRequest(localImages)];
        }

        // images from external library (image has provider info) should be uploaded from url on the Media side so all images are sent in separate requests
        const externalImages = validImages.filter(img => img.provider);
        if (externalImages.length) {
          externalImages.forEach(img => img.processing = true);
          requests = [...requests, ...externalImages.map(img => this.imagesUploadService.createImageRequest([img]))];
        }
        return forkJoin(requests).pipe(map((response: any) => {
          const images = response.reduce((acc, data) => {
            const imgSet = data.map(item => {
              const image = validImages.find(img => img.queueID === item.queueID);
              const additionalParams = extractAdditionalImageUploadParams(item);
              Object.keys(additionalParams).forEach(key => image[key] = additionalParams[key]);
              image.uploading = image.uploadUrl;
              image.uploaded = !image.uploading;
              return image;
            });

          return [...acc, ...imgSet];
        }, []);

        return images;
      }));
      }),
      tap(async images => {
        this.store.dispatch(new UpdateQueuedImagesAction(images));
        const localImages = images.filter(img => img.uploadUrl);
        const limit = 1;
        const iterationsCount = Math.ceil(localImages.length / limit);
        for(let i = 0; i < iterationsCount; i++) {
          const imagesSlice = localImages.slice(i * limit, (i * limit) + limit);
          await this.uploadImages(imagesSlice);
        }

        this.store.select(getQueuedImagesArray)
          .pipe(take(1))
          .subscribe(queuedImages => {
            if (queuedImages?.length && queuedImages.filter((img: any) => !img.invalid).every((img: any) => img.uploaded)) {
              this.snackBar.open($localize`Upload completed.`, null, { duration: 2000 });
              this.store.dispatch(new AllImagesUploadedAction(queuedImages));
              return;
            }
            if (this.uploadingMessageRef) {
              this.uploadingMessageRef.dismiss();
              this.uploadingMessageRef = null;
            }
          });
      },
      err => this.snackBar.open($localize`Can't upload images. Please try again`, null, { duration: 2000 })
    )
    ), { dispatch: false });


  imageUploaded$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(IMAGE_UPLOADED),
      tap((action: UnsafeAction) => {
        this.store.dispatch(new UpdateUploadedImageAction(action.payload));
      })
    ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private imagesUploadService: ImagesUploadService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  mapImageMetaToDetails(iptc) {
    const metaObj = { key: 'image_details', value: {} };
    const filteredIptc = iptc
    .filter(
      (obj) =>
        obj.value !== false &&
        obj.value !== null &&
        obj.value !== undefined &&
        obj.value !== 0 &&
        obj.value !== ' ' &&
        obj.value !== '' &&
        obj.value !== '\x00' &&
        obj.value !== '\u0000'
    );

    metaObj.value['credit'] = ['credit', 'byline', 'byline title'].reduce((res, key) => res || filteredIptc.find(meta => meta.key === key)?.value, '');
    metaObj.value['caption'] = ['caption', 'caption writer', 'description', 'caption abstract'].reduce((res, key) => res || filteredIptc.find(meta => meta.key === key)?.value, '');
    metaObj.value['headline'] = ['headline'].reduce((res, key) => res || filteredIptc.find(meta => meta.key === key)?.value, '');
    metaObj.value['description'] = ['description', 'caption', 'caption abstract', 'caption writer'].reduce((res, key) => res || filteredIptc.find(meta => meta.key === key)?.value, '');
    metaObj.value['copyright'] = ['copyright'].reduce((res, key) => res || filteredIptc.find(meta => meta.key === key)?.value, '');

    if (metaObj.value['credit']) {
      metaObj.value['credit'] = JSON.stringify([metaObj.value['credit']]);
    }

    return metaObj;
  }

  uploadImages(images) {
    return new Promise(resolve => {
      const uploadTracker = images.reduce((acc, img) => ({ ...acc, [img.queueID]: false }), {});
      from(
        images.map((image: any) => {
          return this.imagesUploadService
          .uploadImage(image.fileData, image.uploadUrl)
          .pipe(mergeMap(response => of({ httpEvent: response, image: image })));
        })
      ).pipe(mergeMap((val: any) => val))
        .subscribe(({ httpEvent, image }: any) => {
          if (httpEvent.loaded && httpEvent.total) {
            const uploadProgress =
              (httpEvent.loaded / httpEvent.total) * 100;
            this.imagesUploadService.dispatchUploadProgress({
              image: image.queueID,
              progress: uploadProgress,
            });
          }

          if (httpEvent.status && httpEvent.status === 200) {
            uploadTracker[image.queueID] = true;
            this.store.dispatch(new ImageUploadedAction(image));
            this.imagesUploadService.dispatchUploadProgress({
              image: image.queueID,
              progress: 'UPLOADED',
            });
            const imagesUploaded = Object.values(uploadTracker).every(val => val);
            if (imagesUploaded) {
              return resolve(true)
            }
          }
        });
    });
  }
}

function extractAdditionalImageUploadParams(data) {
  const imageKeys = JSON.parse(
    data.meta.find(meta => meta.key === 'resize_format_keys').value
  );
  const imageUploadKeys = Object.values(imageKeys).map((value: any) => value.uploadKey);
  const imageFormats = Object.entries(imageKeys).map(([key, value]: any) => {
    let format = key.split('-')[0];
    format = format.split('_');
    return {
      width: format[0],
      height: format[1],
      key: value.uploadKey,
      identifier: value.renditionId,
    };
  });

  return {
    id: data.image_id,
    uploadUrl: data.upload_url,
    requestID: data.request_id,
    uploading: true,
    image_keys: imageUploadKeys,
    formats: imageFormats,
  };
}
