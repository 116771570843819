import { UnsafeAction } from '../unsafe-action.interface';
import { Dashboard } from './dashboards.model';

export const GET_DASHBOARDS = '[Dashboards] Get List';
export class GetDashboardsAction implements UnsafeAction {
  readonly type = GET_DASHBOARDS;
  constructor(public payload: any = {}) {}
}

export const GET_DASHBOARDS_SUCCESS = '[Dashboards] Get List Success';
export class GetDashboardsSuccessAction implements UnsafeAction {
  readonly type = GET_DASHBOARDS_SUCCESS;
  constructor(public payload: any) {}
}

export const CREATE_DASHBOARD = '[Dashboards] Create Dashboard';
export class CreateDashboardAction implements UnsafeAction {
  readonly type = CREATE_DASHBOARD;
  // payload is fetched article
  constructor(public payload: Dashboard) {}
}

export const CREATE_DASHBOARD_SUCCESS = '[Dashboards] Create Dashboard Success';
export class CreateDashboardSuccessAction implements UnsafeAction {
  readonly type = CREATE_DASHBOARD_SUCCESS;
  // payload is fetched article
  constructor(public payload: any) {}
}
export const UPDATE_DASHBOARD = '[Dashboards] Update Dashboard';
export class UpdateDashboardAction implements UnsafeAction {
  readonly type = UPDATE_DASHBOARD;
  // payload is fetched article
  constructor(public payload: Dashboard) {}
}

export const UPDATE_DASHBOARD_SUCCESS = '[Dashboards] Update Dashboard Success';
export class UpdateDashboardSuccessAction implements UnsafeAction {
  readonly type = UPDATE_DASHBOARD_SUCCESS;
  // payload is fetched article
  constructor(public payload: any) {}
}

export const DASHBOARD_ACTION_FAILED = '[Dashboards] Generic failure action';
export class DashboardFailedAction implements UnsafeAction {
  readonly type = DASHBOARD_ACTION_FAILED;
  constructor(public payload: any) {}
}
