import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  CREATE_DASHBOARD,
  CreateDashboardSuccessAction,
  DASHBOARD_ACTION_FAILED,
  DashboardFailedAction,
  GET_DASHBOARDS,
  GetDashboardsSuccessAction,
  UPDATE_DASHBOARD,
  UpdateDashboardSuccessAction,
} from './dashboards.actions';
import { DashboardsService } from '../../api/dashboards/dashboards.service';
import { get } from 'lodash';

@Injectable()
export class DashboardsEffects {
  loadDashboards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_DASHBOARDS),
      mergeMap((action: UnsafeAction) => {
        return this.dashboardsService.getDashboards().pipe(
          map((response) => new GetDashboardsSuccessAction(response)),
          catchError((e) => of(new DashboardFailedAction(e)))
        );
      })
    )
  );

  createDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_DASHBOARD),
    mergeMap((action: UnsafeAction) => {
      const data = { ...action.payload };
      return this.dashboardsService.createDashboard(data).pipe(
        map((response) => new CreateDashboardSuccessAction(response)),
        // tap(() => this.snackBar.open($localize`Dashboard created`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          return of(new DashboardFailedAction(e));
        })
      );
    })
  ));

  updateDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_DASHBOARD),
    mergeMap((action: UnsafeAction) => {
      const data = { ...action.payload };
      return this.dashboardsService.updateDashboard(data).pipe(
        map((response) => new UpdateDashboardSuccessAction(response)),
        // tap(() => this.snackBar.open($localize`Dashboard updated`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          return of(new DashboardFailedAction(e));
        })
      );
    })
  ));

  actionFailed$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTION_FAILED),
        tap((err: any) => {
          const actionType = get(err, 'payload.action.type', null) || $localize`Unknown`;
          this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, {
            duration: 4000,
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private dashboardsService: DashboardsService
  ) {}
}
