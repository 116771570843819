export interface Dashboard {
  id?: any;
  name?: string;
  panels: DashboardPanelConfig[];
}

export interface DashboardsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  items: Dashboard[];
}

export enum DashboardPanelType {
  QuickCreate = 'QUICK_CREATE',
  LookerStudio = 'LOOKER_STUDIO',
  ContentQueue = 'CONTENT_QUEUE',
  StatsOverview = 'STATS_OVERVIEW',
}

export interface DashboardPanelConfig {
  id?: any;
  name: string;
  type: DashboardPanelType;
  dataId?: any;
  additionalItems?: {
    position?: number;
    rowSpan?: string;
    columnSpan?: string;
  };

  // UI properties
  icon?: string;
}

const QuickCreatePanel: DashboardPanelConfig = {
  name: $localize`Quick Create`,
  type: DashboardPanelType.QuickCreate,
  additionalItems: { columnSpan: '12' },
  icon: 'fa-share'
};

const LookerStudioPanel: DashboardPanelConfig = {
  name: $localize`Looker Studio`,
  type: DashboardPanelType.LookerStudio,
  additionalItems: { columnSpan: '12' },
  icon: 'fa-chart-line'
};

const StatsOverviewPanel: DashboardPanelConfig = {
  name: 'Stats Overview',
  type: DashboardPanelType.StatsOverview,
  additionalItems: { columnSpan: '12' },
  icon: 'fa-history'
};

export const systemDashboardPanels = [QuickCreatePanel, LookerStudioPanel];
