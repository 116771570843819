import { Directive, ElementRef, HostListener, Input, Renderer2, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { AnimationBuilder, style, animate } from '@angular/animations';
import { generateShortId } from './shared-functions';
import { UserPreferencesService } from '../core/api/user-preferences/user-preferences.service';
import { get } from 'lodash-es';
declare const $;

export enum CheckMoreTypes {
  Authors = 'authors',
  Taxonomies = 'taxonomies'
}

@Directive({
  selector: '[gdCheckMoreTooltip]'
})

export class CheckMoreTooltipDirective implements OnChanges, OnDestroy {
  @Input() gdCheckMoreTooltip: { type: string; data: any; };

  tooltipText = '';
  tooltipElementClass = 'gd-check-more-tooltip';
  tooltipId;
  tooltipData = [];
  activeTheme = 'default';
  longestTaxonomyPath = 0;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private animationBuilder: AnimationBuilder,
    private userPreferenceService: UserPreferencesService
  ) {
    this.tooltipId = generateShortId();
  }

  @HostListener('mouseover') onmouseover() {
    this.showTooltip();
  }

  @HostListener('mouseout') onmouseout() {
    this.hideTooltip();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.activeTheme = this.userPreferenceService.getUserPreference('theme');
    const currentValue: any = get(changes, 'gdCheckMoreTooltip.currentValue', {});
    if (currentValue) {
      const type: CheckMoreTypes = currentValue.type;
      this.tooltipData = currentValue.data;
      this.tooltipText = '<div>';

      switch (type) {
        case CheckMoreTypes.Authors:
          this.tooltipData.forEach(author => {
            const tooltipStyle = `background-color: transparent; color: ${this.activeTheme === 'default' ? '#000' : '#fff'}; font-size: 14px;`
            this.tooltipText += this.createDivElement('gd-check-more-tooltip-wrapper-item gd-check-more-tooltip-wrapper-item__authors', author.name, tooltipStyle);
            this.longestTaxonomyPath = this.longestTaxonomyPath < author.name.length ? author.name.length : this.longestTaxonomyPath
          });
          break;
        case CheckMoreTypes.Taxonomies:
          this.tooltipData.forEach(taxonomy => {
            this.tooltipText += this.createDivElement('gd-check-more-tooltip-wrapper-item', taxonomy.fullPath || taxonomy.name);
            this.longestTaxonomyPath = this.longestTaxonomyPath < taxonomy.fullPath.length ? taxonomy.fullPath.length : this.longestTaxonomyPath;
          });
          break;
      }
      this.tooltipText += '</div>';
    }
  }


  ngOnDestroy() {
    this.hideTooltip();
  }

  showTooltip() {
    const tooltipEl = this.renderer.createElement('div');
    this.renderer.addClass(tooltipEl, this.resolveTooltipClass());
    tooltipEl.insertAdjacentHTML('beforeend', this.tooltipText);
    this.setTooltipStyles(tooltipEl);
    const isTooltipEmpty = tooltipEl.innerHTML === '';
    if (isTooltipEmpty) {
      return;
    }
    this.renderer.appendChild(document.querySelector('body'), tooltipEl);
    this.animateScaleIn(tooltipEl);
  }

  hideTooltip() {
    const isTooltipVisible = $('.gd-check-more-tooltip__' + this.tooltipId).length;
    if (!isTooltipVisible) {
      return;
    }
    const tooltipEl = document.querySelector(`.${this.resolveTooltipClass()}`);
    this.animateScaleOut(tooltipEl);
    // setTimeout is added for security check because of tooltips that didn't successfully closed.
    setTimeout(() => {
      const isTooltipVisibleSafetyCheck = $('.gd-check-more-tooltip__' + this.tooltipId).length;
      if (isTooltipVisibleSafetyCheck) {
        this.hideTooltip();
      }
    }, 200);
  }

  createDivElement(divClass, path, tooltipCSS?) {
    let element = `<div class="${divClass}">`;
    element += `<span class="gd-check-more-tooltip-item" ${tooltipCSS ? 'style="' + tooltipCSS +'"' : ''}>${path}</span>`;
    element += '</div>';
    return element;
  }

  resolveTooltipClass() {
    return `${this.tooltipElementClass}__${this.tooltipId}`;
  }


  setTooltipStyles(elRef) {
    this.activeTheme = this.userPreferenceService.getUserPreference('theme');
    const parentElRectBounds = this.elRef.nativeElement.getBoundingClientRect();
    const background = this.activeTheme === 'default' ?
      '#FFFFFF 0% 0% no-repeat padding-box' : '#333333 0% 0% no-repeat padding-box';
    this.renderer.setStyle(elRef, 'position', 'absolute');
    this.renderer.setStyle(elRef, 'background', background);
    this.renderer.setStyle(elRef, 'z-index', '2000');
    this.renderer.setStyle(elRef, 'border-radius', '4px');
    this.renderer.setStyle(elRef, 'padding', '12px');
    this.renderer.setStyle(elRef, 'display', 'flex');
    this.renderer.setStyle(elRef, 'justify-content', 'center');
    this.renderer.setStyle(elRef, 'box-shadow', 'rgba(0, 0, 0, 0.16) 0px 3px 8px 4px');
    this.renderer.setStyle(elRef, 'opacity', '1');

    // TODO use @ViewChild, jQuery usage for getting elements is unsafe
    const visibleDocumentHeight = $('#main-container')[0].clientHeight;
    const longestPath = this.longestTaxonomyPath * 6 + 40;
    const toolTipLeftValue = parentElRectBounds.left - longestPath / 2;
    const isTooltipOutOfBounds = visibleDocumentHeight - parentElRectBounds.y < 150;
    if (!isTooltipOutOfBounds) {
      this.renderer.setStyle(elRef, 'top', `${(parentElRectBounds.top + parentElRectBounds.height) + 10}px`);
      this.renderer.setStyle(elRef, 'left', `${toolTipLeftValue}px`);
      return;
    }
    const numberOfPermissions = this.tooltipData.length + 2;
    const tooltipTopValue = numberOfPermissions * 24 + parentElRectBounds.height - 1;
    this.renderer.setStyle(elRef, 'top', `${parentElRectBounds.top - tooltipTopValue}px`);
    this.renderer.setStyle(elRef, 'left', `${toolTipLeftValue}px`);
  }

  animateScaleIn(el) {
    const animation = this.animationBuilder.build([
      style({ transform: 'scale(0)' }),
      animate(150, style({ transform: 'scale(1)' }))
    ]);

    const player = animation.create(el);
    player.play();
  }

  animateScaleOut(el) {
    const animation = this.animationBuilder.build([
      style({ transform: 'scale(1)' }),
      animate(50, style({ transform: 'scale(0)' }))
    ]);

    const player = animation.create(el);
    player.play();

    setTimeout(() => { player.destroy(); }, 50);

    player.onDestroy(() => {
      this.renderer.removeChild(document.body, el);
    });
  }
}
