import { Component, ViewEncapsulation, VERSION, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { CheckForUpdateService } from './check-for-update.service';
import { SystemNotificationsService } from './system-notifications.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MixPanelService } from './core/api/mixpanel/mixpanel.service';
import { environment } from '../environments/environment';
import { BidiService } from './core/i18n/bidi.service';
import { PageTitleService } from './core/miscellaneous/page-title.service';

import  { Injector} from '@angular/core';
import  { createCustomElement } from '@angular/elements';
import { EditorWidgetCardFrameComponent } from './gpp-shared/editor-widget-card-frame/editor-widget-card-frame.component';

@Component({
  selector: 'gd-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit{
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private injector: Injector,
    private mixPanelService: MixPanelService,
    @Inject(LOCALE_ID) locale: string,
    private bidiService: BidiService,
    private pageTitleService: PageTitleService,
    private checkForUpdateService: CheckForUpdateService,
   //SN killSwitch private systemNotificationsService: SystemNotificationsService,
  ) {



    const el = createCustomElement(EditorWidgetCardFrameComponent, { injector });
    customElements.define('editor-widget-card-frame', el);

    window.printInfoLogs =
      !environment.production ||
      environment.envNotes.includes('GlideCloud1 QA environment') ||
      environment.envNotes.includes('GlideCloud1 DEV environment') ||
      environment.envNotes.includes('This is a local');

    // set current active language
    window.locale = locale;

    // set UI locale directionality
    this.bidiService.setUiLocaleCode(locale);

    this.checkForUpdateService.runUpdateChecks();
    //SN killSwitch  this.systemNotificationsService.runMaintenanceChecks();

    pageTitleService.registerPageTitleHandler();

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_accounts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_accounts.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_article-configuration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_article-configuration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_authors',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_authors.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_collection-configuration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_collection-configuration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_collections',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_collections.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_content-panels-configuration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_content-panels-configuration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_galleries',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_galleries.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_content-tags-confguration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_content-tags-confguration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_media-settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_media-settings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_taxonomies',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_taxonomies.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_taxonomy-configuration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_taxonomy-configuration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_page-routes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_page-routes.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_system-widgets',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_system-widgets.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_third-party-widgets',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_file-rtf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/files/glideCustomIcon_file-rtf.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_file-svg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/files/glideCustomIcon_file-svg.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_collection',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_collection.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_default-file',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_default-file.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_excel.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_image.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_pdf.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_powerpoint',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_powerpoint.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_rtf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_rtf.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_sound',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_sound.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_svg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_svg.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_txt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_txt.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_video',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_video.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_widget',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_widget.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_word',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_word.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_brightcove',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_brightcove.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_content-panel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_content-panel.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_strip-tags',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/collections/glideCustomIcon_strip-tags.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_image-search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_image-search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_content-tags',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_content-tags.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_clear-filters',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_clear-filters.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_verify',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/verify/glideCustomIcon_verify.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_verifySettings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/verify/glideCustomIcon_verifySettings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_accessBundles',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/verify/glideCustomIcon_accessBundles.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_video-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_video-placeholder.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_live-report',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_live-report.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_liveReport',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_liveReport.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_live-report-types',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_live-report-types.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_transmit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_transmit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_event-subscribers',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_event-subscribers.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_generate-image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_generate-image.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'uk-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/gb.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bs-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/ba.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ar-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/ar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'en-us-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/us.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'es-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/es.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ko-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/kr.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'zh-cn-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/cn.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ja-jp-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/jp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fi-fi-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/fi.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'it-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/it.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pt-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/pt.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fr-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/fr.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'de-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/de.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ar-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flags/sa.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_summary',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_summary.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_person',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_person-solid.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gpp-gaia-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia-logo.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_question',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_question-solid.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_location-pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_location-pin-solid.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_calendar-clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_calendar-clock-regular.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_comment-question',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_comment-question-solid.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_lightbulb-exclamation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_lightbulb-exclamation-solid.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_paper-plane',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_paper-plane.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_summary',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_summary.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_drafting_assistant',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_drafting_assistant.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_stars',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_stars.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_image_generation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_image_generation.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_preflight',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_preflight.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_translation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_translation.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_voice',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_voice.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_gaia_voice',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/gaia/glideCustomIcon_gaia_voice.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_checkbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_checkbox.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_toggle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_toggle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_radio',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_radio.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_colour',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_colour.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_date',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_date.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_datetime',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_datetime.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_text',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_text.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_number',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_number.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_email.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_password.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_snippet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_snippet.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_value-list',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_value-list.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_locale',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_locale.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_autocomplete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_autocomplete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_select',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_select.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_cfg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_cfg.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_external-data',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_external-data.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_displayWidgetCard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_displayWidgetCard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'content-queues-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/content-queues-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'looker-studio-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/looker-studio-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_scratchpad',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_scratchpad.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glideCustomIcon_move',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom-icons/glideCustomIcon_move.svg')
    );

    // Add
    try {
      const ngVersionMetaTag = document.querySelector('meta[name="gc:angular-version"]');
      ngVersionMetaTag.setAttribute('content', VERSION.full);
    } catch (error) {
      // non essential, silent fail
    }
  }

  ngOnInit() {
    this.mixPanelService.init(environment.mixPanelToken);
  }

}
