import { InfoDialogComponent } from './modals/info-dialog/info-dialog.component';
import { SaveFormComponent } from './gd-components/save-form/save-form.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { SortablejsModule } from 'ngx-sortablejs';
import { ColorPickerModule } from 'ngx-color-picker';
import { TreeModule } from '@circlon/angular-tree-component';
import { MaterialModule } from './material-module.module';

import { ModalsService } from './modals/modals.service';
import { EmbeddablesService } from './editors/embeddables.service';
import { EmbeddablesDataService } from './editors/embeddables-data.service';

import { ConfirmComponent } from './modals/confirm/confirm.component';
import { FroalaComponent } from './editors/froala/froala.component';
import { WordCountComponent } from './word-count/word-count.component';
import { TaxonomyEditComponent } from './forms/taxonomy/taxonomy.edit.component';
import { TaxonomiesSelectComponent } from './taxonomies-select/taxonomies-select.component';
import { RevisionsSelectComponent } from './revisions-select/revisions-select.component';
import { IndentationComponent } from './indentation/indentation.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { EmbedMediaDialogComponent } from './embed-media-dialog/embed-media-dialog.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImagesViewGridComponent } from './images-view-grid/images-view-grid.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { WorkflowStatusSelectComponent } from './workflow-status-select/workflow-status-select.component';
import { ScrollingListComponent } from './scrolling-list/scrolling-list.component';
import { TooltipDirective } from './tooltip.directive';
import { ImageCropEditComponent } from './image-crop/image-crop-edit/image-crop-edit.component';
import { ImageCropperComponent } from './image-crop/image-cropper/image-cropper.component';
import { TaxonomyTreeModalComponent } from './taxonomy-tree-modal/taxonomy-tree-modal.component';
import { TaxonomyTreeComponent } from './taxonomy-tree/taxonomy-tree.component';
import { ImageDownloadComponent } from './image-download/image-download.component';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { DragAndDropWrapperComponent } from './drag-and-drop-wrapper/drag-and-drop-wrapper.component';
import { UploadImageMetaFormComponent } from './upload-image-meta-form/upload-image-meta-form.component';
import { SanitazeURL } from './sanitaze.pipe';
import { PromoCardComponent } from './promo-card/promo-card.component';
import { TagsAutocompleteComponent } from './tags-autocomplete/tags-autocomplete.component';
import { UISectionTitleComponent } from './ui-section-title/ui-section-title.component';
import { ScrollerComponent } from './scroller/scroller.component';
import { EditorComponent } from './editors/editor/editor.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { UserDetailsFormComponent } from './user-details-form/user-details-form.component';
import { PromoImageComponent } from './promo-image/promo-image.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ImageThumbnailComponent } from './image-thumbnail/image-thumbnail.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { TableNoResultsComponent } from './table-no-results/table-no-results.component';
import { EmbedYoutubeDialogComponent } from './embed-dialogs/embed-youtube-dialog/embed-youtube-dialog.component';
import { EmbedVimeoDialogComponent } from './embed-dialogs/embed-vimeo-dialog/embed-vimeo-dialog.component';
import { EmbedBrightcoveDialogComponent } from './embed-dialogs/embed-brightcove-dialog/embed-brightcove-dialog.component';
import { VideoTypeHelperModalComponent } from './embed-dialogs/video-type-helper-modal/video-type-helper-modal.component';
import { WidgetConfigurationModule } from '../gpp-shared/widget-configuration/widget-configuration.module';
import {
  EmbedAutocompleteCmsDataDialogComponent
} from './embed-dialogs/embed-autocomplete-cms-data-dialog/embed-autocomplete-cms-data-dialog.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { CreateFolderComponent } from './create-folder/create-folder.component';
import { UploadEditFileFormComponent } from './upload-edit-file-form/upload-edit-file-form.component';
import { MoveToComponent } from './move-to/move-to.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { EmbedFilesDialogComponent } from './embed-files-dialog/embed-files-dialog.component';
import { AddContentTagDialogComponent } from './add-content-tag-dialog/add-content-tag-dialog.component';
import { RenditionsViewComponent } from './renditions-view/renditions-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AdvancedFilteringOptionComponent } from './advanced-filtering-option/advanced-filtering-option.component';
import { ContentFilterDialogComponent } from './content-filter-dialog/content-filter-dialog.component';
import { ExtendSessionDialogComponent } from './extend-session-dialog/extend-session-dialog.component';
import { EditLabelDialogComponent } from '../shared/edit-label-dialog/edit-label-dialog.component';
import { RichTextEditorModule } from '../gpp-shared/rich-text-editor/rich-text-editor.module';
import { CheckMoreTooltipDirective } from './checkMoreTooltip.directive';
import { ItemChipTooltipDirective } from './item-chip-tooltip.directive';
import { MediaEmbedModalComponent } from './embed-dialogs/media-embed-modal/media-embed-modal.component';
import { SharingOptionFormComponent } from './sharing-option-form/sharing-option-form.component';
import { BylineFieldComponent } from './byline-field/byline-field.component';
import { RelatedVideosComponent } from './related-videos/related-videos.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RelatedArticlesListComponent } from './related-articles-list/related-articles-list.component';
import { AdvancedFiltersComponent } from './advanced-filters/advanced-filters.component';
import { TaxonomyAutocompleteInputComponent } from './taxonomy-autocomplete-input/taxonomy-autocomplete-input.component';
import { RefreshTokenDialogComponent } from './refresh-token-dialog/refresh-token-dialog.component';
import { ArticleQuickViewComponent } from './article-quick-view/article-quick-view.component';
import { SectionInfoComponent } from './section-info/section-info.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { ExpandableListComponent } from './expandable-chip-list/expandable-chip-list.component';
import { EmbedWidgetConfigurationComponent } from './embed-widget-configuration/embed-widget-configuration.component';
import { FroalaEmbedWidgetComponent } from './froala-embed-widget/froala-embed-widget.component';
import { CollectionEmbedWidgetComponent } from './collection-embed-widget/collection-embed-widget.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MediaCollectionItemDialogComponent } from './embed-dialogs/media-collection-item-dialog/media-collection-item-dialog.component';
import { EmbedJWMediaDialogComponent } from './embed-dialogs/embed-jw-media-dialog/embed-jw-media-dialog.component';
import { MultichannelPublishingComponent } from './multichannel-publishing/multichannel-publishing.component';
import { LinksReportComponent } from './links-report/links-report.component';
import { TooltipListPipe } from './tooltip-list.pipe';
import { QuickPublishSettingsComponent } from './quick-publish-settings/quick-publish-settings.component';
import { ToolbarOptionsComponent } from './toolbar-options/toolbar-options.component';
import { ExternalImageLibraryWidgetComponent } from './external-image-library-widget/external-image-library-widget.component';
import { SimpleLocalesSelectComponent } from './simple-locales-select/simple-locales-select.component';
import { ContentLocaleComponentsModule } from '../gpp-shared/content-locale-components/content-locale-components.module';
import { RevisionsMenuComponent } from './revisions-menu/revisions-menu.component';
import { AutosaveOptionsComponent } from './autosave-options/autosave-options.component';
import { WorkflowStatusMenuButtonComponent } from './workflow-status-menu-button/workflow-status-menu-button.component';
import { RelativeDurationComponent } from './relative-duration/relative-duration.component';
import { SystemNotificationsComponent } from './modals/notifications/system-notifications/system-notifications.component';
import { EmbedDailymotionDialogComponent } from './embed-dialogs/embed-dailymotion-dialog/embed-dailymotion-dialog.component';
import { TripleChoiceDialogComponent } from './modals/triple-choice-dialog/triple-choice-dialog.component';
import { GenerateImageFormComponent } from './generate-image-form/generate-image-form.component';
import { GenerateImageComponent } from './generate-image/generate-image.component';
import { FoundationModelFormComponent } from './content-generation/foundation-model-form/foundation-model-form.component';
import { FoundationModelSelectorComponent } from './content-generation/foundation-model-selector/foundation-model-selector.component';
import { EmbedTwitchDialogComponent } from './embed-dialogs/embed-twitch-dialog/embed-twitch-dialog.component';
import { FoundationModelInformationComponent } from './content-generation/foundation-model-information/foundation-model-information.component';
import { FilesUploadComponent } from '../glide-media/+files/files-upload/files-upload.component';
import { DateTransformModule } from '../gpp-shared/date-transform/date-transform.module';
import { StatusViewComponent } from './table-columns/status-view/status-view.component';
import { LastUpdateStatusComponent } from './table-columns/last-update-status/last-update-status.component';
import { AuthorListComponent } from './table-columns/author-list/author-list.component';
import { TaxonomiesListComponent } from './table-columns/taxonomies-list/taxonomies-list.component';
import { QueryFormBuilderComponent } from './query-form-builder/query-form-builder.component';
import { DateRangeFilterFieldComponent } from './query-form-builder/date-range-filter-field/date-range-filter-field.component';
import { UsersAutocompleteFilterFieldComponent } from './query-form-builder/users-autocomplete-filter-field/users-autocomplete-filter-field.component';
import { StatusSelectFilterFieldComponent } from './query-form-builder/status-select-filter-field/status-select-filter-field.component';
import { LastUpdateUserComponent } from './table-columns/last-update-user/last-update-user.component';
import { LastUpdateTimeComponent } from './table-columns/last-update-time/last-update-time.component';
import { SelectedFiltersOverviewComponent } from './query-form-builder/selected-filters-overview/selected-filters-overview.component';
import { RightClickDialogWrapperComponent } from './modals/right-click-dialog-wrapper/right-click-dialog-wrapper.component';
import { CQTableRCDialogComponent } from './modals/right-click-dialog-wrapper/cq-table-rc-dialog/cq-table-rc-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        SortablejsModule,
        RouterModule,
        // from fury
        ColorPickerModule,
        PerfectScrollbarModule,
        TreeModule,
        // PerfectScrollbarModule,
        MomentDateModule,
        NgxTrimDirectiveModule,
        // TODO check if this inport is neccessary, as the module is separately imported where used as well
        WidgetConfigurationModule,
        DragDropModule,
        RichTextEditorModule,
        ScrollingModule,
        NgxPopperjsModule,
        ClipboardModule,
        ContentLocaleComponentsModule,
        DateTransformModule,
    ],
    declarations: [
        InfoDialogComponent,
        EditorComponent,
        FroalaComponent,
        WordCountComponent,
        ConfirmComponent,
        TripleChoiceDialogComponent,
        TaxonomiesSelectComponent,
        RevisionsSelectComponent,
        RevisionsMenuComponent,
        WorkflowStatusMenuButtonComponent,
        AutosaveOptionsComponent,
        WorkflowStatusSelectComponent,
        IndentationComponent,
        UserMenuComponent,
        EmbedMediaDialogComponent,
        DropzoneComponent,
        ImagesViewGridComponent,
        ImageUploadComponent,
        ImagePreviewComponent,
        TooltipDirective,
        ImageCropEditComponent,
        ImageCropperComponent,
        ImageDownloadComponent,
        ScrollingListComponent,
        TaxonomyTreeModalComponent,
        TaxonomyEditComponent,
        TaxonomyTreeComponent,
        DragAndDropDirective,
        DragAndDropWrapperComponent,
        UploadImageMetaFormComponent,
        SanitazeURL,
        TagsAutocompleteComponent,
        PromoCardComponent,
        UISectionTitleComponent,
        ScrollerComponent,
        UserDetailsFormComponent,
        ImageThumbnailComponent,
        PromoImageComponent,
        TableNoResultsComponent,
        HelpModalComponent,
        EmbedYoutubeDialogComponent,
        EmbedVimeoDialogComponent,
        EmbedBrightcoveDialogComponent,
        VideoTypeHelperModalComponent,
        EmbedAutocompleteCmsDataDialogComponent,
        FileDownloadComponent,
        CreateFolderComponent,
        UploadEditFileFormComponent,
        MoveToComponent,
        FilePreviewComponent,
        EmbedFilesDialogComponent,
        FilesUploadComponent,
        AddContentTagDialogComponent,
        RenditionsViewComponent,
        AdvancedFilteringOptionComponent,
        ContentFilterDialogComponent,
        ExtendSessionDialogComponent,
        SaveFormComponent,
        CheckMoreTooltipDirective,
        EditLabelDialogComponent,
        ItemChipTooltipDirective,
        MediaEmbedModalComponent,
        SharingOptionFormComponent,
        BylineFieldComponent,
        RelatedVideosComponent,
        RelatedArticlesListComponent,
        AdvancedFiltersComponent,
        TaxonomyAutocompleteInputComponent,
        RefreshTokenDialogComponent,
        ArticleQuickViewComponent,
        TaxonomiesListComponent,
        SectionInfoComponent,
        ExpandableListComponent,
        EmbedWidgetConfigurationComponent,
        FroalaEmbedWidgetComponent,
        CollectionEmbedWidgetComponent,
        ImageSliderComponent,
        EmbedJWMediaDialogComponent,
        MultichannelPublishingComponent,
        MediaCollectionItemDialogComponent,
        LinksReportComponent,
        TooltipListPipe,
        QuickPublishSettingsComponent,
        ToolbarOptionsComponent,
        ExternalImageLibraryWidgetComponent,
        SimpleLocalesSelectComponent,
        SystemNotificationsComponent,
        RelativeDurationComponent,
        EmbedDailymotionDialogComponent,
        GenerateImageFormComponent,
        GenerateImageComponent,
        FoundationModelFormComponent,
        FoundationModelSelectorComponent,
        EmbedTwitchDialogComponent,
        FoundationModelInformationComponent,
        StatusViewComponent,
        LastUpdateStatusComponent,
        LastUpdateUserComponent,
        LastUpdateTimeComponent,
        AuthorListComponent,
        QueryFormBuilderComponent,
        DateRangeFilterFieldComponent,
        UsersAutocompleteFilterFieldComponent,
        StatusSelectFilterFieldComponent,
        SelectedFiltersOverviewComponent,
        RightClickDialogWrapperComponent,
        CQTableRCDialogComponent
    ],
    exports: [
        ConfirmComponent,
        TripleChoiceDialogComponent,
        InfoDialogComponent,
        MaterialModule,
        WordCountComponent,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ColorPickerModule,
        PerfectScrollbarModule,
        TreeModule,
        // PerfectScrollbarModule,
        TaxonomyTreeModalComponent,
        TaxonomyEditComponent,
        TaxonomyTreeComponent,
        // from fury
        EditorComponent,
        TaxonomiesSelectComponent,
        RevisionsSelectComponent,
        RevisionsMenuComponent,
        WorkflowStatusMenuButtonComponent,
        AutosaveOptionsComponent,
        WorkflowStatusSelectComponent,
        IndentationComponent,
        UserMenuComponent,
        EmbedMediaDialogComponent,
        DropzoneComponent,
        ImagesViewGridComponent,
        ImageUploadComponent,
        ImagePreviewComponent,
        TooltipDirective,
        ImageCropEditComponent,
        ImageCropperComponent,
        ImageDownloadComponent,
        ScrollingListComponent,
        DragAndDropDirective,
        DragAndDropWrapperComponent,
        UploadImageMetaFormComponent,
        SanitazeURL,
        TagsAutocompleteComponent,
        PromoCardComponent,
        UISectionTitleComponent,
        MomentDateModule,
        ScrollerComponent,
        UserDetailsFormComponent,
        NgxTrimDirectiveModule,
        ImageThumbnailComponent,
        HelpModalComponent,
        TableNoResultsComponent,
        PromoImageComponent,
        EmbedYoutubeDialogComponent,
        EmbedVimeoDialogComponent,
        EmbedBrightcoveDialogComponent,
        VideoTypeHelperModalComponent,
        EmbedAutocompleteCmsDataDialogComponent,
        FileDownloadComponent,
        CreateFolderComponent,
        UploadEditFileFormComponent,
        MoveToComponent,
        FilePreviewComponent,
        EmbedFilesDialogComponent,
        FilesUploadComponent,
        AddContentTagDialogComponent,
        RenditionsViewComponent,
        AdvancedFilteringOptionComponent,
        ContentFilterDialogComponent,
        SaveFormComponent,
        CheckMoreTooltipDirective,
        ItemChipTooltipDirective,
        MediaEmbedModalComponent,
        SharingOptionFormComponent,
        BylineFieldComponent,
        RelatedVideosComponent,
        ScrollingModule,
        RelatedArticlesListComponent,
        AdvancedFiltersComponent,
        TaxonomyAutocompleteInputComponent,
        RefreshTokenDialogComponent,
        ArticleQuickViewComponent,
        TaxonomiesListComponent,
        SectionInfoComponent,
        ExpandableListComponent,
        EmbedWidgetConfigurationComponent,
        FroalaEmbedWidgetComponent,
        CollectionEmbedWidgetComponent,
        ImageSliderComponent,
        EmbedJWMediaDialogComponent,
        MultichannelPublishingComponent,
        MediaCollectionItemDialogComponent,
        LinksReportComponent,
        TooltipListPipe,
        QuickPublishSettingsComponent,
        ToolbarOptionsComponent,
        ExternalImageLibraryWidgetComponent,
        SimpleLocalesSelectComponent,
        ContentLocaleComponentsModule,
        SystemNotificationsComponent,
        RelativeDurationComponent,
        EmbedDailymotionDialogComponent,
        GenerateImageFormComponent,
        GenerateImageComponent,
        FoundationModelFormComponent,
        FoundationModelSelectorComponent,
        EmbedTwitchDialogComponent,
        FoundationModelInformationComponent,
        DateTransformModule,
        StatusViewComponent,
        LastUpdateStatusComponent,
        LastUpdateUserComponent,
        LastUpdateTimeComponent,
        AuthorListComponent,
        QueryFormBuilderComponent,
        DateRangeFilterFieldComponent,
        UsersAutocompleteFilterFieldComponent,
        StatusSelectFilterFieldComponent,
        SelectedFiltersOverviewComponent,
        RightClickDialogWrapperComponent,
        CQTableRCDialogComponent,
    ],
    // TODO: These should all be in the core, or in the relevant modules
    providers: [
        ModalsService,
        EmbeddablesService,
        EmbeddablesDataService,
        CdkColumnDef
    ]
})
export class SharedModule { }
