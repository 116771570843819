export enum PreviewProviderVersions {
  V1 = 'V1',
  V2 = 'V2',
  NoProvider = 'NO_PROVIDER'
}

export enum PreviewProviderVersionLabels {
  V1 = 'Version 1 API',
  V2 = 'Version 2 API',
  NoProvider = 'No preview service'
}
