import { Injectable } from '@angular/core';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ChipService {
  constructor(private accountSettingsService: AccountSettingsService) {}

  // This function is used to prepare display data for Article list taxonomy cell.
  // Taxonomy name length is used to calculate the maximum number of displayed elements.
  public getTaxonomyDisplayData(taxonomies) {
    const showTaxonomyPath = this.accountSettingsService.getShowTaxonomyPathFlag();
    let taxosWrapperLength = 0;
    let row = 0;
    const visibleTaxonomies = [];
    const hiddenTaxonomies = [];

    taxonomies.forEach((taxonomy) => {
      if (row > 2) {
        return hiddenTaxonomies.push(taxonomy);
      }
      const pathLength = showTaxonomyPath
        ? this.fullPathLength(taxonomy.taxonomyDisplayData)
        : this.pathLength(taxonomy.name);
      taxosWrapperLength += pathLength;
      const maxLength = row < 2 ? 380 : 360;
      if (taxosWrapperLength < maxLength) {
        return visibleTaxonomies.push(taxonomy);
      }
      row += 1;
      if (row < 3) {
        taxosWrapperLength = pathLength;
        return visibleTaxonomies.push(taxonomy);
      }
      hiddenTaxonomies.push(taxonomy);
    });

    const taxonomyDisplayData = {
      visibleTaxonomies,
      hiddenTaxonomies,
      infoChip:
        hiddenTaxonomies.length > 0
          ? { id: 'notShown', name: '+' + hiddenTaxonomies.length }
          : null,
    };
    return taxonomyDisplayData;
  }

  private fullPathLength(names, charInPx = 6) {
    const chipMargins = names.length > 3 ? 90 : (names.length - 1) * 25 + 20;
    const pathLength = names.reduce((obj, item) => {
      if (item !== 'NODE_PLACEHOLDER') {
        obj += item.length;
      }
      return obj;
    }, 0);
    return pathLength * charInPx + chipMargins;
  }

  private pathLength(name, charInPx = 6, chipMargins = 25) {
    return name.length * charInPx + chipMargins;
  }
}
